.schedule-card {
  background: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease;
  margin-bottom: 12px;
}

.schedule-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-color: var(--text-secondary);
}

.schedule-card-content {
  display: flex;
  gap: 12px;
  flex: 1;
  min-width: 0;
}

.schedule-icon {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bg-secondary);
  border-radius: 50%;
}

.icon-primary {
  color: #E2384D;
  font-size: 1rem;
}

.schedule-details {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.schedule-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 0;
}

.schedule-title {
  color: var(--text-primary);
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  min-width: 0;
}

.schedule-time {
  color: var(--text-secondary);
  font-size: 0.875rem;
}

.schedule-notes {
  font-size: 0.875rem;
  color: var(--text-secondary);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.4;
}

.schedule-locations {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 4px 0;
}

.location {
  display: flex;
  gap: 8px;
  font-size: 0.875rem;
  white-space: nowrap;
  overflow: hidden;
}

.location-label {
  color: var(--text-secondary);
  flex-shrink: 0;
  min-width: 40px;
}

.location-name {
  color: var(--text-primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.schedule-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  padding-top: 8px;
}

.visibility-badge {
  background: var(--bg-secondary);
  color: var(--text-secondary);
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.75rem;
}

.details-button {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #E2384D;
  font-size: 0.875rem;
  padding: 4px 8px;
  border: none;
  background: none;
  cursor: pointer;
  transition: opacity 0.2s;
}

.details-button:hover {
  opacity: 0.8;
}

.status-icon {
  flex-shrink: 0;
  font-size: 1rem;
  margin-left: 8px;
}

.status-icon.confirmed {
  color: #E2384D;
}

.status-icon.unconfirmed {
  color: var(--text-secondary);
}

/* Hover state improvements */
.schedule-card:hover .schedule-title {
  white-space: normal;
  overflow: visible;
}

.schedule-card:hover .schedule-notes {
  -webkit-line-clamp: unset;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .schedule-card {
    padding: 12px;
  }

  .schedule-title {
    font-size: 0.875rem;
  }

  .schedule-time,
  .schedule-notes,
  .location {
    font-size: 0.8125rem;
  }
}