/* TopBar.module.css */
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: var(--bg-primary);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 3rem;
  border-bottom: 1px solid var(--border-color);
}

.logo {
  height: 2rem;
  object-fit: contain;
  cursor: pointer;
  display: flex; /* Added to improve alignment */
  align-self: center; /* Ensure logo is centered */
  margin-bottom: 0.5rem;
}

.top-bar-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.375rem;
  border: none;
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
  cursor: pointer;
  transition: all 0.15s ease;
  padding: 1rem;
  position: relative;
}

.icon-button:hover {
  background-color: var(--border-color);
  color: var(--text-primary);
}

/* Chat Button specific styles */
.chat-button {
  position: relative;
}

.chat-dot {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 8px;
  height: 8px;
  background-color: #E2384D;
  border-radius: 50%;
  border: 2px solid var(--bg-primary);
}

/* Notification Button specific styles */
.notification-button {
  position: relative;
}

.notification-dot {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 8px;
  height: 8px;
  background-color: #E2384D;
  border-radius: 50%;
  border: 2px solid var(--bg-primary);
}

.profile-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  border: none;
  background: transparent !important;
  cursor: pointer;
}

.profile-picture {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 9999px;
  object-fit: cover;
  border: 2px solid var(--border-color);
  transition: all 0.15s ease;
}

.top-bar-left {
  display: flex;
  align-items: center; /* Ensures vertical alignment */
  gap: 1rem;
  flex-shrink: 0;
}

.logo-text {
  font-size: 1.5rem;
  font-weight: 600;
  color: #E2384D;
  display: flex; /* Added to improve alignment */
  align-self: center; /* Ensure text is centered */
  line-height: 1; /* This helps with vertical alignment */
}

/* New trial card styles */
/* Trial card container */
.trial-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--bg-secondary);
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  flex: 1;
  margin: 0 1.5rem;
  border: 1px solid var(--border-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 900px;
}

/* Left side with icon and text */
.trial-info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  flex: 1;
}

/* Crown icon */
.trial-icon {
  color: #E2384D;
  font-size: 1.25rem;
  flex-shrink: 0;
}

/* Text container */
.trial-text {
  font-size: 0.9rem;
  color: var(--text-primary);
  font-weight: 500;
  margin-right: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Button */
.trial-action {
  background: #E2384D;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-weight: 500;
  font-size: 0.875rem;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
  flex-shrink: 0;
  max-width: 200px;
  text-align: center;
}

.trial-action:hover {
  background: #cb1d32;
}


.overlay {
  position: fixed;
  top: 3.5rem;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: 59;
  animation: fadeIn 0.2s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Dark mode support */
:root[class="dark"] .chat-dot,
:root[class="dark"] .notification-dot {
  background-color: #ff4d6a;
  border-color: var(--bg-primary);
}

:root[class="dark"] .trial-card {
  background-color: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.1);
}

.auth-buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.auth-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-weight: 500;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
  min-width: fit-content;
}

.login-button {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
}

.login-button:hover {
  background-color: var(--border-color);
}

.signup-button {
  background-color: #E2384D;
  color: white;
}

.signup-button:hover {
  background-color: #cb1d32;
}

.auth-icon {
  font-size: 1rem;
}

/* Responsive styles */
@media (max-width: 600px) {
  .auth-button span {
    display: none;
  }
  
  .auth-button {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    justify-content: center;
  }
  
  .auth-icon {
    font-size: 1.25rem;
  }
}

/* Responsive styles */
@media (max-width: 1024px) {
  .trial-card {
    max-width: 550px;
  }
}

@media (max-width: 768px) {
  .trial-card {
    max-width: 400px;
    padding: 0.375rem 0.75rem;
    margin: 0 1rem;
  }
  
  .trial-text {
    font-size: 0.8rem;
  }
  
  .trial-action {
    min-width: 120px;
    padding: 0.375rem 0.75rem;
  }
}

@media (max-width: 600px) {
  .trial-card {
    max-width: none;
    width: auto;
  }
  
  .trial-text {
    display: none;
  }
  
  .trial-info {
    justify-content: center;
  }
  
  .trial-action {
    flex: 1;
    max-width: 150px;
  }
}

/* Extra small screens */
@media (max-width: 480px) {
  .top-bar {
    padding: 0.5rem 0.5rem;
  }
  
  .trial-card {
    min-width: 0;
    margin: 0 0.375rem;
    padding: 0.25rem 0.5rem;
  }
  
  .trial-info {
    margin-right: 0.5rem;
  }
  
  .trial-icon {
    font-size: 1rem;
  }
  
  .trial-text {
    max-width: 90px;
    font-size: 0.7rem;
  }
  
  .trial-action {
    padding: 0.25rem 0.5rem;
    font-size: 0.7rem;
    min-width: 80px;
  }
}