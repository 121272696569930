.mainLayout {
    display: flex;
    min-height: 100vh;
    padding-top: 3.5rem;
    background-color: var(--page-bg);
  }
  
  .verificationMain {
    flex: 1;
    margin-left: 240px;
    margin-right: 270px;
    min-width: 0;
    height: calc(100vh - 3.5rem);
    overflow-y: auto;
  }
  
  .verificationContent {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    color: var(--text-primary);
  }
  
  .description {
    font-size: 16px;
    text-align: center;
    margin-bottom: 2rem;
    color: var(--text-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
  
  .verifiedTick {
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
  }
  
  .formContainer {
    background-color: var(--bg-primary);
    border: 1px solid var(--border-color);
    border-radius: 12px;
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .documentSelect {
    position: relative;
    margin-bottom: 1.5rem;
  }
  
  .selectButton {
    width: 100%;
    padding: 0.75rem 1rem;
    background-color: var(--bg-primary);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: var(--text-primary);
    transition: border-color 0.2s ease;
  }
  
  .selectButton:hover {
    border-color: var(--primary);
  }
  
  .dropdownMenu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--bg-primary);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    margin-top: 0.5rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    z-index: 10;
  }
  
  .dropdownItem {
    padding: 0.75rem 1rem;
    cursor: pointer;
    color: var(--text-primary);
    transition: background-color 0.2s ease;
  }
  
  .dropdownItem:hover {
    background-color: var(--bg-secondary);
  }
  
  .uploadContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  .uploadBox {
    border: 2px dashed var(--border-color);
    border-radius: 8px;
    padding: 1.5rem;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .uploadBox:hover {
    border-color: var(--primary);
  }
  
  .uploadIcon {
    font-size: 2rem;
    color: var(--text-secondary);
    margin-bottom: 0.5rem;
  }
  
  .uploadText {
    color: var(--text-secondary);
    font-size: 0.875rem;
  }
  
  .imagePreviewContainer {
    position: relative;
    width: 100%;
    height: 200px;
  }
  
  .imagePreview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .checkIcon {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    color: #10b981;
    font-size: 1.5rem;
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.3));
  }
  
  .submitButton {
    width: 100%;
    padding: 1rem;
    background-color: var(--primary);
    color: var(--text-primary);
    border: none;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .submitButton:hover {
    background-color: var(--primary-dark);
  }
  
  .submitButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .successContainer {
    text-align: center;
    padding: 2rem;
  }
  
  .successMessage {
    font-size: 1.125rem;
    color: var(--text-primary);
    margin-bottom: 2rem;
  }
  
  .pendingImage {
    width: 200px;
    height: 200px;
    margin: 2rem auto;
    object-fit: contain;
  }
  
  .goToProfileButton {
    display: inline-block;
    padding: 0.75rem 0.5rem;
    background-color: var(--primary);
    color: var(--text-primary);
    border-radius: 8px;
    font-weight: 600;
    text-decoration: none;
    transition: background-color 0.2s ease;
  }
  
  .goToProfileButton:hover {
    background-color: #E2384D;
  }
  
  /* Loading Spinner */
  .loadingSpinner {
    width: 40px;
    height: 40px;
    border: 3px solid var(--border-color);
    border-top-color: var(--primary);
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
    margin: 0 auto;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .verificationMain {
      margin: 0;
      padding: 1rem;
    }
  
    .uploadContainer {
      grid-template-columns: 1fr;
    }
  
    .verificationContent {
      padding: 1rem;
    }
  
    .formContainer {
      padding: 1rem;
    }
  }

  .descriptionContainer {
    margin-bottom: 2rem;
  }
  
  .description {
    margin-bottom: 1rem;
    line-height: 1.5;
    color: #374151;
  }
  
  .verificationBenefit {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    line-height: 1.5;
    color: #374151;
  }
  
  .inlineVerifiedTick {
    width: 20px;
    height: 20px;
    margin: 0 4px;
    vertical-align: middle;
  }
  
  .verifiedContainer {
    text-align: center;
    padding: 2rem;
  }
  
  .verifiedHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .verifiedImage {
    width: 48px;
    height: 48px;
  }
  
  .verifiedTitle {
    font-size: 1.5rem;
    font-weight: 600;
    color: #10b981;
  }
  
  .verifiedMessage {
    font-size: 1.125rem;
    color: #374151;
    margin-bottom: 2rem;
  }
  
  .submittedDocuments {
    margin: 2rem 0;
  }
  
  .documentType {
    font-weight: 500;
    margin-bottom: 1rem;
    color: var(--text-primary);
  }
  
  .documentPreview {
    display: flex;
    gap: 1rem;
    justify-content: center;
  }
  
  .previewImage {
    width: 200px;
    height: 150px;
    object-fit: cover;
    border-radius: 0.5rem;
    border: 2px solid #e5e7eb;
  }
  
  /* Dark Mode Styles */
  :root[class="dark"] .formContainer {
    background-color: var(--bg-secondary);
  }
  
  :root[class="dark"] .selectButton {
    background-color: var(--bg-secondary);
  }
  
  :root[class="dark"] .dropdownMenu {
    background-color: var(--bg-secondary);
  }
  
  :root[class="dark"] .dropdownItem:hover {
    background-color: var(--bg-primary);
  }