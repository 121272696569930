.container {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #111;
    border-radius: 8px;
    color: #fff;
    box-shadow: 0 4px 20px rgba(226, 56, 77, 0.3);
    border: 1px solid rgba(226, 56, 77, 0.2);
  }
  
  h1 {
    color: #E2384D;
    margin-bottom: 1rem;
    font-size: 2rem;
    text-align: center;
  }
  
  .description {
    margin-bottom: 2rem;
    color: #ccc;
    text-align: center;
  }
  
  .uploadContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  
  .fileLabel {
    background-color: #222;
    color: #fff;
    padding: 1rem;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    border: 1px solid rgba(226, 56, 77, 0.3);
    transition: all 0.3s ease;
  }
  
  .fileLabel:hover {
    background-color: #333;
    border-color: rgba(226, 56, 77, 0.5);
  }
  
  .fileLabel input {
    display: none;
  }
  
  .importButton {
    background-color: #E2384D;
    color: #fff;
    border: none;
    padding: 1rem;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px;
    transition: transform 0.2s ease, background-color 0.3s ease;
  }
  
  .importButton:hover:not(:disabled) {
    transform: scale(1.02);
    background-color: #d02e41;
  }
  
  .importButton:disabled {
    background-color: #555;
    cursor: not-allowed;
    opacity: 0.7;
  }
  
  .status {
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 4px;
    font-weight: 500;
  }
  
  .success {
    background-color: rgba(25, 135, 84, 0.2);
    color: #4ade80;
    border: 1px solid rgba(25, 135, 84, 0.3);
  }
  
  .error {
    background-color: rgba(220, 53, 69, 0.2);
    color: #f87171;
    border: 1px solid rgba(220, 53, 69, 0.3);
  }
  
  .progress {
    background-color: rgba(13, 110, 253, 0.2);
    color: #93c5fd;
    border: 1px solid rgba(13, 110, 253, 0.3);
  }
  
  .preview {
    margin-top: 2rem;
    background-color: #222;
    padding: 1rem;
    border-radius: 4px;
    border: 1px solid rgba(226, 56, 77, 0.2);
  }
  
  .preview h3 {
    color: #E2384D;
    margin-bottom: 1rem;
  }
  
  .brandsPreview {
    max-height: 200px;
    overflow-y: auto;
    padding: 0.5rem;
  }
  
  .brandItem {
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.1);
  }
  
  .brandItem strong {
    color: #E2384D;
  }
  
  @media (max-width: 768px) {
    .container {
      margin: 1rem;
      padding: 1.5rem;
    }
  }