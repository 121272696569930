/* Base container and component styles */
.search-component {
  position: relative;
  width: 100%;
}

.search-container {
  display: flex;
  width: 100%;
  gap: 1rem;
  position: relative;
  margin-bottom: 1.5rem;
  padding: 0;
}

/* Input wrapper */
.search-input-wrapper {
  position: relative;
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
}

/* Search input */
.search-input {
  width: 100%;
  height: 20px;
  padding: 0 2.5rem 0 2.5rem;
  border-radius: 0.5rem;
  border: 2px dashed var(--border-color);
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  font-size: 0.875rem;
  transition: all 0.2s ease;
  line-height: 20px;
}

/* Search icon alignment */
.search-icon {
  position: absolute;
  left: 1rem;
  top: 35%;
  transform: translateY(-50%);
  color: var(--text-secondary);
  font-size: 0.875rem;
  z-index: 1;
  pointer-events: none;
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Clear button */
.clear-button {
  position: absolute;
  right: 0.75rem;
  top: 35%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: 20px;
  height: 20px;
}

/* Search button */
.search-button {
  height: 40px;
  padding: 0 1.5rem;
  background-color: #E2384D;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  width: 120px;
  flex: 0 0 120px;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* New Suggestions Dropdown Styles */
.suggestions-dropdown {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  background-color: var(--bg-secondary);
  border: 2px solid var(--border-color);
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}

.suggestion-item {
  padding: 0.75rem 1rem;
  color: var(--text-primary);
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
}

.suggestion-item:hover {
  background-color: rgba(226, 56, 77, 0.1);
  color: #E2384D;
}

.suggestion-item:not(:last-child) {
  border-bottom: 1px solid var(--border-color);
}

/* No Results Message */
.no-results-message {
  margin-top: 0.5rem;
  padding: 0.75rem;
  color: var(--text-secondary);
  text-align: center;
  font-size: 0.875rem;
  background-color: var(--bg-secondary);
  border-radius: 0.5rem;
  border: 2px dashed var(--border-color);
}

/* Placeholder text styling */
.search-input::placeholder {
  color: var(--text-secondary);
  opacity: 0.8;
  line-height: 40px;
  padding-left: 25px;
  vertical-align: middle;
}

/* Focus states */
.search-input:focus {
  outline: none;
  border-color: #E2384D;
  border-style: solid;
}

.search-input:hover {
  border-color: #E2384D;
}

/* Button hover states */
.search-button:hover {
  background-color: #d42e41;
}

.search-button:disabled {
  background-color: #e57585;
  cursor: not-allowed;
}

/* Clear button hover */
.clear-button:hover {
  color: #E2384D;
}

/* Loading spinner */
.spinner {
  width: 1rem;
  height: 1rem;
  border: 2px solid white;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Theme variables */
:root {
  --bg-secondary: #f3f4f6;
  --text-primary: #111827;
  --text-secondary: #6b7280;
  --border-color: #e5e7eb;
}

/* Dark theme */
:root[class="dark"] {
  --bg-secondary: #1f2937;
  --text-primary: #ffffff;
  --text-secondary: #9ca3af;
  --border-color: #374151;
}

/* Responsive styles */
@media (max-width: 640px) {
  .search-container {
    padding: 0 1rem;
  }
  
  .search-button {
    min-width: 80px;
    padding: 0 1rem;
  }
  
  .search-input {
    font-size: 1rem;
  }
  
  .suggestions-dropdown {
    max-height: 160px;
  }
}