.alertModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100; /* Higher than the band modal */
    backdrop-filter: blur(4px);
  }
  
  .alertModal {
    background-color: var(--bg-primary);
    border-radius: 12px;
    padding: 24px;
    width: min(400px, 90%);
    border: 1px solid var(--border-color);
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
  }
  
  .alertHeader {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;
  }
  
  .alertIcon {
    font-size: 24px;
  }
  
  .alertIcon.error {
    color: #ef4444;
  }
  
  .alertIcon.success {
    color: #10b981;
  }
  
  .alertTitle {
    color: var(--text-primary);
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }
  
  .alertMessage {
    color: var(--text-secondary);
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 1.5;
  }
  
  .alertActions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
  
  .alertButton {
    padding: 8px 16px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    border: none;
  }
  
  .alertButton.error {
    background: #ef4444;
    color: white;
  }
  
  .alertButton.success {
    background: #10b981;
    color: white;
  }
  
  .alertButton:hover {
    transform: translateY(-1px);
  }.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(4px);
  }
  
  .modalContent {
    background-color: var(--bg-primary);
    border-radius: 12px;
    width: 90%;
    max-width: 800px;
    max-height: 90vh;
    overflow-y: auto;
    padding: 2rem;
    position: relative;
  }
  
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .modalHeader h2 {
    font-size: 1.5rem;
    color: var(--text-primary);
    margin: 0;
  }
  
  .closeButton {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: var(--text-secondary);
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 50%;
    transition: all 0.2s ease;
  }
  
  .closeButton:hover {
    background-color: var(--bg-secondary);
    color: var(--text-primary);
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  /* Pictures Section */
  .picturesSection {
    position: relative;
    margin-bottom: 3rem;
  }
  
  .coverPhotoContainer {
    width: 100%;
    height: 200px;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 3rem;
  }
  
  .coverPhoto {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .editCoverPhotoButton {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    border: none;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .editCoverPhotoButton:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .profilePictureContainer {
    position: absolute;
    left: 2rem;
    bottom: -2rem;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid var(--bg-primary);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }
  
  .profilePicture {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .editProfilePictureButton {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    border: none;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .editProfilePictureButton:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .hiddenInput {
    display: none;
  }
  
  /* Form Sections */
  .section {
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 2rem;
  }
  
  .section:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
  
  .section h3 {
    font-size: 1.25rem;
    color: var(--text-primary);
    margin: 0 0 1.5rem 0;
  }
  
  .field {
    margin-bottom: 1.5rem;
  }
  
  .field:last-child {
    margin-bottom: 0;
  }
  
  .field label {
    display: block;
    font-weight: 500;
    color: var(--text-primary);
    margin-bottom: 0.5rem;
  }
  
  .field input,
  .field textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    font-size: 1rem;
  }
  
  .field textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .field small {
    display: block;
    color: var(--text-secondary);
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }
  
  /* Genres Grid */
  .genreGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 0.5rem;
  }
  
  .genreButton {
    padding: 0.5rem;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background: var(--bg-secondary);
    color: var(--text-primary);
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .genreButton.selected {
    background: #E2384D;
    color: white;
    border-color: #E2384D;
  }
  
  /* Location Fields */
  .locationFields {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }
  
  /* Social Links */
  .socialInputGroup {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  
  .socialIcon {
    font-size: 1.5rem;
    width: 24px;
    color: #E2384D;
  }
  
  .socialIconContainer {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .brandSocialIcon {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
  
  /* Submit Button */
  .submitButton {
    width: 100%;
    padding: 1rem;
    background-color: #E2384D;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-top: 2rem;
  }
  
  .submitButton:hover:not(:disabled) {
    background-color: #cc2d43;
  }
  
  .submitButton:disabled {
    background-color: var(--text-secondary);
    cursor: not-allowed;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .modalContent {
      padding: 1rem;
      height: 100vh;
      max-height: none;
      border-radius: 0;
    }
  
    .locationFields {
      grid-template-columns: 1fr;
    }
  
    .genreGrid {
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    }
  
    .profilePictureContainer {
      width: 100px;
      height: 100px;
      left: 1rem;
    }
  
    .coverPhotoContainer {
      height: 150px;
    }
  }
  
  /* Dark Mode Support */
  :root[class="dark"] .modalContent {
    background-color: var(--bg-secondary);
  }
  
  :root[class="dark"] .field input,
  :root[class="dark"] .field textarea {
    background-color: var(--bg-primary);
  }
  
  :root[class="dark"] .genreButton {
    background-color: var(--bg-primary);
  }