/* NotificationsPanel.module.css */

.panel {
    width: 280px;
    height: calc(100vh - 3.5rem);
    background-color: var(--bg-primary);
    position: fixed;
    right: 0;
    top: 3.5rem;
    display: flex;
    flex-direction: column;
    z-index: 60; /* Higher than RightMenu's z-index of 40 */
    border-left: 1px solid var(--border-color);
    animation: slideIn 0.2s ease-out;
  }
  
  .header {
    padding: 1rem;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--bg-primary);
  }
  
  .header h2 {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--text-primary);
    margin: 0;
  }
  
  .notificationsList {
    flex: 1;
    overflow-y: auto;
    padding: 0.5rem;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .notificationsList::-webkit-scrollbar {
    display: none;
  }
  
  .notificationItem {
    display: flex;
    gap: 1rem;
    padding: 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: all 0.2s;
    margin-bottom: 0.5rem;
    background-color: var(--bg-secondary);
    border: 1px solid var(--border-color);
  }
  
  .notificationItem:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .notificationIcon {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(226, 56, 77, 0.1);
    border-radius: 50%;
    color: #E2384D;
  }
  
  .notificationContent {
    flex: 1;
    min-width: 0;
  }
  
  .notificationHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0.25rem;
  }
  
  .notificationHeader h3 {
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--text-primary);
    margin: 0;
  }
  
  .timestamp {
    font-size: 0.75rem;
    color: var(--text-secondary);
    white-space: nowrap;
    margin-left: 0.5rem;
  }
  
  .notificationContent p {
    font-size: 0.875rem;
    color: var(--text-secondary);
    margin: 0;
    margin-bottom: 0.25rem;
    line-height: 1.4;
  }
  
  .tourName {
    font-size: 0.75rem;
    color: #E2384D;
    font-weight: 500;
  }
  
  .emptyState {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    color: var(--text-secondary);
    text-align: center;
  }
  
  .loadingSpinner {
    width: 2rem;
    height: 2rem;
    border: 2px solid var(--border-color);
    border-top-color: #E2384D;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 2rem auto;
  }
  
  .loadMoreButton {
    width: 100%;
    padding: 0.75rem;
    background-color: var(--bg-secondary);
    border: 1px solid var(--border-color);
    border-radius: 0.375rem;
    color: #E2384D;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
    margin-top: 0.5rem;
  }
  
  .loadMoreButton:hover:not(:disabled) {
    background-color: rgba(226, 56, 77, 0.1);
  }
  
  .loadMoreButton:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  .overlay {
    position: fixed;
    top: 3.5rem;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    z-index: 59; /* Just below the panel but above RightMenu */
    animation: fadeIn 0.2s ease-out;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Dark mode support */
  :root[class="dark"] .notificationItem {
    background-color: var(--bg-secondary);
  }
  
  :root[class="dark"] .loadMoreButton {
    background-color: var(--bg-secondary);
    color: #ff4d6a;
  }
  
  :root[class="dark"] .loadMoreButton:hover:not(:disabled) {
    background-color: rgba(255, 77, 106, 0.1);
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .panel {
      width: 100%;
      max-width: 100%;
      height: 100vh;
      top: 0;
    }
  
    .overlay {
      top: 0;
    }
  }