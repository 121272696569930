/* Layout Structure */
.layout {
    min-height: 100vh;
    width: 100%;
    background-color: var(--page-bg);
  }
  
  .mainLayout {
    display: flex;
    min-height: calc(100vh - 3.5rem);
    margin-top: 3.5rem;
    width: 100%;
    position: relative;
  }
  
  .content {
    flex: 1;
    padding: 24px;
    margin-left: 240px; /* Width of LeftMenu */
    margin-right: 280px; /* Width of RightMenu */
    width: calc(100% - 490px);
    background-color: var(--page-bg);
    min-width: 0;
    overflow-x: hidden;
  }
  
  /* Header Styles */
  .header {
    background-color: var(--bg-primary);
    border-radius: 12px;
    padding: 24px;
    margin-bottom: 24px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    text-align: center;
  }
  
  .header h1 {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text-primary);
    margin: 0;
  }
  
  .header h2 {
    font-size: 1.2rem;
    color: var(--text-secondary);
    margin: 8px 0 0;
  }
  
  .backButton {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background: none;
    border: none;
    color: var(--text-primary);
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 16px;
  }
  
  .backButton:hover {
    background-color: var(--border-color);
  }
  
  /* Task Section */
  .taskSection {
    background-color: var(--bg-primary);
    border-radius: 12px;
    padding: 24px;
  }
  
  .createButton {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 12px 24px;
    background-color: #E2384D;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;
    margin-bottom: 24px;
  }
  
  .createButton:hover {
    background-color: #cc2b3d;
  }
  
  /* Task List */
  .taskList {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  
  .taskCard {
    background-color: var(--bg-secondary);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding: 16px;
    transition: transform 0.2s;
  }
  
  .taskCard:hover {
    transform: translateY(-2px);
  }
  
  .taskHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 12px;
  }
  
  .taskHeader h3 {
    margin: 0;
    font-size: 1.1rem;
    color: var(--text-primary);
  }
  
  .taskActions {
    display: flex;
    gap: 8px;
  }
  
  .actionButton {
    padding: 8px;
    background: none;
    border: none;
    color: var(--text-secondary);
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.2s;
  }
  
  .actionButton:hover {
    background-color: var(--border-color);
    color: var(--text-primary);
  }
  
  .actionButton.deleteButton:hover {
    background-color: rgba(226, 56, 77, 0.1);
    color: #E2384D;
  }
  
  .taskDetails {
    color: var(--text-secondary);
    margin-bottom: 16px;
    line-height: 1.5;
  }
  
  .taskMeta {
    border-top: 1px solid var(--border-color);
    padding-top: 12px;
    margin-bottom: 12px;
  }
  
  .assignedInfo {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 4px;
    color: var(--text-secondary);
    font-size: 0.9rem;
  }
  
  .metaLabel {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--text-primary);
    font-weight: 500;
  }
  
  .completedSection {
    border-top: 1px solid var(--border-color);
    padding-top: 12px;
  }
  
  /* Modal Styles */
  .modalOverlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalContent {
    background-color: var(--bg-primary);
    border-radius: 12px;
    padding: 24px;
    width: 90%;
    max-width: 500px;
    position: relative;
    max-height: 90vh;
    overflow-y: auto;
  }
  
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  
  .modalHeader h2 {
    margin: 0;
    font-size: 1.5rem;
    color: var(--text-primary);
  }
  
  .closeButton {
    background: none;
    border: none;
    color: var(--text-secondary);
    cursor: pointer;
    padding: 8px;
    border-radius: 4px;
  }
  
  .closeButton:hover {
    background-color: var(--border-color);
    color: var(--text-primary);
  }
  
  .modalForm {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .formGroup {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .formGroup label {
    color: var(--text-primary);
    font-weight: 500;
  }
  
  .input,
  .textarea,
  .select {
    padding: 8px 12px;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    font-size: 1rem;
  }
  
  .textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .select {
    cursor: pointer;
  }
  
  .input:focus,
  .textarea:focus,
  .select:focus {
    outline: none;
    border-color: #E2384D;
  }
  
  .checkboxLabel {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    color: var(--text-primary);
  }
  
  .checkbox {
    width: 16px;
    height: 16px;
    accent-color: #E2384D;
  }
  
  .modalActions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;
  }
  
  .cancelButton,
  .submitButton {
    padding: 10px 20px;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .cancelButton {
    background-color: var(--bg-secondary);
    border: 1px solid var(--border-color);
    color: var(--text-primary);
  }
  
  .cancelButton:hover:not(:disabled) {
    background-color: var(--border-color);
  }
  
  .submitButton {
    background-color: #E2384D;
    border: none;
    color: white;
  }
  
  .submitButton:hover:not(:disabled) {
    background-color: #cc2b3d;
  }
  
  .cancelButton:disabled,
  .submitButton:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  /* Loading States */
  .loadingOverlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1100;
  }
  
  .loadingSpinner {
    width: 40px;
    height: 40px;
    border: 3px solid var(--border-color);
    border-top: 3px solid #E2384D;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  .loadingText {
    color: white;
    margin-top: 16px;
    font-size: 16px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Empty State */
  .emptyState {
    text-align: center;
    padding: 48px;
    color: var(--text-secondary);
  }
  
  .emptyState p {
    margin: 8px 0 0;
    font-size: 1.1rem;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .content {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      padding: 16px;
    }
  
    .header {
      padding: 16px;
    }
  
    .taskSection {
      padding: 16px;
    }
  
    .taskList {
      grid-template-columns: 1fr;
    }
  
    .modalContent {
      width: 95%;
      margin: 16px;
      padding: 16px;
    }
  
    .modalActions {
      flex-direction: column;
    }
  
    .cancelButton,
    .submitButton {
      width: 100%;
    }
  
    .taskCard {
      margin-bottom: 16px;
    }
  
    .taskHeader {
      flex-direction: column;
      gap: 12px;
    }
  
    .taskActions {
      width: 100%;
      justify-content: flex-end;
    }
  }
  
  /* Adjustments for larger screens */
  @media (min-width: 1281px) {
    .content {
      margin-left: 240px;
      margin-right: 280px;
      width: calc(100% - 490px);
    }
  }
  
  /* Dark mode support */
  :root[class="dark"] .modalContent {
    background-color: var(--bg-primary);
    border: 1px solid var(--border-color);
  }
  
  :root[class="dark"] .input,
  :root[class="dark"] .textarea,
  :root[class="dark"] .select {
    background-color: var(--bg-secondary);
    border-color: var(--border-color);
    color: var(--text-primary);
  }
  
  :root[class="dark"] .taskCard {
    background-color: var(--bg-secondary);
    border-color: var(--border-color);
  }
  
  :root[class="dark"] .actionButton {
    color: var(--text-secondary);
  }
  
  :root[class="dark"] .actionButton:hover {
    background-color: var(--border-color);
    color: var(--text-primary);
  }
  
  :root[class="dark"] .actionButton.deleteButton:hover {
    background-color: rgba(226, 56, 77, 0.2);
    color: #ff4d6a;
  }
  
  /* Accessibility Improvements */
  .input:focus,
  .textarea:focus,
  .select:focus,
  .checkbox:focus,
  .actionButton:focus,
  .createButton:focus,
  .backButton:focus {
    outline: 2px solid #E2384D;
    outline-offset: 2px;
  }
  
  /* Focus visible styles for keyboard navigation */
  .input:focus-visible,
  .textarea:focus-visible,
  .select:focus-visible,
  .checkbox:focus-visible,
  .actionButton:focus-visible,
  .createButton:focus-visible,
  .backButton:focus-visible {
    outline: 2px solid #E2384D;
    outline-offset: 2px;
  }
  
  /* Animation Enhancements */
  .modalContent {
    animation: modalSlideIn 0.3s ease-out;
  }
  
  @keyframes modalSlideIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Scrollbar Styles */
  .modalContent::-webkit-scrollbar,
  .textarea::-webkit-scrollbar {
    width: 6px;
  }
  
  .modalContent::-webkit-scrollbar-track,
  .textarea::-webkit-scrollbar-track {
    background: var(--bg-secondary);
  }
  
  .modalContent::-webkit-scrollbar-thumb,
  .textarea::-webkit-scrollbar-thumb {
    background-color: var(--border-color);
    border-radius: 3px;
  }
  
  /* Status Indicators */
  .taskCard.completed {
    border-left: 4px solid #4CAF50;
  }
  
  .taskCard.pending {
    border-left: 4px solid #FFC107;
  }
  
  /* Hover Effects */
  .createButton,
  .submitButton,
  .cancelButton,
  .actionButton {
    position: relative;
    overflow: hidden;
  }
  
  .createButton::after,
  .submitButton::after,
  .cancelButton::after,
  .actionButton::after {
    content: '';
    position: absolute;
    inset: 0;
    background-color: currentColor;
    opacity: 0;
    transition: opacity 0.2s;
  }
  
  .createButton:hover::after,
  .submitButton:hover::after,
  .cancelButton:hover::after,
  .actionButton:hover::after {
    opacity: 0.1;
  }
  
  /* Print Styles */
  @media print {
    .createButton,
    .actionButton,
    .backButton,
    .modalOverlay {
      display: none;
    }
  
    .content {
      margin: 0;
      padding: 20px;
    }
  
    .taskCard {
      break-inside: avoid;
      border: 1px solid #ccc;
    }
  
    .header {
      text-align: left;
      padding: 0;
      margin-bottom: 20px;
    }
  }