/* Layout Structure */
.schedule-layout {
  min-height: 100vh;
  width: 100%;
  background-color: var(--page-bg);
}

.main-layout {
  display: flex;
  min-height: calc(100vh - 3.5rem);
  margin-top: 3.5rem;
  width: 100%;
  position: relative;
}

.schedule-main {
  flex: 1;
  padding: 24px;
  margin-left: 240px; /* Width of LeftMenu */
  margin-right: 280px; /* Width of RightMenu */
  width: calc(100% - 490px); /* Total width minus both menus */
  background-color: var(--page-bg);
  min-width: 0; /* Prevents flex items from overflowing */
  overflow-x: hidden; /* Prevents horizontal scroll */
}


/* Header Styles */
.schedule-header {
  background-color: var(--bg-primary);
  border-radius: 12px;
  padding: 4px;
  margin-bottom: 0px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}


.header-date h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 0px;
}

.header-address {
  text-align: center;
  color: var(--text-secondary);
  margin: 8px 0;
}

.header-date {
  position: relative;
  text-align: center;
}

.rescheduled-info {
  color: #FFA500;
  font-style: italic;
  font-size: 0.9rem;
  margin-top: 4px;
  padding: 4px 8px;
  background-color: rgba(255, 165, 0, 0.1);
  border-radius: 4px;
  display: inline-block;
}

.cancelled-info {
  color: #E2384D;
  font-style: italic;
  font-size: 0.9rem;
  margin-top: 4px;
  padding: 4px 8px;
  background-color: rgba(226, 56, 77, 0.1);
  border-radius: 4px;
  display: inline-block;
}

.header-actions {
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex;
  gap: 12px;
}

.actionButton {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: #E2384D;
  font-size: 1.25rem;
  transition: all 0.2s ease;
  border-radius: 50%;
}

.actionButton:hover {
  background-color: rgba(226, 56, 77, 0.1);
}

.actionButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Modal Styles */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background-color: var(--bg-primary);
  padding: 24px;
  border-radius: 12px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 10000;
  margin: 0 auto;
}

.modal-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: var(--text-primary);
}

.modal-message {
  font-size: 1rem;
  color: var(--text-secondary);
  margin-bottom: 24px;
  line-height: 1.5;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.modal-button {
  padding: 10px 20px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
}

.cancel-button {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
}

.cancel-button:hover:not(:disabled) {
  background-color: var(--border-color);
}

.delete-button {
  background-color: #E2384D;
  color: white;
}

.delete-button:hover:not(:disabled) {
  background-color: #d42e41;
}

.modal-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* For dark mode support */
@media (prefers-color-scheme: dark) {
  .modal-content {
    background-color: var(--bg-primary);
    border: 1px solid var(--border-color);
  }
}

.modalContent {
  background-color: var(--bg-primary);
  padding: 24px;
  border-radius: 12px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modalTitle {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 16px;
  text-align: center;
  color: var(--text-primary);
}



.dateInput {
  width: 100%;
  padding: 12px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  margin-bottom: 20px;
  font-size: 1rem;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  cursor: pointer;
}

/* Style the calendar popup for Webkit browsers */
.dateInput::-webkit-calendar-picker-indicator {
  filter: var(--theme-calendar-icon);
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
}

.dateInput::-webkit-calendar-picker-indicator:hover {
  background-color: var(--border-color);
}

/* Style calendar popup for Firefox */
.dateInput::-moz-calendar-picker-indicator {
  filter: var(--theme-calendar-icon);
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
}

.dateInput::-moz-calendar-picker-indicator:hover {
  background-color: var(--border-color);
}

/* Add focus state */
.dateInput:focus {
  outline: none;
  border-color: #E2384D;
  box-shadow: 0 0 0 2px rgba(226, 56, 77, 0.2);
}



.modalButtons {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.modalButton {
  padding: 12px;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.saveButton {
  background-color: #E2384D;
  color: white;
}

.saveButton:hover {
  background-color: #cc2b3d;
}

.cancelButton {
  background-color: #dc3545;
  color: white;
}

.cancelButton:hover {
  background-color: #c82333;
}

.cancel-button:hover:not(:disabled) {
  background-color: #ebebeb;
}


.delete-button {
  background-color: #E2384D;
  color: white;
  border: none;
}

.delete-button:hover:not(:disabled) {
  background-color: #d42e41;
}


.closeButton {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
}

.closeButton:hover {
  background-color: var(--border-color);
}

/* Content Section */

.content-section {
  background-color: var(--bg-primary);
  border-radius: 12px;
  padding: 24px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

/* Add Schedule Button */
.add-schedule-btn {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  margin-bottom: 16px;
  background-color: #E2384D;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.add-schedule-btn:hover {
  background-color: #cc2b3d;
}

.add-schedule-btn svg {
  margin-right: 8px;
}

/* Section Buttons */
.section-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  color: var(--text-primary);
  margin-bottom: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.section-button:hover {
  background-color: var(--border-color);
}

.section-button-text {
  display: flex;
  align-items: center;
  gap: 8px;
}

.section-button svg {
  color: #E2384D;
}

/* Notes Section */
.notes-section {
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin: 24px 0;
  overflow: hidden;
}

.notes-header {
  padding: 16px;
  border-bottom: 1px solid var(--border-color);
}

.notes-content {
  padding: 16px;
}

.notes-textarea {
  width: 100%;
  min-height: 120px;
  padding: 12px;
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  color: var(--text-primary);
  resize: vertical;
}

/* Schedule Tabs */
.schedule-tabs {
  display: flex;
  background-color: var(--bg-secondary);
  padding: 4px;
  border-radius: 8px;
  margin-top: 0px;
  gap: 4px;
  width: 100%;
}

.tab-button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px;
  background: transparent;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.tab-button.active {
  background-color: var(--bg-primary);
  color: #E2384D;
}

/* Schedules Section */
.schedules-section {
  margin-top: 24px;
  width: 100%;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.section-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 0px;
}

.filter-dropdown {
  padding: 8px 16px;
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: 6px;
  color: var(--text-primary);
}

/* Counter Badge */
.counter-badge {
  background-color: #E2384D;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
}

.loading-text {
  color: white;
  margin-top: 16px;
  font-size: 16px;
}

/* Loading States */
.loading-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loading-spinner {
  border: 3px solid var(--border-color);
  border-top: 3px solid #E2384D;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Empty State */
.empty-state {
  text-align: center;
  padding: 48px 0;
  color: var(--text-secondary);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .schedule-main {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    padding: 16px;
  }

  .header-actions {
    position: static;
    justify-content: center;
    margin-top: 16px;
  }

  .modalContent {
    margin: 0 16px;
  }
  
  .content-section,
  .schedule-header {
    padding: 16px;
  }
}

/* Adjustments for larger screens */
@media (max-width: 1280px) {
  .schedule-main {
    margin-left: 240px;
    margin-right: 280px;
    width: calc(100% - 490px);
  }

}

@media (prefers-color-scheme: dark) {
  .dateInput {
    background-color: var(--bg-secondary);
    border-color: var(--border-color);
  }
}