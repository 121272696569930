.profile-container {
    max-width: 100%;
    min-height: 100vh;
    background-color: #fff;
  }
  
  .cover-photo-container {
    width: 100%;
    height: 300px;
    overflow: hidden;
    position: relative;
  }
  
  .cover-photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .profile-content {
    max-width: 1200px;
    margin: -100px auto 0;
    padding: 0 20px;
    position: relative;
  }
  
  .profile-header {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 20px;
    align-items: start;
    margin-bottom: 30px;
  }
  
  .profile-photo {
    width: 168px;
    height: 168px;
    border-radius: 50%;
    border: 4px solid #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  .profile-info {
    padding-top: 20px;
  }
  
  .name-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 5px;
  }
  
  .name-container h1 {
    font-size: 24px;
    margin: 0;
  }
  
  .verified-badge {
    width: 20px;
    height: 20px;
  }
  
  .user-type {
    color: #666;
    margin-bottom: 10px;
  }
  
  .location, .status {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
    color: #666;
  }
  
  .status.on-tour {
    color: #E2384D;
  }
  
  .status.available {
    color: #28a745;
  }
  
  .bio {
    margin-top: 15px;
    color: #333;
    max-width: 600px;
  }
  
  .profile-actions {
    display: flex;
    gap: 10px;
    padding-top: 20px;
  }
  
  .edit-button, .contact-button, .share-button {
    padding: 8px 20px;
    border-radius: 5px;
    border: none;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .edit-button {
    background-color: #000;
    color: #fff;
  }
  
  .contact-button {
    background-color: #E2384D;
    color: #fff;
  }
  
  .share-button {
    background-color: #f0f0f0;
    color: #333;
  }
  
  .profile-stats {
    display: flex;
    gap: 40px;
    margin: 30px 0;
    padding: 20px 0;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }
  
  .stat {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .stat-value {
    font-size: 24px;
    font-weight: 600;
  }
  
  .stat-label {
    color: #666;
    font-size: 14px;
  }
  
  .profile-tabs {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .tab {
    padding: 10px 20px;
    border: none;
    background: none;
    font-weight: 600;
    color: #666;
    cursor: pointer;
    position: relative;
  }
  
  .tab.active {
    color: #E2384D;
  }
  
  .tab.active::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #E2384D;
  }
  
  .social-links {
    display: flex;
    gap: 15px;
    margin-top: 15px;
  }
  
  .social-links a {
    color: #666;
    font-size: 20px;
    transition: color 0.2s;
  }
  
  .social-links a:hover {
    color: #E2384D;
  }

  .tour-card {
    background: #fff;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    margin-bottom: 20px;
    transition: transform 0.2s;
  }
  
  .tour-card:hover {
    transform: translateY(-2px);
  }
  
  .tour-cover {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .tour-info {
    padding: 15px;
  }
  
  .tour-info h3 {
    margin: 0 0 5px 0;
    font-size: 18px;
  }
  
  .act-name {
    color: #666;
    margin: 0 0 10px 0;
  }
  
  .tour-dates {
    font-size: 14px;
    color: #888;
    margin-bottom: 5px;
  }
  
  .shows-count {
    color: #E2384D;
    font-weight: 600;
    font-size: 14px;
  }
  
  .tour-dates {
    padding: 20px 0;
  }
  
  .date-filters {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .filter-btn {
    padding: 8px 16px;
    border: none;
    background: #f0f0f0;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .filter-btn.active {
    background: #E2384D;
    color: white;
  }
  
  .dates-list {
    display: grid;
    gap: 15px;
  }
  
  .date-card {
    background: #fff;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  }
  
  .date-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .date {
    font-weight: 600;
  }
  
  .status {
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
  }
  
  .status.on-tour {
    background: #ffebee;
    color: #E2384D;
  }
  
  .status.available {
    background: #e8f5e9;
    color: #2e7d32;
  }
  
  .venue-info h4 {
    margin: 0 0 5px 0;
    font-size: 16px;
  }
  
  .venue-info p {
    margin: 0;
    color: #666;
    font-size: 14px;
  }
  
  .load-more {
    width: 100%;
    padding: 12px;
    background: none;
    border: 2px solid #E2384D;
    color: #E2384D;
    border-radius: 8px;
    margin-top: 20px;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .load-more:hover {
    background: #E2384D;
    color: white;
  }
  

  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .profile-header {
      grid-template-columns: 1fr;
      text-align: center;
    }
  
    .profile-photo {
      width: 120px;
      height: 120px;
      margin: 0 auto;
    }
  
    .profile-info {
      text-align: center;
    }
  
    .location, .status {
      justify-content: center;
    }
  
    .profile-actions {
      justify-content: center;
    }
  
    .profile-stats {
      justify-content: center;
      gap: 20px;
    }
  
    .social-links {
      justify-content: center;
    }

    .tour-card {
          margin: 10px;
        }
      
        .date-filters {
          overflow-x: auto;
          padding: 10px;
          margin: -10px;
        }
      
        .filter-btn {
          white-space: nowrap;
        }
      
        .dates-list {
          padding: 10px;
        }
      }
  
  /* Loading State */
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 18px;
    color: #666;
  }

  