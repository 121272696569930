/* Theme variables */
:root {
    --bg-primary: #ffffff;
    --bg-secondary: #f3f4f6;
    --text-primary: #111827;
    --text-secondary: #6b7280;
    --border-color: #e5e7eb;
    --page-bg: #f5f7fb;
    --accent-color: #E2384D;
    --success-color: #10b981;
    --error-color: #ef4444;
    --shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    --gradient-primary: linear-gradient(135deg, #E2384D 0%, #cc2d43 100%);
  }
  
  :root[class="dark"] {
    --bg-primary: #1f2937;
    --bg-secondary: #111827;
    --text-primary: #ffffff;
    --text-secondary: #9ca3af;
    --border-color: #374151;
    --page-bg: #111827;
    --shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.3);
  }
  
  /* Layout Styles */
  .container {
    width: 100%;
    min-height: 100vh;
    background-color: var(--page-bg);
  }
  
  .main-layout {
    display: flex;
    min-height: calc(100vh - 3.5rem);
    margin-top: 3.5rem;
  }
  
  .content {
    flex: 1;
    margin-left: 240px;
    margin-right: 270px;
    padding: 2rem;
    background-color: var(--page-bg);
  }
  
  /* Header and Summary Section */
  .expense-header {
    margin-bottom: 2.5rem;
  }
  
  .expense-header h1 {
    font-size: 2rem;
    font-weight: 700;
    color: var(--text-primary);
    margin-bottom: 1.5rem;
  }
  
  .summary-section {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .summary-cards {
    display: flex;
    gap: 1.5rem;
    width: 100%;
  }
  
  .summary-card {
    background: var(--bg-primary);
    border-radius: 1rem;
    padding: 1.5rem;
    flex: 1;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    box-shadow: var(--shadow-md);
    transition: transform 0.2s ease;
  }
  
  .summary-card:hover {
    transform: translateY(-2px);
  }
  
  .card-icon {
    font-size: 1.5rem;
    color: var(--accent-color);
    background: rgba(226, 56, 77, 0.1);
    padding: 0.75rem;
    border-radius: 0.75rem;
  }
  
  .card-content {
    flex: 1;
  }
  
  .card-content h3 {
    font-size: 1rem;
    color: var(--text-secondary);
    margin-bottom: 0.75rem;
    font-weight: 500;
  }
  
  .amount {
    font-size: 1.75rem;
    font-weight: 700;
    color: var(--text-primary);
    line-height: 1.2;
  }
  
  /* Tab Navigation */
  .tab-container {
    margin-bottom: 2rem;
    border-bottom: 1px solid var(--border-color);
  }
  
  .tabs {
    display: flex;
    gap: 2rem;
    margin-bottom: -1px;
  }
  
  .tab {
    padding: 1rem 1.5rem;
    color: var(--text-secondary);
    font-weight: 500;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease;
    border-bottom: 2px solid transparent;
  }
  
  .tab.active {
    color: var(--accent-color);
    border-bottom-color: var(--accent-color);
  }
  
  /* Record Form */
  .record-form {
    background: var(--bg-primary);
    border-radius: 1rem;
    padding: 2rem;
    box-shadow: var(--shadow-md);
  }
  
  .form-grid {
    display: grid;
    gap: 1.5rem;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .form-group label {
    font-weight: 500;
    color: var(--text-primary);
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
  }
  
  .form-input-group {
    display: flex;
    align-items: center;
    border: 1px solid var(--border-color);
    border-radius: 0.75rem;
    background: var(--bg-primary);
  }
  
  .currency-symbol {
    padding: 0.75rem 1rem;
    color: var(--text-primary);
    border-right: 1px solid var(--border-color);
    background: var(--bg-secondary);
  }
  
  .form-input {
    flex: 1;
    padding: 0.75rem 1rem;
    border: 1px solid var(--border-color);
    background: var(--bg-primary);
    color: var(--text-primary);
    width: 100%;
    border-radius: 0.75rem;
    appearance: none;
  }
  
  .form-input option {
    background: var(--bg-primary);
    color: var(--text-primary);
    padding: 0.5rem;
  }
  
  .form-input:focus {
    outline: none;
  }
  
  .checkbox-label {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    cursor: pointer;
    color: var(--text-primary);
  }
  
  .checkbox-input {
    width: 1.25rem;
    height: 1.25rem;
    margin: 0;
  }
  
  /* User Selection Grid */
  .users-grid {
    background: var(--bg-secondary);
    border-radius: 0.75rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .user-item {
    background: var(--bg-primary);
    border-radius: 0.5rem;
    padding: 0.75rem;
  }
  
  .user-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }
  
  .checkbox-container {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    flex: 1;
  }
  
  .user-name {
    font-weight: 500;
    color: var(--text-primary);
  }
  
  .amount-input {
    width: 120px;
    text-align: right;
    padding: 0.5rem 0.75rem;
    border: 1px solid var(--border-color);
    border-radius: 0.5rem;
    font-size: 0.875rem;
    background: var(--bg-primary);
    color: var(--text-primary);
  }
  
  /* History Section */
  .history-content {
    background: var(--bg-primary);
    border-radius: 1rem;
    padding: 1.5rem;
    box-shadow: var(--shadow-md);
  }
  
  .dates-list {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  
  .date-card {
    background: var(--bg-primary);
    padding: 1rem;
    border-radius: 0.75rem;
    border: 1px solid var(--border-color);
    cursor: pointer;
    transition: all 0.2s ease;
    color: var(--text-primary);
  }
  
  .date-card:hover {
    transform: translateY(-2px);
    box-shadow: var(--shadow-md);
  }
  
  .date {
    font-weight: 600;
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .expense-count {
    color: var(--text-secondary);
    font-size: 0.875rem;
  }
  
  .expense-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .expense-card {
    background: var(--bg-secondary);
    border-radius: 0.75rem;
    padding: 1.25rem;
    transition: transform 0.2s ease;
  }
  
  .expense-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
  }
  
  .expense-info {
    flex: 1;
  }
  
  .expense-amount {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--accent-color);
    margin-bottom: 0.25rem;
  }
  
  .paid-by {
    font-size: 0.875rem;
    color: var(--text-secondary);
  }
  
  .expense-note {
    color: var(--text-primary);
    margin: 0.5rem 0;
  }
  
  .split-details {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid var(--border-color);
  }
  
  .split-details h4 {
    color: var(--text-primary);
    margin-bottom: 0.75rem;
  }
  
  .split-item {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    color: var(--text-primary);
    gap: 1rem;
  }
  
  .split-info {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    flex: 1;
  }
  
  /* Modal Styles */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(4px);
  }
  
  .modal-content {
    background: var(--bg-primary);
    border-radius: 1rem;
    padding: 2rem;
    width: 90%;
    max-width: 600px;
    max-height: 80vh;
    overflow-y: auto;
    color: var(--text-primary);
    box-shadow: var(--shadow-md);
  }

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.modal-header h3 {
  color: var(--text-primary);
  font-size: 1.25rem;
  font-weight: 600;
}

.close-button {
  background: var(--bg-secondary);
  border: none;
  color: var(--text-primary);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  flex-shrink: 0;
}

.close-button:hover {
  background: var(--border-color);
}

.modal-body {
  margin-bottom: 1.5rem;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid var(--border-color);
}

.date-selection-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-bottom: 0.5rem;
  color: var(--text-primary);
  background: var(--bg-primary);
}

.date-selection-item:hover {
  background: var(--bg-secondary);
}

.date-selection-item.selected {
  border-color: var(--accent-color);
  background: rgba(226, 56, 77, 0.1);
}

.check-icon {
  color: var(--accent-color);
}

/* Buttons and Actions */
.generate-report-button {
  background: var(--gradient-primary);
  color: white;
  padding: 0.75rem 1.25rem;
  border-radius: 0.75rem;
  border: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: var(--shadow-sm);
  width: fit-content;
  align-self: flex-end;
}

.generate-report-button:hover {
  transform: translateY(-1px);
  box-shadow: var(--shadow-md);
}

.history-date {

    color: var(--text-primary);
  }
  
  .empty-state {

    color: var(--text-primary);
  }

.action-button {
  background: var(--gradient-primary);
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 0.75rem;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 120px;
  text-align: center;
}

.action-button.secondary {
  background: var(--bg-secondary);
  color: var(--text-primary);
}

.action-button:hover {
  transform: translateY(-1px);
  box-shadow: var(--shadow-md);
}

.action-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
}

.settle-button {
    background: var(--success-color);
  color: white;
  padding: 0.75rem 1.25rem;
  border-radius: 0.75rem;
  border: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: var(--shadow-sm);
  width: fit-content;
  align-self: flex-end;
}

.settle-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.delete-button {
  background: var(--error-color);
  color: white;
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.delete-button:hover {
  transform: scale(1.05);
}

.profile-picture {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 9999px;
  object-fit: cover;
  border: 2px solid var(--border-color);
  transition: all 0.15s ease;
  align-items: center;
}

/* Status Indicators */
.settled-badge {
  background: var(--success-color);
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.75rem;
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
}

.char-count {
  font-size: 0.75rem;
  color: var(--text-secondary);
  text-align: right;
}

/* Loading States */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  backdrop-filter: blur(4px);
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid var(--bg-primary);
  border-top: 3px solid var(--accent-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Bottom Navigation */
.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--bg-primary);
  padding: 0.75rem;
  display: none;
  justify-content: space-around;
  border-top: 1px solid var(--border-color);
  z-index: 50;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  color: var(--text-secondary);
  text-decoration: none;
  font-size: 0.75rem;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0.5rem;
}

.nav-item.active {
  color: var(--accent-color);
}

/* Responsive Styles */
@media (max-width: 1400px) {
  .content {
    margin-left: 200px;
    margin-right: 200px;
  }
}

@media (max-width: 768px) {
  .content {
    margin: 0;
    padding: 1rem;
    padding-bottom: 5rem;
  }

  .summary-section {
    flex-direction: column;
  }

  .summary-cards {
    flex-direction: column;
  }

  .generate-report-button {
    width: 100%;
    align-self: center;
  }

  .user-select {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .amount-input {
    width: 100%;
  }

  .modal-footer {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .action-button {
    flex: 1;
    min-width: auto;
  }

  .bottom-nav {
    display: flex;
  }
}

/* Utility Classes */
.self-expense {
  font-style: italic;
  color: var(--text-secondary);
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

.error-message {
  color: var(--error-color);
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.success-message {
  color: var(--success-color);
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

/* Focus States */
.form-input:focus-visible,
.amount-input:focus-visible,
.checkbox-input:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px var(--accent-color);
}

.action-button:focus-visible,
.generate-report-button:focus-visible,
.close-button:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px var(--accent-color);
}

/* Scrollbar Styles */
.modal-content::-webkit-scrollbar,
.users-grid::-webkit-scrollbar {
  width: 8px;
}

.modal-content::-webkit-scrollbar-track,
.users-grid::-webkit-scrollbar-track {
  background: var(--bg-secondary);
  border-radius: 4px;
}

.modal-content::-webkit-scrollbar-thumb,
.users-grid::-webkit-scrollbar-thumb {
  background: var(--border-color);
  border-radius: 4px;
}

.modal-content::-webkit-scrollbar-thumb:hover,
.users-grid::-webkit-scrollbar-thumb:hover {
  background: var(--text-secondary);
}
