.modal-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: var(--bg-primary);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    margin: 20px;
  }
  
  .modal-header {
    padding: 20px;
    border-bottom: 1px solid var(--border-color);
  }
  
  .modal-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text-primary);
    margin: 0;
  }
  
  .modal-body {
    padding: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--text-primary);
    margin-bottom: 8px;
  }
  
  .form-input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    background-color: var(--bg-primary);
    color: var(--text-primary);
    font-size: 0.875rem;
    transition: border-color 0.2s;
  }
  
  .form-input:focus {
    outline: none;
    border-color: #E2384D;
  }
  
  .form-textarea {
    min-height: 100px;
    resize: vertical;
  }
  
  .time-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
  
  .location-section {
    background-color: var(--bg-secondary);
    border-radius: 6px;
    padding: 16px;
    margin-bottom: 20px;
  }
  
  .location-section h3 {
    margin: 0 0 16px 0;
    font-size: 1rem;
    color: var(--text-primary);
  }
  
  .location-button {
    width: 100%;
    padding: 8px 12px;
    background-color: var(--bg-primary);
    border: 1px solid var(--border-color);
    border-radius: 6px;
    color: var(--text-primary);
    text-align: left;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .location-button:hover {
    background-color: var(--bg-hover);
  }
  
  .switch-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  
  .visibility-select {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    background-color: var(--bg-primary);
    color: var(--text-primary);
    font-size: 0.875rem;
  }
  
  .modal-footer {
    padding: 16px 20px;
    border-top: 1px solid var(--border-color);
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
  
  .button {
    padding: 8px 16px;
    border-radius: 6px;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .button-secondary {
    background-color: transparent;
    border: 1px solid var(--border-color);
    color: var(--text-primary);
  }
  
  .button-secondary:hover {
    background-color: var(--bg-hover);
  }
  
  .button-primary {
    background-color: #E2384D;
    border: none;
    color: white;
  }
  
  .button-primary:hover {
    background-color: #c62236;
  }
  
  /* Dark mode support */
  @media (prefers-color-scheme: dark) {
    .modal-content {
      background-color: var(--bg-primary);
    }
  
    .form-input,
    .visibility-select {
      background-color: var(--bg-primary);
      color: var(--text-primary);
      border-color: var(--border-color);
    }
  
    .location-section {
      background-color: var(--bg-secondary);
    }
  
    .location-button {
      background-color: var(--bg-primary);
      border-color: var(--border-color);
      color: var(--text-primary);
    }
  
    .location-button:hover {
      background-color: var(--bg-hover);
    }
  }