.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(4px);
  }
  
  .modalContent {
    background-color: var(--bg-primary);
    border-radius: 12px;
    width: 90%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modalHeader h2 {
    font-size: 1.5rem;
    color: var(--text-primary);
    margin: 0;
  }
  
  .closeButton {
    background: none;
    border: none;
    font-size: 1.5rem;
    color: var(--text-secondary);
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 50%;
    transition: all 0.2s ease;
  }
  
  .closeButton:hover {
    background-color: var(--bg-secondary);
    color: var(--text-primary);
  }
  
  .tabs {
    display: flex;
    gap: 1rem;
    border-bottom: 1px solid var(--border-color);
    margin: -0.5rem 0 0.5rem;
  }
  
  .tab {
    padding: 0.75rem 1.5rem;
    background: none;
    border: none;
    color: var(--text-secondary);
    font-weight: 500;
    cursor: pointer;
    position: relative;
  }
  
  .tab.active {
    color: #E2384D;
  }
  
  .tab.active::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #E2384D;
  }
  
  .searchBar {
    display: flex;
    gap: 1rem;
  }
  
  .searchBar input {
    flex: 1;
    padding: 0.75rem;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    font-size: 1rem;
  }
  
  .searchButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    background-color: #E2384D;
    color: white;
    border: none;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .searchButton:hover:not(:disabled) {
    background-color: #cc2d43;
  }
  
  .searchButton:disabled {
    background-color: var(--text-secondary);
    cursor: not-allowed;
  }
  
  .searchResults {
    flex: 1;
    overflow-y: auto;
    min-height: 400px;
  }
  
  .resultsList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .resultCard {
    display: flex;
    gap: 1rem;
    padding: 1rem;
    background-color: var(--bg-secondary);
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .resultCard:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .resultPhoto {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .resultInfo {
    flex: 1;
  }
  
  .nameContainer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.25rem;
  }
  
  .nameContainer h3 {
    font-size: 1.1rem;
    color: var(--text-primary);
    margin: 0;
  }
  
  .verifiedBadge {
    width: 16px;
    height: 16px;
  }
  
  .username {
    font-size: 0.9rem;
    color: var(--text-secondary);
    margin: 0 0 0.25rem;
  }
  
  .subtype,
  .genre {
    font-size: 0.9rem;
    color: var(--text-primary);
    margin: 0 0 0.25rem;
  }
  
  .location {
    font-size: 0.875rem;
    color: var(--text-secondary);
    margin: 0;
  }
  
  .loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    color: var(--text-secondary);
  }
  
  .spinner {
    width: 40px;
    height: 40px;
    border: 3px solid var(--border-color);
    border-top-color: #E2384D;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 1rem;
  }
  
  .noResults,
  .searchPrompt {
    text-align: center;
    padding: 3rem;
    color: var(--text-secondary);
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Dark Mode Support */
  :root[class="dark"] .modalContent {
    background-color: var(--bg-secondary);
  }
  
  :root[class="dark"] .searchBar input {
    background-color: var(--bg-primary);
  }
  
  :root[class="dark"] .resultCard {
    background-color: var(--bg-primary);
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .modalContent {
      width: 95%;
      height: 100vh;
      max-height: none;
      border-radius: 0;
      padding: 1rem;
    }
  
    .searchBar {
      flex-direction: column;
    }
  
    .searchButton {
      width: 100%;
    }
  
    .tabs {
      justify-content: space-around;
      gap: 0;
    }
  
    .tab {
      padding: 0.75rem;
      flex: 1;
      text-align: center;
    }
  }