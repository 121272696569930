/* Event.css */
.event-venue-card {
  background-color: var(--bg-primary);
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid var(--border-color);
}

.event-venue-header {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.event-venue-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid var(--border-color);
}

.event-venue-info {
  flex: 1;
}

.event-venue-name {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
}

.event-venue-address {
  font-size: 0.875rem;
  color: var(--text-secondary);
  line-height: 1.4;
}

.event-actions {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.event-action-button {
  flex: 1;
  min-width: 120px;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: all 0.2s ease;
}

.event-action-button:hover {
  background-color: var(--bg-hover);
  border-color: var(--border-hover);
}

.event-action-button svg {
  color: #E2384D;
}

/* Organizer section */
.event-organizer-section {
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.event-organizer-info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.event-organizer-icon {
  color: #E2384D;
}

.event-organizer-name {
  color: var(--text-primary);
}

/* Navigation buttons */
.event-nav-button {
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--text-primary);
  transition: all 0.2s ease;
}

.event-nav-button:hover {
  background-color: var(--bg-hover);
}

.event-nav-button-content {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.event-nav-icon {
  color: #E2384D;
}

/* Vendor styles */
.event-vendor-section {
  margin-bottom: 1.5rem;
}

.event-vendor-header {
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.event-vendor-header-content {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.event-vendor-count {
  color: var(--text-secondary);
  font-size: 0.875rem;
}

.event-vendor-list {
  margin-top: 1rem;
}

.event-vendor-item {
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.event-vendor-item:hover {
  background-color: var(--bg-hover);
}

.event-vendor-image {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  object-fit: cover;
}

.event-vendor-info {
  flex: 1;
}

.event-vendor-name {
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 0.25rem;
}

.event-vendor-subtype {
  color: var(--text-secondary);
  font-size: 0.875rem;
}

/* Ride Modal Styles */
.ride-modal-overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  z-index: 1000;
}

.ride-modal-content {
  background-color: var(--bg-primary);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 1.5rem;
  width: 100%;
  max-width: 500px;
}

.ride-modal-header {
  text-align: center;
  margin-bottom: 1.5rem;
}

.ride-modal-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-primary);
}

.ride-option-button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  background-color: var(--bg-secondary);
  cursor: pointer;
  transition: all 0.2s ease;
}

.ride-option-button:hover {
  background-color: var(--bg-hover);
}

.ride-option-logo {
  width: 40px;
  height: 40px;
  margin-right: 1.25rem;
  object-fit: contain;
}

.ride-option-text {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-primary);
}

.loading-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1100;
}

.loading-text {
  color: white;
  margin-top: 1rem;
  font-size: 1rem;
}

/* Modal styles */
.event-modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.event-modal-content {
  background-color: var(--bg-primary);
  border-radius: 12px;
  padding: 1.5rem;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.event-modal-header {
  margin-bottom: 1.5rem;
}

.event-modal-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-primary);
}

.event-modal-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  margin-bottom: 1rem;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
}

.event-modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}