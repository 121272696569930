/* PublicProfile.module.css */
/* Base container styles */
.profile-container {
    width: 100%;
    min-height: 100vh;
    background-color: var(--page-bg);
    position: relative;
    z-index: 1;
  }
  
  .main-layout {
    display: flex;
    min-height: calc(100vh - 3.5rem);
    margin-top: 3.5rem;
    width: 100%;
  }
  
  /* Main content area - note the width adjustment to accommodate for hidden menus */
  .profile-main {
    flex: 1;
    padding: 2rem;
    background-color: var(--page-bg);
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 100%;
  }
  
  /* Profile content container */
  .profile-content {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
  }
  
  /* Cover photo section */
  .cover-photo-container {
    width: 100%;
    height: 250px;
    position: relative;
    background-color: var(--bg-secondary);
    border-radius: 1rem 1rem 1rem 1rem;
    overflow: hidden;
    margin-bottom: 4rem;
  }
  
  .cover-photo {
    min-width: 100%;
  height: 100%;
  object-fit: cover;
  }
  
  .cover-overlay {
    position: absolute;
    inset: 0;
    background: linear-gradient(
      to bottom,
      transparent 0%,
      rgba(0, 0, 0, 0.4) 100%
    );
  }
  
  /* Profile card */
  .profile-card {
    background: var(--bg-primary);
    border-radius: 1rem;
    overflow: hidden;
    margin-bottom: 2rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1;
    margin-top: 0rem;
  }
  
  /* Profile header section */
  .profile-header {
    display: flex;
    gap: 2rem;
    padding: 0 2rem 2rem;
    position: relative;
    margin-top: -8rem;
  }
  
  /* Left profile section */
  .profile-left {
    flex: 0 0 auto;
    width: 250px;
    position: relative;
    margin-top: -3rem;
  }
  
  .profile-info {
    margin-top: 1rem;
    width: 100%;
  }
  
  .profile-photo {
    width: 10rem;
    height: 10rem;
    border-radius: 9999px;
    border: 4px solid var(--bg-primary);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    object-fit: cover;
  }
  
  /* Right section */
  .profile-right {
    flex: 1;
    min-width: 0;
    padding-top: 4rem;
  }
  
  /* Profile info and details */
  .name-section {
    display: flex;
    align-items: center; /* Ensures vertical alignment */
    gap: 0.4rem; /* Reduced from 0.8rem to bring the badge closer to the name */
    margin-bottom: 0.5rem;
    margin-top: 0;
  }
  
  .name-section h1 {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--text-primary);
    margin: 0;
  }
  
  .verified-badge {
    width: 1.25rem;
    height: 1.25rem;
    display: flex; /* Added to help with alignment */
    align-self: center; /* Ensures vertical centering */
  }
  
  .user-type {
    font-size: 1rem;
    color: var(--text-secondary);
    margin-bottom: 1rem;
  }
  
  .info-items {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .info-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--text-secondary);
  }
  
  .info-item.on-tour {
    color: #E2384D;
  }
  
  .info-item.available {
    color: #10b981;
  }
  
  .bio {
    font-size: 1rem;
    color: var(--text-secondary);
    line-height: 1.5;
    margin-top: 0.5rem;
  }
  
  /* Social Icons */
  .bioSection {
    margin: 1rem 0;
  }
  
  .bio {
    color: var(--text-secondary);
    line-height: 1.5;
    margin: 0;
  }
  
  .socialIcons {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .socialIcon {
    width: 26px;  
    height: 26px;
    color: #E2384D;
  }
  
  .customsocialIcon {
    width: 26px;  
    height: 26px;
    color: #E2384D;
    margin-top: -1.5rem;
  }
  
  /* Profile actions */
  .profile-actions {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
  }
  
  .action-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: all 0.2s;
    text-decoration: none;
  }
  
  .action-button.contact {
    background-color: #E2384D;
    color: white;
  }
  
  .action-button.share {
    background-color: var(--bg-secondary);
    color: var(--text-secondary);
  }
  
  /* Stats section */
  .stats-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    border-radius: 0.75rem;
    padding: 1rem;
    margin-top: 1rem;
  }
  
  .stat-card {
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.2s ease;
    padding-bottom: 0.25rem;
    border-radius: 0.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .stat-card:hover {
    transform: translateY(-2px);
    background-color: var(--border-color);
  }
  
  .stat-value {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text-primary);
    text-align: center;
  }
  
  .stat-label {
    color: var(--text-secondary);
    font-size: 0.875rem;
    text-align: center;
  }
  
  .share-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: #E2384D;
    cursor: pointer;
    height: 100%;
    padding: 0.5rem 0;
  }
  
  .share-icon {
    font-size: 1.25rem;
  }
  
  /* Content section */
  .content-section {
    background: var(--bg-primary);
    border-radius: 1rem;
    padding: 1.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .content-tabs {
    display: flex;
    gap: 1rem;
    border-bottom: 1px solid var(--border-color);
    margin-bottom: 1.5rem;
  }
  
  .tab {
    padding: 0.75rem 1.5rem;
    font-weight: 500;
    color: var(--text-secondary);
    background: none;
    border: none;
    cursor: pointer;
    position: relative;
  }
  
  .tab.active {
    color: #E2384D;
  }
  
  .tab.active::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #E2384D;
  }
  
  /* Login prompt styles */
  .login-prompt-container {
    width: 100%;
    padding: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .login-prompt {
    background: var(--bg-secondary);
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease-out;
  }
  
  .lock-icon {
    font-size: 2.5rem;
    color: var(--text-secondary);
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
  }
  
  .login-prompt h3 {
    font-size: 1.25rem;
    color: var(--text-primary);
    margin-bottom: 0.5rem;
  }
  
  .login-prompt p {
    color: var(--text-secondary);
    margin-bottom: 1.5rem;
  }
  
  .login-actions {
    display: flex;
    gap: 1rem;
    justify-content: center;
  }
  
  .login-actions button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    border: none;
  }
  
  .login-actions .login-button {
    background: var(--bg-primary);
    color: var(--text-primary);
    border: 1px solid var(--border-color);
  }
  
  .login-actions .signup-button {
    background: #E2384D;
    color: white;
  }
  
  /* Modal styles */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.2s ease-out;
  }
  
  .modal-content {
    background-color: var(--bg-primary);
    color: var(--text-primary);
    border-radius: 0.75rem;
    padding: 1.5rem;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    position: relative;
    animation: slideIn 0.3s ease-out;
  }

 
  
  .modal-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 1.25rem;
    color: var(--text-secondary);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    transition: background-color 0.2s ease;
  }
  
  .modal-close:hover {
    background-color: var(--bg-secondary);
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
    margin-top: 1.5rem;
  }
  
  .secondary-button, 
  .primary-button {
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    font-weight: 500;
    cursor: pointer;
    border: none;
  }
  
  .secondary-button {
    background-color: var(--bg-secondary);
    color: var(--text-secondary);
  }
  
  .primary-button {
    background-color: #E2384D;
    color: white;
  }

  /* Private account message styling */
.private-account {
    width: 100%;
    padding: 2rem 0;
    justify-content: center;
    align-items: center;
    background: var(--bg-secondary);
    border-radius: 1rem;
    text-align: center;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease-out;
  }
  
  .private-account .lock-icon {
    font-size: 2.5rem;
    color: var(--text-secondary);
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
  }
  
  .private-account h3 {
    font-size: 1.25rem;
    color: var(--text-primary);
    margin-bottom: 0.5rem;
  }
  
  .private-account p {
    color: var(--text-secondary);
    margin-bottom: 0.5rem;
  }
  
  /* Add message button style */
  .action-button.message {
    background-color: var(--bg-secondary);
    color: var(--text-secondary);
  }
  
  /* Updated modal content styles to ensure theme compatibility */
  .modal-content h2 {
    color: var(--text-primary);
  }
  
  .modal-content p {
    color: var(--text-secondary);
  }
  
  /* Not found container */
  .not-found-container {
    flex: 1;
    min-width: 0;
    padding: 2rem;
    background-color: var(--page-bg);
    position: relative;
    z-index: 1;
    min-height: calc(100vh - 3.5rem);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .not-found-content {
    text-align: center;
    max-width: 400px;
    animation: fadeIn 0.5s ease-out;
    background: var(--bg-primary);
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .not-found-artwork {
    position: relative;
    width: 200px;
    height: 200px;
    margin: 0 auto 2rem;
  }
  
  .not-found-icon {
    font-size: 80px;
    color: #E2384D;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
  
  .not-found-circle {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    background: linear-gradient(45deg, rgba(226, 56, 77, 0.1), rgba(226, 56, 77, 0.2));
    animation: pulse 2s infinite;
  }
  
  .not-found-content h1 {
    color: var(--text-primary);
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .not-found-content p {
    color: var(--text-secondary);
    margin-bottom: 2rem;
  }
  
  .return-button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    background: #E2384D;
    color: white;
    border: none;
    border-radius: 0.5rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    text-decoration: none;
  }
  
  .return-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(226, 56, 77, 0.2);
  }
  
  @keyframes pulse {
    0% {
      transform: scale(0.95);
      opacity: 0.5;
    }
    50% {
      transform: scale(1.05);
      opacity: 0.8;
    }
    100% {
      transform: scale(0.95);
      opacity: 0.5;
    }
  }
  
  @media (max-width: 768px) {
    .not-found-container {
      margin: 0;
      padding: 1rem;
      padding-bottom: 5rem;
    }
    
    .not-found-artwork {
      width: 150px;
      height: 150px;
    }
    
    .not-found-content {
      width: 90%;
      padding: 1.5rem;
    }
  
    .not-found-icon {
      font-size: 60px;
    }
    
    .not-found-content h1 {
      font-size: 1.5rem;
    }
  }
  
  .return-button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    background: #E2384D;
    color: white;
    border: none;
    border-radius: 0.5rem;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    margin-top: 1.5rem;
  }
  
  
  
  /* Posts grid */
  .posts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  .no-results {
    text-align: center;
    padding: 2rem;
    color: var(--text-secondary);
  }
  
  .loading-spinner-container {
    display: flex;
    justify-content: center;
    padding: 2rem;
  }
  
  .loading-spinner {
    border: 3px solid var(--border-color);
    border-top: 3px solid #E2384D;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .load-more {
    display: block;
    width: 100%;
    padding: 0.75rem;
    background: var(--bg-secondary);
    border: none;
    border-radius: 0.375rem;
    color: var(--text-primary);
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .load-more:hover {
    background-color: var(--border-color);
  }
  
  /* Animations */
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes slideIn {
    from { 
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsive styles */
  @media (max-width: 1024px) {
    .profile-header {
      flex-direction: column;
      gap: 1rem;
    }
    
    .profile-left {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    .profile-right {
      width: 100%;
      padding-top: 0;
    }
    
    .profile-info {
      text-align: center;
    }
    
    .name-section {
      justify-content: center;
    }
    
    .info-items {
      justify-content: center;
    }
    
    .profile-actions {
      justify-content: center;
    }
    
    .socialIcons {
      justify-content: center;
    }
  }
  
  @media (max-width: 768px) {
    .profile-main {
      padding: 1rem;
    }
    
    .profile-header {
      padding: 0 1rem 1rem;
    }
    
    .posts-grid {
      grid-template-columns: 1fr;
    }
    
    .content-section {
      padding: 1rem;
    }
    
    .content-tabs {
      gap: 0.5rem;
    }
    
    .tab {
      padding: 0.75rem 1rem;
    }
  }