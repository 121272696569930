/* Theme variables */
:root {
    --bg-primary: #ffffff;
    --bg-secondary: #f3f4f6;
    --text-primary: #111827;
    --text-secondary: #6b7280;
    --border-color: #e5e7eb;
    --bg-hover: #f9fafb;
    --border-hover: #d1d5db;
    --accent-color: #E2384D;
    --accent-hover: #d42e41;
    --modal-overlay: rgba(0, 0, 0, 0.5);
    --modal-bg: #ffffff;
    --disabled-bg: #9ca3af;
    --disabled-text: #6b7280;
    --placeholder-bg: #e5e7eb;
  }
  
  /* Dark theme */
  :root[class="dark"] {
    --bg-primary: #1f2937;
    --bg-secondary: #111827;
    --text-primary: #ffffff;
    --text-secondary: #9ca3af;
    --border-color: #374151;
    --bg-hover: #374151;
    --border-hover: #4b5563;
    --accent-color: #E2384D;
    --accent-hover: #d42e41;
    --modal-overlay: rgba(0, 0, 0, 0.7);
    --modal-bg: #1f2937;
    --disabled-bg: #4b5563;
    --disabled-text: #9ca3af;
    --placeholder-bg: #374151;
  }
  
  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--modal-overlay);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalContent {
    background: var(--modal-bg);
    border-radius: 12px;
    padding: 24px;
    width: 90%;
    max-width: 800px;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  .modalTitle {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px;
    text-align: center;
    color: var(--text-primary);
  }
  
  .searchContainer {
    margin-bottom: 24px;
  }
  
  .searchInputWrapper {
    display: flex;
    gap: 12px;
    margin-bottom: 16px;
  }
  
  .searchInput {
    flex: 1;
    padding: 12px 16px;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    font-size: 16px;
    background-color: var(--bg-primary);
    color: var(--text-primary);
    transition: border-color 0.2s ease;
  }
  
  .searchInput:focus {
    outline: none;
    border-color: var(--accent-color);
    box-shadow: 0 0 0 2px rgba(226, 56, 77, 0.1);
  }
  
  .searchInput::placeholder {
    color: var(--text-secondary);
  }
  
  .searchButton {
    padding: 12px 24px;
    background-color: var(--accent-color);
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: bold;
    min-width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s ease;
  }
  
  .searchButton:hover:not(:disabled) {
    background-color: var(--accent-hover);
  }
  
  .disabledButton {
    background-color: var(--disabled-bg);
    color: var(--disabled-text);
    cursor: not-allowed;
  }
  
  .spinner {
    width: 20px;
    height: 20px;
    border: 2px solid #ffffff;
    border-top: 2px solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .suggestionsContainer {
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    margin-top: 8px;
    background-color: var(--bg-primary);
  }
  
  .suggestionItem {
    padding: 12px 16px;
    cursor: pointer;
    border-bottom: 1px solid var(--border-color);
    color: var(--text-primary);
    transition: background-color 0.2s ease;
  }
  
  .suggestionItem:last-child {
    border-bottom: none;
  }
  
  .suggestionItem:hover {
    background-color: var(--bg-hover);
  }
  
  .selectedPlace {
    margin-top: 24px;
    padding: 20px;
    background-color: var(--bg-secondary);
    border-radius: 8px;
    border: 1px solid var(--border-color);
  }
  
  .selectedLocationText {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
    color: var(--text-primary);
  }
  
  .venueDetailsContainer {
    display: flex;
    gap: 16px;
    margin-bottom: 20px;
    padding: 16px;
    background-color: var(--bg-primary);
    border-radius: 8px;
  }
  
  .venueImageContainer {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .venueImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .venueImagePlaceholder {
    width: 100%;
    height: 100%;
    background-color: var(--placeholder-bg);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-secondary);
  }
  
  .venueImagePlaceholder::after {
    content: '📍';
    font-size: 24px;
  }
  
  .venueTextContainer {
    flex: 1;
  }
  
  .venueDisplayName {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
    color: var(--text-primary);
  }
  
  .venueAddress {
    font-size: 14px;
    color: var(--text-secondary);
    line-height: 1.4;
  }
  
  .mapContainer {
    height: 300px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid var(--border-color);
    margin-top: 16px;
  }
  
  .modalActions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;
    padding-top: 16px;
    border-top: 1px solid var(--border-color);
  }
  
  .selectButton {
    padding: 12px 24px;
    background-color: var(--accent-color);
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: bold;
    min-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s ease;
  }
  
  .selectButton:hover:not(:disabled) {
    background-color: var(--accent-hover);
  }
  
  .selectButton:disabled {
    background-color: var(--disabled-bg);
    cursor: not-allowed;
  }
  
  .closeButton {
    padding: 12px 24px;
    background-color: transparent;
    color: var(--text-primary);
    border: 1px solid var(--border-color);
    border-radius: 6px;
    cursor: pointer;
    font-weight: bold;
    transition: all 0.2s ease;
  }
  
  .closeButton:hover {
    background-color: var(--bg-hover);
    border-color: var(--border-hover);
  }
  
  /* Scrollbar styling */
  .modalContent::-webkit-scrollbar,
  .suggestionsContainer::-webkit-scrollbar {
    width: 8px;
  }
  
  .modalContent::-webkit-scrollbar-track,
  .suggestionsContainer::-webkit-scrollbar-track {
    background: var(--bg-secondary);
    border-radius: 4px;
  }
  
  .modalContent::-webkit-scrollbar-thumb,
  .suggestionsContainer::-webkit-scrollbar-thumb {
    background: var(--border-color);
    border-radius: 4px;
  }
  
  .modalContent::-webkit-scrollbar-thumb:hover,
  .suggestionsContainer::-webkit-scrollbar-thumb:hover {
    background: var(--border-hover);
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    .modalContent {
      width: 95%;
      padding: 16px;
    }
  
    .venueDetailsContainer {
      flex-direction: column;
    }
  
    .venueImageContainer {
      width: 100%;
      height: 200px;
    }
  
    .mapContainer {
      height: 250px;
    }
  
    .modalActions {
      flex-direction: column-reverse;
    }
  
    .selectButton,
    .closeButton {
      width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .modalTitle {
      font-size: 20px;
    }
  
    .searchInputWrapper {
      flex-direction: column;
    }
  
    .searchButton {
      width: 100%;
    }
  
    .mapContainer {
      height: 200px;
    }
  }