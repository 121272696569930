/* LeftMenu.css */
.left-menu {
  width: 210px;
  height: calc(100vh - 3.5rem);
  background-color: var(--bg-primary);
  padding: 1rem;
  position: fixed;
  left: 0;
  top: 3.5rem;
  overflow-y: auto;
  z-index: 40;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE/Edge */
}

.left-menu::-webkit-scrollbar {
  display: none;
}
  
  .left-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .subuser-type {
    font-size: 1.5rem;
    color: var(--text-primary);
    font-weight: 500;
    padding: 0.5rem 1rem;
    padding-top: 1.1rem;
   
}

  .menu-item {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem 1rem;
    border-radius: 0.375rem;
    cursor: pointer;
    color: var(--text-secondary);
    transition: all 0.2s ease;
    user-select: none;
    position: relative;
  }
  
  .menu-item:hover {
    background: var(--bg-secondary);
    color: var(--text-primary);
  }
  
  .menu-item.selected {
    background: var(--bg-secondary);
    color: var(--text-primary);
    font-weight: 500;
  }
  
  .menu-item-icon {
    width: 1.25rem;
    height: 1.25rem;
    flex-shrink: 0;
  }
  


  .date-cancelled {
    color: #E2384D;
    font-size: 0.75rem;
    font-weight: 500;
    margin-top: 0.25rem;
  }
  
  .dates-dropdown {
    margin-left: 0rem;
    padding-top: 0.5rem;
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE/Edge */
  }
  
  /* Hide scrollbar for Chrome/Safari/Opera */
  .dates-dropdown::-webkit-scrollbar {
    display: none;
  }
  
  .date-item {
    padding: 0.75rem;
    border-radius: 0.375rem;
    background: var(--bg-secondary);
    border: 1px solid var(--border-color);
    margin-bottom: 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .date-item:hover {
    border-color: var(--text-secondary);
  }
  
  .date-venue {
    font-weight: 500;
    color: var(--text-primary);
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
  }
  
  .date-info {
    font-size: 0.75rem;
    color: var(--text-secondary);
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .date-location {
    color: var(--text-secondary);
    font-style: italic;
  }
  
  .no-dates {
    color: var(--text-secondary);
    font-style: italic;
    text-align: center;
    padding: 1rem;
  }
  
  .dates-dropdown {
    margin-left: 2.5rem;
    padding-top: 0.5rem;
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow-y: auto;
  }
  
  .ml-auto {
    margin-left: auto;
  }
  
  /* Chevron icon styles */
  .menu-item .chevron-icon {
    transition: transform 0.2s ease;
  }
  
  .menu-item.expanded .chevron-icon {
    transform: rotate(180deg);
  }
  
  @media (max-width: 768px) {
    .left-menu {
      display: none;
    }
  }