:root {
  --bg-primary: #ffffff;
  --bg-secondary: #f3f4f6;
  --text-primary: #111827;
  --text-secondary: #6b7280;
  --border-color: #e5e7eb;
  --page-bg: #f5f7fb;
}

:root[class="dark"] {
  --bg-primary: #1f2937;
  --bg-secondary: #111827;
  --text-primary: #ffffff;
  --text-secondary: #9ca3af;
  --border-color: #374151;
  --page-bg: #111827;
}

.main-layout {
  display: flex;
  min-height: 100vh;
  padding-top: 3.5rem;
  background-color: var(--page-bg);
}

.search-container {
  flex: 1;
  margin-left: 240px;
  margin-right: 270px;
  padding: 2rem;
  min-width: 0;
  height: calc(100vh - 3.5rem);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.search-header {
  background: var(--bg-primary);
  border-radius: 0.75rem;
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
}



.filter-icon {
  color: var(--text-secondary);
  cursor: pointer;
  font-size: 1.25rem;
  padding: 0.5rem;
}

.filter-icon.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.filter-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.filter-tag {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.75rem;
  background: #E2384D;
  color: white;
  border-radius: 1rem;
  font-size: 0.875rem;
}

.filter-tag button {
  background: none;
  border: none;
  color: white;
  font-size: 1.25rem;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}



.search-bar input:focus {
  outline: none;
  border-color: #E2384D;
}

.clear-icon {
  position: absolute;
  right: 7rem;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 0.5rem;
}

.search-bar {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
  position: relative;
  width: 100%;
}




.search-bar input {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  font-size: 1rem;
  background: var(--bg-secondary);
  color: var(--text-primary);
  min-width: 0; /* Add this */
}

.search-button {
  width: auto;
  min-width: fit-content;
  background: #E2384D;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
  white-space: nowrap;
}

.search-button.disabled {
  background: #ccc;
  cursor: not-allowed;
}

.tabs {
  display: flex;
  gap: 1rem;
  border-bottom: 1px solid var(--border-color);
}

.tab {
  padding: 0.75rem 1.5rem;
  border: none;
  background: none;
  color: var(--text-secondary);
  cursor: pointer;
  position: relative;
  font-weight: 500;
}

.tab.active {
  color: #E2384D;
}

.tab.active::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #E2384D;
}

.search-results {
  margin-top: 2rem;
  flex: 1;
  overflow-y: auto;
  background: var(--bg-primary);
  border-radius: 0.75rem;
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.results-grid {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  align-items: stretch;
}

.user-card {
  width: 100%;
  background: var(--bg-secondary);
  border-radius: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.user-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}



.user-card-content {
  display: flex;
  padding: 1.5rem;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.profile-photo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
  flex-shrink: 0;
}

.user-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: left;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
}

.name-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  justify-content: flex-start;
}

.name-container h3 {
  margin: 0;
  font-size: 1.1rem;
  color: var(--text-primary);
  font-weight: 600;
  text-align: left;
}

.username, .subtype, .location {
  margin: 0;
  color: var(--text-secondary);
  text-align: left;
  width: 100%;
}



.verified-badge {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}



.username {
  font-size: 0.9rem;
}

.subtype {
  font-size: 0.9rem;
}

.location {
  font-size: 0.875rem;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: var(--bg-primary);
  border-radius: 0.75rem;
  padding: 1.5rem;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-content h2 {
  margin: 0 0 1.5rem 0;
  color: var(--text-primary);
}

.filter-section {
  margin-bottom: 1.5rem;
}

.filter-section label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--text-primary);
  font-weight: 500;
}

.filter-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  background: var(--bg-secondary);
  color: var(--text-primary);
  margin-bottom: 0.5rem;
}

.location-inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.selected-subtypes {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.subtype-tag {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.75rem;
  background: #E2384D;
  color: white;
  border-radius: 1rem;
  font-size: 0.875rem;
}

.subtype-tag button {
  background: none;
  border: none;
  color: white;
  padding: 0;
  cursor: pointer;
}

.suggestions-list {
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  max-height: 200px;
  overflow-y: auto;
}

.suggestion-item {
  padding: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.suggestion-item:hover {
  background: var(--bg-secondary);
}

.filter-actions {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

.cancel-button, .apply-button {
  flex: 1;
  padding: 0.75rem;
  border-radius: 0.5rem;
  border: none;
  font-weight: 500;
  cursor: pointer;
}

.cancel-button {
  background: var(--bg-secondary);
  color: var(--text-secondary);
}

.apply-button {
  background: #E2384D;
  color: white;
}

/* Status Messages */
.upgrade-prompt, .loading, .no-results, .search-prompt {
  text-align: center;
  padding: 3rem;
  color: var(--text-secondary);
}

.upgrade-button {
  background: #E2384D;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: 500;
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .search-container {
    margin: 0;
    padding: 1rem;
    padding-bottom: 5rem;
  }

  .search-header {
    padding: 1rem;
  }

  .search-bar {
    flex-direction: column;
  }

  .clear-icon {
    right: 1rem;
  }

  .search-button {
    width: 100%;
   
  }

  .tabs {
    overflow-x: auto;
    gap: 0;
  }

  .tab {
    padding: 0.75rem 1rem;
    white-space: nowrap;
  }

  .modal-content {
    width: 95%;
    margin: 1rem;
  }

  .location-inputs {
    grid-template-columns: 1fr;
  }
}