/* Layout Structure */
.layout {
  min-height: 100vh;
  width: 100%;
  background-color: var(--page-bg);
}

.mainLayout {
  display: flex;
  min-height: calc(100vh - 3.5rem);
  margin-top: 3.5rem;
  width: 100%;
  position: relative;
}

.content {
  flex: 1;
  padding: 24px;
  margin-left: 240px; /* Width of LeftMenu */
  margin-right: 280px; /* Width of RightMenu */
  width: calc(100% - 490px);
  background-color: var(--page-bg);
  min-width: 0;
  overflow-x: hidden;
}

/* Header Styles */
.header {
  background-color: var(--bg-primary);
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.headerInfo {
  text-align: center;
}

.headerInfo h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

.headerDate {
  position: relative;
  text-align: center;
}

.headerDate h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 0px;
}

.rescheduledInfo {
  color: #FFA500;
  font-style: italic;
  font-size: 0.9rem;
  margin-top: 4px;
  padding: 4px 8px;
  background-color: rgba(255, 165, 0, 0.1);
  border-radius: 4px;
  display: inline-block;
}

.cancelledInfo {
  color: #E2384D;
  font-style: italic;
  font-size: 0.9rem;
  margin-top: 4px;
  padding: 4px 8px;
  background-color: rgba(226, 56, 77, 0.1);
  border-radius: 4px;
  display: inline-block;
}

.headerAddress {
  text-align: center;
  color: var(--text-secondary);
  margin: 8px 0;
}

.headerAddress p {
  margin: 0;
  font-size: 1rem;
}

.date {
  color: var(--text-secondary);
  margin: 8px 0 0;
}

.backButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: none;
  border: none;
  color: var(--text-primary);
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 16px;
  max-width: 200px;
}

.backButton:hover {
background-color: var(--border-color);
}

/* Upload Section */
.uploadSection {
  background-color: var(--bg-primary);
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
}



/* Attachments List */
.attachmentsList {
  background-color: var(--bg-primary);
  border-radius: 12px;
  padding: 24px;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.attachmentCard {
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  transition: all 0.2s;
}

.attachmentCard:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.attachmentInfo {
  display: flex;
  gap: 12px;
  flex: 1;
  cursor: pointer;
}

.fileIcon {
  font-size: 24px;
  color: #E2384D;
}

.attachmentDetails {
  flex: 1;
}

.attachmentDetails h3 {
  margin: 0 0 4px;
  font-size: 16px;
  color: var(--text-primary);
}

.attachmentDetails p {
  margin: 0 0 8px;
  font-size: 14px;
  color: var(--text-secondary);
}

.uploadedBy {
  font-size: 12px;
  color: var(--text-secondary);
}

.attachmentActions {
  display: flex;
  gap: 8px;
}

.actionButton {
  padding: 8px;
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s;
}

.actionButton:hover {
  background-color: var(--border-color);
  color: var(--text-primary);
}

/* Layout Structure */
.layout {
  min-height: 100vh;
  width: 100%;
  background-color: var(--page-bg);
}

.mainLayout {
  display: flex;
  min-height: calc(100vh - 3.5rem);
  margin-top: 3.5rem;
  width: 100%;
  position: relative;
}

.content {
  flex: 1;
  padding: 24px;
  margin-left: 240px; /* Width of LeftMenu */
  margin-right: 280px; /* Width of RightMenu */
  width: calc(100% - 490px);
  background-color: var(--page-bg);
  min-width: 0;
  overflow-x: hidden;
}

/* Header Styles */
.header {
  background-color: var(--bg-primary);
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.headerInfo {
  text-align: center;
}

.headerInfo h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

.date {
  color: var(--text-secondary);
  margin: 8px 0 0;
}

.backButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: none;
  border: none;
  color: var(--text-primary);
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 16px;
}

.backButton:hover {
  color: #E2384D;
}

/* Upload Section */
.uploadSection {
  background-color: var(--bg-primary);
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
}

.uploadButton {
  display: inline-flex;
  align-items: center;
  max-width: 200px;
  gap: 8px;
  padding: 12px 24px;
  background-color: #E2384D;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.uploadButton:hover {
  background-color: #cc2b3d;
}

.fileInput {
  display: none;
}

/* Attachments List */
.attachmentsList {
  background-color: var(--bg-primary);
  border-radius: 12px;
  padding: 24px;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.attachmentCard {
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  transition: all 0.2s;
}

.attachmentCard:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.attachmentInfo {
  display: flex;
  gap: 12px;
  flex: 1;
  cursor: pointer;
}

.fileIcon {
  font-size: 24px;
  color: #E2384D;
}

.attachmentDetails {
  flex: 1;
}

.attachmentDetails h3 {
  margin: 0 0 4px;
  font-size: 16px;
  color: var(--text-primary);
}

.attachmentDetails p {
  margin: 0 0 8px;
  font-size: 14px;
  color: var(--text-secondary);
}

.uploadedBy {
  font-size: 12px;
  color: var(--text-secondary);
}

.attachmentActions {
  display: flex;
  gap: 8px;
}

.actionButton {
  padding: 8px;
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s;
}

.actionButton:hover {
  background-color: var(--border-color);
  color: var(--text-primary);
}

.deleteButton:hover {
  background-color: rgba(226, 56, 77, 0.1);
  color: #E2384D;
}

/* Empty State */
.emptyState {
  text-align: center;
  padding: 48px;
  color: var(--text-secondary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.emptyState svg {
  opacity: 0.5;
  color: var(--text-secondary);
}

.emptyState p {
  margin: 0;
  font-size: 1.1rem;
}

/* Loading State */
.loadingOverlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 3px solid var(--border-color);
  border-top: 3px solid #E2384D;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loadingText {
  color: white;
  margin-top: 16px;
  font-size: 16px;
}

/* Dark Mode Support */
:root[class="dark"] .attachmentCard {
  background-color: var(--bg-secondary);
  border-color: var(--border-color);
}

:root[class="dark"] .attachmentDetails h3 {
  color: var(--text-primary);
}

:root[class="dark"] .attachmentDetails p,
:root[class="dark"] .uploadedBy {
  color: var(--text-secondary);
}

:root[class="dark"] .actionButton {
  color: var(--text-secondary);
}

:root[class="dark"] .actionButton:hover {
  background-color: var(--border-color);
  color: var(--text-primary);
}

:root[class="dark"] .uploadButton {
  background-color: #E2384D;
}

:root[class="dark"] .uploadButton:hover {
  background-color: #cc2b3d;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .content {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    padding: 16px;
  }

  .header {
    padding: 16px;
  }

  .uploadSection,
  .attachmentsList {
    padding: 16px;
  }

  .attachmentsList {
    grid-template-columns: 1fr;
  }

  .attachmentCard {
    flex-direction: column;
  }

  .attachmentActions {
    margin-top: 16px;
    justify-content: flex-end;
    width: 100%;
  }
}

/* Adjustments for larger screens */
@media (min-width: 1281px) {
  .content {
    margin-left: 240px;
    margin-right: 280px;
    width: calc(100% - 490px);
  }
}

/* File Type Indicators */
.fileIcon[data-type="pdf"] {
  color: #E2384D;
}

.fileIcon[data-type="image"] {
  color: #4CAF50;
}

.fileIcon[data-type="document"] {
  color: #2196F3;
}

/* Visibility Badge */
.visibilityBadge {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  margin-top: 4px;
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
}

/* Progress Bar for uploads */
.progressBar {
  width: 100%;
  height: 4px;
  background-color: var(--bg-secondary);
  border-radius: 2px;
  overflow: hidden;
  margin-top: 8px;
}

.progressFill {
  height: 100%;
  background-color: #E2384D;
  transition: width 0.3s ease;
}

/* Custom Scrollbar */
.attachmentsList {
  scrollbar-width: thin;
  scrollbar-color: var(--border-color) transparent;
}

.attachmentsList::-webkit-scrollbar {
  width: 6px;
}

.attachmentsList::-webkit-scrollbar-track {
  background: transparent;
}

.attachmentsList::-webkit-scrollbar-thumb {
  background-color: var(--border-color);
  border-radius: 3px;
}

/* Alert Modal Styles */
.alertModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
  backdrop-filter: blur(4px);
}

.alertModal {
  background-color: var(--bg-primary);
  border-radius: 12px;
  padding: 24px;
  width: 90%;
  max-width: 400px;
  border: 1px solid var(--border-color);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
}

.alertHeader {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.alertIcon {
  font-size: 24px;
}

.alertIcon.warning {
  color: #E2384D;
}

.alertIcon.error {
  color: #ef4444;
}

.alertTitle {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: var(--text-primary);
}

.alertMessage {
  color: var(--text-secondary);
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 1.5;
}

.alertActions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.alertButton {
  padding: 8px 16px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.alertButton.cancel {
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  color: var(--text-primary);
}

.alertButton.warning {
  background-color: #E2384D;
  border: none;
  color: white;
}

.alertButton.error {
  background-color: #ef4444;
  border: none;
  color: white;
}

.alertButton:hover {
  transform: translateY(-1px);
}

.alertButton.cancel:hover {
  background-color: var(--border-color);
}

.alertButton.warning:hover {
  background-color: #E2384D;
}

.alertButton.error:hover {
  background-color: #dc2626;
}

/* Dark mode support */
:root[class="dark"] .alertModal {
  background-color: var(--bg-secondary);
}

.attachmentMeta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}

.uploadedBy {
  font-size: 12px;
  color: var(--text-secondary);
}

.visibility {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 12px;
  color: var(--text-secondary);
  padding: 4px 8px;
  background-color: var(--bg-secondary);
  border-radius: 4px;
  max-width: 300px;
}

.visibilityText {
  margin-left: 4px;
  white-space: normal;
  line-height: 1.3;
}

customNames {
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
}

.showMoreButton,
.showLessButton {
  background: none;
  border: none;
  color: #E2384D;
  padding: 0;
  font-size: 12px;
  cursor: pointer;
  display: inline;
  text-decoration: underline;
  margin-left: 4px;
}

.showMoreButton:hover,
.showLessButton:hover {
  color: #cc2b3d;
}


/* Dark mode support */
:root[class="dark"] .visibility {
  background-color: var(--bg-secondary);
}

:root[class="dark"] .showMoreButton,
:root[class="dark"] .showLessButton {
  color: #ff4d6a;
}

:root[class="dark"] .showMoreButton:hover,
:root[class="dark"] .showLessButton:hover {
  color: #ff6b82;
}

.actionButton {
  padding: 8px;
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s;
}

.actionButton:hover {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
}

.actionButton.deleteButton:hover {
  background-color: rgba(226, 56, 77, 0.1);
  color: #E2384D;
}

.actionButton.upgradeButton {
  position: relative;
  color: #E2384D;
}

.actionButton.upgradeButton:hover {
  background-color: rgba(226, 56, 77, 0.1);
}

.crownIcon {
  position: absolute;
  top: -2px;
  right: -2px;
  font-size: 10px;
  color: #E2384D;
}


.upgradeModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
}

.upgradeModal {
  background-color: var(--bg-primary);
  border-radius: 12px;
  padding: 24px;
  width: 90%;
  max-width: 400px;
  border: 1px solid var(--border-color);
  animation: slideIn 0.3s ease-out;
}

.upgradeModalTitle {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 16px;
}

.upgradeModalMessage {
  color: var(--text-secondary);
  margin-bottom: 24px;
  line-height: 1.5;
}

.upgradeModalActions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .upgradeModal {
    width: 95%;
    margin: 16px;
  }

  .upgradeModalActions {
    flex-direction: column;
  }

  .upgradeModalActions button {
    width: 100%;
  }
}

/* Dark mode support */
:root[class="dark"] .upgradeModal {
  background-color: var(--bg-primary);
  border-color: var(--border-color);
}

:root[class="dark"] .actionButton.upgradeButton {
  color: #ff4d6a;
}

:root[class="dark"] .actionButton.upgradeButton:hover {
  background-color: rgba(255, 77, 106, 0.1);
}

:root[class="dark"] .crownIcon {
  color: #ff4d6a;
}


/* Visibility Edit Modal Styles */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: var(--bg-primary);
  border-radius: 12px;
  padding: 24px;
  width: 90%;
  max-width: 500px;
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
  border: 1px solid var(--border-color);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.modalTitle {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 24px;
}

.visibilityControl {
  background-color: var(--bg-secondary);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;
  border: 1px solid var(--border-color);
}

.visibilityOptions {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.visibilityOption {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.visibilityOption:hover {
  background-color: var(--bg-primary);
}

.visibilityOption input[type="radio"] {
  width: 16px;
  height: 16px;
  accent-color: #E2384D;
}

.visibilityOption span {
  color: var(--text-primary);
  font-size: 14px;
}

.customVisibilitySelector {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid var(--border-color);
}

.customVisibilityTitle {
  font-size: 14px;
  color: var(--text-secondary);
  margin-bottom: 12px;
}

.customVisibilityOption {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 6px;
  transition: background-color 0.2s;
}

.customVisibilityOption:hover {
  background-color: var(--bg-primary);
}

.customVisibilityOption.manager {
  background-color: rgba(226, 56, 77, 0.1);
}

.customVisibilityOption input[type="checkbox"] {
  width: 16px;
  height: 16px;
  accent-color: #E2384D;
}

.customVisibilityOption.manager input[type="checkbox"] {
  opacity: 0.7;
  cursor: not-allowed;
}

.customVisibilityOption label {
  color: var(--text-primary);
  font-size: 14px;
  cursor: pointer;
}

.modalActions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
  padding-top: 16px;
  border-top: 1px solid var(--border-color);
}

.cancelButton,
.submitButton {
  padding: 10px 20px;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.cancelButton {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
}

.cancelButton:hover {
  background-color: var(--border-color);
}

.submitButton {
  background-color: #E2384D;
  color: white;
  border: none;
}

.submitButton:hover {
  background-color: #cc2b3d;
}

/* Making the modal scrollable on smaller screens */
@media (max-height: 700px) {
  .modalContent {
    max-height: 80vh;
  }

  .visibilityControl {
    max-height: 50vh;
    overflow-y: auto;
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .modalContent {
    width: 95%;
    padding: 16px;
  }

  .visibilityControl {
    padding: 12px;
  }

  .modalActions {
    flex-direction: column;
  }

  .cancelButton,
  .submitButton {
    width: 100%;
  }

  .visibilityOption,
  .customVisibilityOption {
    padding: 12px 8px;
  }
}

/* Dark mode support */
:root[class="dark"] .modalContent {
  background-color: var(--bg-primary);
  border-color: var(--border-color);
}

:root[class="dark"] .visibilityControl {
  background-color: var(--bg-secondary);
}

:root[class="dark"] .visibilityOption:hover,
:root[class="dark"] .customVisibilityOption:hover {
  background-color: var(--bg-primary);
}

:root[class="dark"] .customVisibilityOption.manager {
  background-color: rgba(226, 56, 77, 0.2);
}

/* Animation */
@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modalContent {
  animation: modalFadeIn 0.3s ease-out;
}

/* Accessibility */
.visibilityOption input[type="radio"]:focus + span,
.customVisibilityOption input[type="checkbox"]:focus + label {
  outline: 2px solid #E2384D;
  outline-offset: 2px;
}

/* Scrollbar styles */
.modalContent::-webkit-scrollbar,
.visibilityControl::-webkit-scrollbar {
  width: 6px;
}

.modalContent::-webkit-scrollbar-track,
.visibilityControl::-webkit-scrollbar-track {
  background: var(--bg-secondary);
}

.modalContent::-webkit-scrollbar-thumb,
.visibilityControl::-webkit-scrollbar-thumb {
  background-color: var(--border-color);
  border-radius: 3px;
}

/* Loading state */
.modalContent.loading {
  opacity: 0.7;
  pointer-events: none;
}

.modalContent.loading::after {
  content: '';
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .alertModal {
    width: 95%;
    margin: 1rem;
  }

  .visibility {
    max-width: 100%;
  }

  .attachmentMeta {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .visibility {
    width: 100%;
  }

}