:root {
    --bg-primary: #ffffff;
    --bg-secondary: #f3f4f6;
    --text-primary: #111827;
    --text-secondary: #6b7280;
    --border-color: #e5e7eb;
    --page-bg: #f5f7fb;
  }
  
  :root[class="dark"] {
    --bg-primary: #1f2937;
    --bg-secondary: #111827;
    --text-primary: #ffffff;
    --text-secondary: #9ca3af;
    --border-color: #374151;
    --page-bg: #111827;
  }
  
.right-menu {
    width: 280px;
    height: calc(100vh - 3.5rem);
    background-color: var(--bg-primary);
    position: fixed;
    right: 0;
    top: 3.5rem;
    display: flex;
    flex-direction: column;
    z-index: 40;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.right-menu::-webkit-scrollbar {
    display: none;
}

.tour-dates-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
  
.section-header {
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
    border-bottom: 1px solid var(--border-color);
    color: var(--text-primary);
    background-color: var(--bg-primary);
}
  
.section-header svg {
    color: var(--text-primary);
}
  
.dates-list {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
    scrollbar-width: none;
    -ms-overflow-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    background-color: var(--bg-primary);
}
  
.dates-list::-webkit-scrollbar {
    display: none;
}
/* Date Cards Base Styles */
.date-card {
    padding: 0.75rem;
    background: var(--bg-secondary);
    border: 1px solid var(--border-color);
    border-radius: 0.375rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease;
    position: relative;
}

.date-card.selected {
    border-color: #E2384D;
    border-width: 2px;
}

.date-card.cancelled {
    opacity: 0.7;

}



.date-card.selected .date {
    color: #E2384D;         /* Make the date text red for selected card */
}

/* Dark Theme Specific Styles */
:root.dark .date-card {
    background: var(--bg-secondary);
}

/* Light Theme Specific Styles */
:root.light .date-card {
    background: var(--bg-secondary);
}

.date-card:hover {
    border-color: var(--text-secondary);
}

.date-card.selected {
    border-color: var(--text-primary);
}

.date-card-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0.5rem;
}

.status-badges {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    align-items: flex-end;
}

.cancelled-badge,
.rescheduled-badge {
    font-size: 0.75rem;
    font-weight: 500;
    padding: 0.125rem 0.375rem;
    border-radius: 0.25rem;
    text-transform: uppercase;
}

.cancelled-badge {
    color: #FFFFFF;
    background-color: #E2384D;
}

.rescheduled-badge {
    color: #FFFFFF;
    background-color: #FFA500;
}

.date-card.cancelled .date,
.date-card.cancelled .venue-details span {
    text-decoration: line-through;
    color: #E2384D;
}

.rescheduled-info {
    font-size: 0.75rem;
    color: #FFA500;
    font-style: italic;
    margin-top: 0.25rem;
}

.date-card-header .date {
    color: var(--text-primary);
    font-weight: 500;
    font-size: 0.875rem;
}

.venue-info {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.venue-details {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.venue-details span:first-child {
    color: var(--text-primary);
    font-weight: 500;
    font-size: 0.875rem;
}

.date-location {
    font-size: 0.75rem;
    color: var(--text-secondary);
    font-style: italic;
}



.date-card-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.tour-selector {
    padding: 1rem;
    border-bottom: 1px solid var(--border-color);
    position: relative;
    z-index: 52; /* Higher than the overlay */
}

.tour-selector-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.75rem;
    background: var(--bg-secondary);
    border: 1px solid var(--border-color);
    border-radius: 0.375rem;
    cursor: pointer;
    color: var(--text-primary);
    transition: all 0.2s ease;
}

.tour-selector-button:hover {
    border-color: var(--text-secondary);
}
  
.tour-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 0;
    flex: 1;
}
  
.tour-act-name {
    font-weight: 600;
    font-size: 0.875rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    color: var(--text-primary);
}
  
.tour-name {
    font-size: 0.75rem;
    color: var(--text-secondary);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}
  
.tour-dropdown {
    position: absolute;
    top: 100%;
    left: 1rem;
    right: 1rem;
    background: var(--bg-primary);
    border: 1px solid var(--border-color);
    border-radius: 0.375rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    z-index: 52;
    max-height: 400px;
    overflow-y: auto;
    margin-top: 0.5rem;
}

.tour-dropdown-item-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid var(--border-color);
    background: var(--bg-secondary);
    transition: all 0.2s ease;
}

.tour-dropdown-item-container:hover {
    background: var(--border-color);
}
  
.tour-dropdown-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    color: var(--text-primary);
    text-align: left;
}

.delete-tour-button {
    background: none;
    border: none;
    padding: 0.5rem;
    color: #E2384D;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 0.2s ease;
}

.delete-tour-button:hover {
    opacity: 1;
}

.tour-actions {
    display: flex;
    gap: 8px;
    margin-left: 10px;
  }
  
  .action-button {
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    color: var(--text-secondary);
    transition: color 0.2s ease;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .action-button:hover {
    color: var(--text-primary);
  }
  
  .action-button:last-child:hover {
    color: #E2384D;
  }
  
.create-tour-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    padding: 0.75rem 1rem;
    background: var(--bg-secondary);
    border: none;
    border-top: 1px solid var(--border-color);
    color: var(--text-primary);
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s ease;
}

.create-tour-button:hover {
    background-color: #E2384D;
    color: var(--text-primary);
}

.create-tour-button:hover:not(:disabled) {
    background: var(--border-color);
}

.create-tour-button:disabled {
    color: var(--text-secondary);
    cursor: not-allowed;
}

.right-menu::after {
    content: '';
    position: fixed;
    top: 3.5rem; /* Same as right-menu top */
    right: 0;
    width: 280px; /* Same as right-menu width */
    height: calc(100vh - 3.5rem); /* Same as right-menu height */
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease;
    z-index: 41;
}

/* Show overlay when dropdown is open */
.right-menu.dropdown-open::after {
    opacity: 1;
}

@media (max-width: 768px) {
    .right-menu {
        display: none;
    }
}