.ui-label {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-primary);
    margin-bottom: 8px;
    display: block;
  }
  
  /* Dark mode support */
  @media (prefers-color-scheme: dark) {
    .ui-label {
      color: var(--text-primary);
    }
  }