/* PostCard.css */
.post-card {
  background: var(--bg-secondary);
  border-radius: 0.75rem;
  overflow: hidden;
  transition: all 0.2s ease;
  position: relative;
}

.post-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.post-header {
  position: relative;
}

.post-options {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
  gap: 0.5rem;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.post-option-btn {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  transition: background-color 0.2s;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.post-option-btn:hover {
  background: rgba(255, 255, 255, 0.1);
}

.post-content {
  cursor: pointer;
}

.post-video,
.post-audio,
.post-photo {
  position: relative;
  width: 100%;
}

.post-embed {
  width: 100%;
  min-height: 300px;
}

.post-fallback {
  width: 100%;
  height: 300px;
  background: var(--bg-primary);
  display: flex;
  justify-content: center;
  align-items: center;
}

.fallback-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-secondary);
  text-align: center;
  padding: 1rem;
}

.fallback-content .media-icon {
  font-size: 2rem;
  opacity: 0.5;
}

.post-info {
  padding: 1rem;
}

.caption-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 0.75rem;
}

.post-caption {
  color: var(--text-primary);
  font-size: 0.875rem;
  line-height: 1.5;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  flex: 1;
  margin: 0;
}


.post-actions {
  display: flex;
  gap: 0.5rem;
  flex-shrink: 0;
}

.action-btn {
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  transition: all 0.2s ease;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.action-btn:hover {
  background: var(--bg-secondary);
  color: var(--text-primary);
}

/* Edit and Delete specific styles */
.action-btn[title="Edit post"] {
  color: var(--text-secondary);
}

.action-btn[title="Delete post"] {
  color: #ef4444;  /* Red color for delete */
}

.action-btn[title="Edit post"]:hover {
  color: var(--text-primary);
}

.action-btn[title="Delete post"]:hover {
  background: rgba(239, 68, 68, 0.1);  /* Light red background */
  color: #ef4444;
}


/* Make sure buttons are clickable */
.caption-container {
  position: relative;
  z-index: 1;
}

.post-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
}

.tag {
  font-size: 0.75rem;
  color: var(--text-secondary);
  background: var(--bg-primary);
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
}

.post-date {
  font-size: 0.75rem;
  color: var(--text-secondary);
}

/* Dark mode styles */
:root[class="dark"] .post-card {
  background: #1f2937;  /* Darker gray for dark mode */
}

:root[class="dark"] .post-info {
  background: #1f2937;
}

:root[class="dark"] .post-caption {
  color: #e5e7eb;  /* Lighter text for better contrast */
}

:root[class="dark"] .tag {
  background: #374151;  /* Slightly lighter than the card background */
  color: #9ca3af;
}

:root[class="dark"] .post-date {
  color: #9ca3af;
}



/* Responsive styles */
@media (max-width: 768px) {
  .post-embed {
    min-height: 150px;
    width: 100%;
  }

  .post-fallback {
    height: 250px;
  }
}

@media (max-width: 480px) {
  .post-embed {
    min-height: 200px;
  }

  .post-fallback {
    height: 200px;
  }
}