.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalContent {
    background-color: var(--bg-primary);
    border-radius: 12px;
    position: relative;
    width: 90%;
    max-width: 1200px;
    height: 90vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid var(--border-color);
  }
  
  .modalHeader h2 {
    font-size: 1.25rem;
    color: var(--text-primary);
    margin: 0;
    font-weight: 600;
  }
  
  .modalActions {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .externalLink {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--text-primary);
    text-decoration: none;
    background-color: var(--bg-secondary);
    padding: 0.5rem 1rem;
    border-radius: 6px;
    font-size: 0.875rem;
    transition: all 0.2s ease;
  }
  
  .externalLink:hover {
    background-color: var(--border-color);
  }
  
  .externalLink:focus {
    outline: none;
    box-shadow: 0 0 0 2px var(--bg-primary), 0 0 0 4px #E2384D;
  }
  
  .closeButton {
    background: none;
    border: none;
    font-size: 1.25rem;
    cursor: pointer;
    color: var(--text-secondary);
    z-index: 1;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 0.2s ease;
  }
  
  .closeButton:hover {
    background-color: var(--bg-secondary);
    color: var(--text-primary);
  }
  
  .iframeContainer {
    flex: 1;
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .websiteIframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  .errorMessage {
    color: var(--text-secondary);
    text-align: center;
    padding: 2rem;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .redirectText {
    color: var(--text-primary);
    font-weight: 500;
    margin-top: 1rem;
  }
  
  .loaderContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--bg-primary);
    z-index: 1;
  }
  
  .spinner {
    width: 50px;
    height: 50px;
    border: 4px solid var(--border-color);
    border-top: 4px solid #E2384D;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 16px;
  }
  
  .loadingText {
    color: var(--text-secondary);
    font-size: 16px;
    font-weight: 500;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Dark mode support */
  :root[class="dark"] .modalContent {
    background-color: var(--bg-secondary);
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .modalContent {
      width: 95%;
      height: 95vh;
    }
    
    .modalHeader {
      padding: 12px 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }
    
    .modalActions {
      width: 100%;
      justify-content: space-between;
    }
    
    .externalLink {
      padding: 0.5rem;
    }
    
    .externalLink span {
      display: none;
    }
  }