:root {
  --bg-primary: #ffffff;
  --bg-secondary: #f3f4f6;
  --text-primary: #111827;
  --text-secondary: #6b7280;
  --border-color: #e5e7eb;
  --page-bg: #f5f7fb;
  --tag-primary: #f3f4f6;
}

:root[class="dark"] {
  --bg-primary: #1f2937;
  --bg-secondary: #111827;
  --text-primary: #ffffff;
  --text-secondary: #9ca3af;
  --border-color: #374151;
  --page-bg: #111827;
  --tag-primary: #1f2937;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}


  .modal-content {
    background: var(--bg-primary);
    border-radius: 16px;
    padding: 24px;
    width: min(95%, 800px); /* Increased max-width */
    max-height: 90vh;
    border: 1px solid var(--border-color);
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
    overflow-y: auto;
    overflow-x: hidden; /* Hide horizontal scrollbar */
  }

  .modal-content {
    scrollbar-width: none;
  }
  
  /* For Chrome, Safari, and Opera */
  .modal-content::-webkit-scrollbar {
    display: none;
  }
  

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--border-color);
}

.modal-header h2 {
  margin: 0;
  font-size: 1.5rem;
  color: var(--text-primary);
  font-weight: 600;
}

.close-button {
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.close-button:hover {
  background-color: var(--bg-hover);
  color: #E2384D;
}

.travel-mode-options {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 24px 0;
}

.travel-mode-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  background: var(--bg-secondary);
  border: 2px solid var(--border-color);
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.travel-mode-card:hover {
  background-color: var(--bg-hover);
  border-color: #E2384D;
  transform: translateY(-2px);
}

.travel-mode-icon {
  font-size: 2rem;
  color: #E2384D;
  margin-bottom: 12px;
}

.travel-mode-text {
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--text-primary);
}

.ticket-form {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 8px;
  min-width: 0; /* Prevent flex items from overflowing */
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  
}

.form-group.full-width {
  width: 100%;
}

.form-group label {
  font-weight: 500;
  color: var(--text-primary);
  font-size: 14px;
}

.form-input {
  width: 100%;
  padding: 12px;
  background-color: var(--bg-secondary);
  border: 2px solid var(--border-color);
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s ease;
  color: var(--text-primary);
}

.form-input:focus {
  outline: none;
  border-color: #E2384D;
  background-color: var(--bg-primary);
  box-shadow: 0 0 0 3px rgba(226, 56, 77, 0.1);
}

.form-input:hover {
  border-color: var(--text-secondary);
}

.form-input::placeholder {
  color: var(--text-secondary);
}

.visibility-selector {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  background: var(--bg-secondary);
  border: 2px solid var(--border-color);
  border-radius: 8px;
  max-height: 200px;
  overflow-y: auto;
}

.visibility-option {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  border-radius: 6px;
  transition: background-color 0.2s ease;
}

.visibility-option:hover {
  background-color: var(--bg-hover);
}

.visibility-option input[type="checkbox"] {
  width: 18px;
  height: 18px;
  accent-color: #E2384D;
}

.visibility-option.manager {
  background-color: rgba(226, 56, 77, 0.1);
}

.visibility-option.manager input[type="checkbox"] {
  opacity: 0.7;
  cursor: not-allowed;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 24px;
  padding-top: 20px;
  border-top: 1px solid var(--border-color);
}

.modal-button {
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
}

.modal-button.primary {
  background-color: #E2384D;
  color: white;
  border: none;
}

.modal-button.primary:hover {
  background-color: #cc2d43;
  transform: translateY(-1px);
}

.modal-button.secondary {
  background-color: var(--bg-secondary);
  border: 2px solid var(--border-color);
  color: var(--text-primary);
}

.modal-button.secondary:hover {
  background-color: var(--bg-hover);
  border-color: var(--text-secondary);
}

/* File upload styling */
.file-upload {
  position: relative;
  width: 100%;
}

.file-upload input[type="file"] {
  display: none;
}

.file-upload-label {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  background-color: var(--bg-secondary);
  border: 2px dashed var(--border-color);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  color: var(--text-secondary);
  justify-content: center;
}

.file-upload-label:hover {
  border-color: #E2384D;
  color: #E2384D;
}

.file-name {
  margin-top: 8px;
  font-size: 12px;
  color: var(--text-secondary);
  text-align: center;
}

/* Time input custom styling */
input[type="datetime-local"] {
  appearance: none;
  padding: 12px;
  width: 100%;
  border: 2px solid var(--border-color);
  border-radius: 8px;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  font-size: 1rem;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  filter: var(--calendar-icon-filter);
}

/* Loading state */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  border-radius: 16px;
}

.loading-spinner {
  position: relative;
  width: 40px;
  height: 40px;
}

.loading-spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid #fff;
  border-top-color: #E2384D;
  animation: spinner 0.8s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

/* Add ticket button styling */
.add-ticket-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px;
  margin-bottom: 24px;
  background-color: var(--bg-primary);
  border: 2px solid #E2384D;
  border-radius: 12px;
  color: #E2384D;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  gap: 12px;
}

.add-ticket-button:hover {
  background-color: var(--bg-hover);
  transform: translateY(-1px);
}

.add-ticket-button svg {
  font-size: 20px;
}

/* Error state styling */
.error-message {
  color: #E2384D;
  font-size: 12px;
  margin-top: 4px;
}

.form-input.error {
  border-color: #E2384D;
}

/* Required field indicator */
.required-field::after {
  content: '*';
  color: #E2384D;
  margin-left: 4px;
}

.passenger-dropdown {
  position: relative;
}

.dropdown-button {
  width: 100%;
  padding: 12px;
  background-color: var(--bg-secondary);
  border: 2px solid var(--border-color);
  border-radius: 8px;
  color: var(--text-primary);
  text-align: left;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease;
}

.dropdown-button:hover {
  border-color: var(--text-secondary);
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 250px;
  overflow-y: auto;
  background-color: var(--bg-primary);
  border: 2px solid var(--border-color);
  border-radius: 8px;
  margin-top: 4px;
  z-index: 1000;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.passenger-option {
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  border-bottom: 1px solid var(--border-color);
}

.passenger-option:last-child {
  border-bottom: none;
}

.passenger-option:hover {
  background-color: var(--bg-hover);
}

.passenger-option.selected {
  background-color: rgba(226, 56, 77, 0.1);
}

.passenger-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
}

.passenger-info {
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex: 1;
}

.passenger-name {
  font-weight: 500;
  color: var(--text-primary);
}

.passenger-username {
  font-size: 12px;
  color: var(--text-secondary);
}

.passenger-usertype {
  font-size: 12px;
  color: #E2384D;
}

.selected-passengers {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.selected-passenger-tag {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px 4px 4px;
  background-color: var(--tag-primary);
  color : var(--text-primary);
  border-radius: 20px;
  font-size: 14px;
}

.selected-passenger-tag img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
  
}

.remove-passenger {
  margin-left: 4px;
  cursor: pointer;
  color: var(--text-secondary);
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.remove-passenger:hover {
  color: #E2384D;
  background-color: rgba(226, 56, 77, 0.1);
}

.dropdown-content::-webkit-scrollbar {
  width: 8px;
}

.dropdown-content::-webkit-scrollbar-track {
  background: var(--bg-primary);
  border-radius: 4px;
}

.dropdown-content::-webkit-scrollbar-thumb {
  background: var(--border-color);
  border-radius: 4px;
}

.dropdown-content::-webkit-scrollbar-thumb:hover {
  background: var(--text-secondary);
}

/* Dark mode specific styles */
:root[class="dark"] .modal-content {
  background-color: var(--bg-secondary);
}

:root[class="dark"] .file-upload-label {
  background-color: var(--bg-primary);
}

:root[class="dark"] input[type="datetime-local"] {
  background-color: var(--bg-primary);
}

/* Responsive styles */
@media (max-width: 768px) {
  .modal-content {
    width: 95%;
    padding: 20px;
    margin: 16px;
  }

  .travel-mode-options {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
    padding: 24px 0;
  }

  .travel-mode-card {
    padding: 16px;
  }

  .modal-button {
    padding: 10px 20px;
  }

  .form-section {
    gap: 16px;
  }

  .visibility-selector {
    max-height: 160px;
  }
  
  .form-input, 
  input[type="datetime-local"] {
    font-size: 16px; /* Prevents zoom on mobile */
  }
  
  
  
  /* Style for multiple select */
  select[multiple] {
    padding: 0;
    overflow-y: auto;
  }
  
  select[multiple] option {
    padding: 8px;
    border-bottom: 1px solid var(--border-color);
  }
  
  select[multiple] option:hover {
    background-color: var(--bg-hover);
  }
  
  select[multiple] option:checked {
    background-color: rgba(226, 56, 77, 0.1);
    color: #E2384D;
  }
  
  /* Date display helper text */
  .date-display {
    font-size: 12px;
    color: var(--text-secondary);
    margin-top: 4px;
    display: block;
  }
  
  /* Required field indicator */
  .required-field::after {
    content: '*';
    color: #E2384D;
    margin-left: 4px;
  }
}

/* Scrollbar styling */
.visibility-selector::-webkit-scrollbar {
  width: 8px;
}

.visibility-selector::-webkit-scrollbar-track {
  background: var(--bg-primary);
  border-radius: 4px;
}

.visibility-selector::-webkit-scrollbar-thumb {
  background: var(--border-color);
  border-radius: 4px;
}

.visibility-selector::-webkit-scrollbar-thumb:hover {
  background: var(--text-secondary);
}