.login-page {
  min-height: 100vh;
  display: flex;
  position: relative;
  background-color: #000;
}

.login-page::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgb(17, 24, 39) 100%
  );
  z-index: 1;
}

.login-container {
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  position: relative;
  z-index: 2;
}

.login-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  color: white;
}

.logo-section {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 40px;
}

.logo {
  height: 50px;
  width: auto;
}

.brand-title {
  color: #E2384D;
  font-size: 28px;
  font-weight: 600;
  margin: 0;
}

.welcome-text {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #fff;
}

.login-description {
  font-size: 18px;
  color: #ccc;
  margin-bottom: 30px;
  line-height: 1.6;
}

.login-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.login-form-container {
  width: 100%;
  max-width: 400px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 8px 32px #1f293789;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.login-form-container h2 {
  color: #fff;
  text-align: center;
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 600;
}

.form-group {
  margin-bottom: 25px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.form-group input {
  width: 100%;
  padding: 12px 16px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  transition: all 0.3s ease;
}

.form-group input:focus {
  outline: none;
  border-color: #E2384D;
  background: rgba(255, 255, 255, 0.15);
}

.error {
  color: #ff6b6b;
  font-size: 14px;
  margin-bottom: 20px;
  text-align: center;
  padding: 10px;
  background: rgba(255, 107, 107, 0.1);
  border-radius: 8px;
}

button {
  width: 100%;
  padding: 14px;
  background: #E2384D;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

button:hover {
  background: #ff4057;
  transform: translateY(-2px);
}

button:disabled {
  background: #666;
  cursor: not-allowed;
  transform: none;
}

.signup-link {
  text-align: center;
  margin-top: 20px;
  color: #ccc;
  font-size: 14px;
}

.signup-link a {
  color: #E2384D;
  text-decoration: none;
  font-weight: 500;
  margin-left: 5px;
  transition: color 0.3s ease;
}

.signup-link a:hover {
  color: #ff4057;
}

.forgot-password {
  text-align: right;
  margin-top: -15px;
  margin-bottom: 20px;
}

.forgot-password a {
  color: #ccc;
  font-size: 14px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.forgot-password a:hover {
  color: #E2384D;
}

@media (max-width: 968px) {
  .login-container {
    flex-direction: column;
    padding: 20px;
  }

  .login-left {
    padding: 20px;
    text-align: center;
  }

  .logo-section {
    justify-content: center;
    margin-bottom: 30px;
  }

  .welcome-text {
    font-size: 28px;
  }

  .login-description {
    font-size: 16px;
  }

  .login-right {
    padding: 20px;
  }

  .login-form-container {
    padding: 30px;
  }
}

@media (max-width: 480px) {
  .login-form-container {
    padding: 20px;
  }

  .logo {
    height: 40px;
  }

  .brand-title {
    font-size: 24px;
  }

  .welcome-text {
    font-size: 24px;
  }
}