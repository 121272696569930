.mainLayout {
    display: flex;
    min-height: 100vh;
    padding-top: 3.5rem;
    background-color: var(--page-bg);
  }
  
  .editProfileMain {
    flex: 1;
    margin-left: 240px;
    margin-right: 270px;
    min-width: 0;
    height: calc(100vh - 3.5rem);
    overflow-y: auto;
  }
  
  .editProfileContent {
    padding: 2rem;
    max-width: 100%;
    margin: 0 auto;
  }
  
  .editProfileHeader {
    margin-bottom: 2rem;
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    color: var(--text-primary);
    text-align: center;
  }
  
  .formContainer {
    background-color: var(--bg-primary);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    padding: 2rem;
    margin-bottom: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .section {
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--border-color);
  }
  
  .section:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
  
  .sectionTitle {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--text-primary);
    margin-bottom: 1.5rem;
  }
  
  .subsectionTitle {
    font-size: 1rem;
    font-weight: 500;
    color: var(--text-primary);
    margin-bottom: 1rem;
  }
  
  /* Profile Pictures Section */
  .picturesSection {
    position: relative;
    margin-bottom: 2rem;
  }
  
  .coverPhotoContainer {
    width: 100%;
    height: 180px;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 3rem;
  }
  
  .coverPhoto {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .editCoverPhotoButton {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    border: none;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .editCoverPhotoButton:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .profilePictureContainer {
    position: absolute;
    left: 2rem;
    top: 130px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid var(--bg-primary);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }
  
  .profilePicture {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .editProfilePictureButton {
    position: absolute;
    bottom: 0.25rem;
    right: 0.25rem;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .editProfilePictureButton:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .hiddenInput {
    display: none;
  }
  
  /* Form Fields */
  .field {
    margin-bottom: 1.5rem;
  }
  
  .field label {
    display: block;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: var(--text-primary);
  }
  
  .inputGroup {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .inputGroup .input {
    flex: 1;
  }
  
  .input {
    flex: 1;
    padding: 0.75rem;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    font-size: 1rem;
    color: var(--text-primary);
    background-color: var(--bg-primary);
    width: 100%;
  }
  
  .input:focus {
    outline: none;
    border-color: #E2384D;
  }
  
  .textarea {
    width: 100%;
    min-height: 120px;
    padding: 0.75rem;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    font-size: 1rem;
    color: var(--text-primary);
    background-color: var(--bg-primary);
    resize: vertical;
    font-family: inherit;
  }
  
  .textarea:focus {
    outline: none;
    border-color: #E2384D;
  }
  
  .charCount {
    font-size: 0.75rem;
    color: var(--text-secondary);
    text-align: right;
    margin-top: 0.25rem;
  }
  
  .helperText {
    font-size: 0.75rem;
    color: var(--text-secondary);
    margin-top: 0.25rem;
  }
  
  .updateButton {
    max-width: 100px;
    padding: 0.75rem 1.5rem;
    background-color: #E2384D;
    color: white;
    border: none;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    flex-shrink: 0;
  }
  
  .updateButton:hover {
    background-color: #cc2d43;
  }
  
  .updateButton:disabled {
    background-color: var(--text-secondary);
    cursor: not-allowed;
  }
  
  /* Social Links */
  .socialInputGroup {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  
  .socialIcon {
    font-size: 1.5rem;
    width: 24px;
    color: #E2384D;
  }
  
  .socialIconContainer {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .brandSocialIcon {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
  
  /* Endorsements */

  /* Brand search results styling */
.brandResultContent {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.brandLogo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  background-color: var(--bg-primary);
}

.brandInfo {
  flex: 1;
}

.selectedBrandsGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}

.selectedBrandTag {
  display: flex;
  align-items: center;
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: 2rem;
  padding: 0.25rem;
  gap: 0.5rem;
}

.selectedBrandLogo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  background-color: var(--bg-primary);
}

.selectedBrandName {
  margin-right: 0.5rem;
  color: var(--text-primary);
  font-size: 0.875rem;
}

.removeBrandButton {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-primary);
  color: var(--text-secondary);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 0.75rem;
}

.removeBrandButton:hover {
  color: #E2384D;
  background-color: var(--border-color);
}
  .searchBrandButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
    padding: 0.75rem;
    background-color: var(--bg-secondary);
    border: 1px solid var(--border-color);
    border-radius: 6px;
    color: var(--text-primary);
    cursor: pointer;
    transition: all 0.2s ease;
    font-weight: 500;
  }
  
  .searchBrandButton:hover {
    background-color: var(--border-color);
  }
  
  .selectedBrandsContainer {
    margin-top: 1.5rem;
  }
  
  .brandsGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    margin-top: 0.5rem;
  }
  
  .brandTag {
    display: flex;
    align-items: center;
    background-color: var(--bg-secondary);
    border: 1px solid var(--border-color);
    border-radius: 1.5rem;
    padding: 0 0.5rem 0 1rem;
    height: 32px;
    position: relative;
  }
  
  .brandTagContent {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--text-primary);
    font-size: 0.875rem;
    padding: 0;
  }
  

  
  /* Save Button */
  .saveButton {
    width: 100%;
    padding: 1rem;
    background-color: #E2384D;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-top: 1rem;
  }
  
  .saveButton:hover {
    background-color: #cc2d43;
  }
  
  .saveButton:disabled {
    background-color: var(--text-secondary);
    cursor: not-allowed;
  }
  
  /* Modal Styles */
  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(4px);
  }
  
  .modal {
    background-color: var(--bg-primary);
    border-radius: 12px;
    padding: 1.5rem;
    width: 90%;
    max-width: 500px;
    max-height: 80vh;
    overflow-y: auto;
    border: 1px solid var(--border-color);
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
  }
  
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .modalHeader h2 {
    margin: 0;
    font-size: 1.25rem;
    color: var(--text-primary);
  }
  
  .closeButton {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: var(--bg-secondary);
    border: none;
    color: var(--text-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .closeButton:hover {
    background-color: var(--border-color);
  }
  
  .searchBar {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .searchBar input {
    flex: 1;
    padding: 0.75rem;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    font-size: 1rem;
    color: var(--text-primary);
    background-color: var(--bg-primary);
  }
  
  .searchBar input:focus {
    outline: none;
    border-color: #E2384D;
  }
  
  .searchButton {
    padding: 0.75rem 1rem;
    background-color: #E2384D;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    max-width: 150px;
    max-height: 40px;
  }
  
  .searchButton:hover {
    background-color: #cc2d43;
  }
  
  .searchButton:disabled {
    background-color: var(--text-secondary);
    cursor: not-allowed;
  }
  
  .searchResults {
    max-height: 400px;
    overflow-y: auto;
    margin-top: 1rem;
  }
  
  .resultsGrid {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .resultCard {
    background-color: var(--bg-secondary);
    border-radius: 8px;
    padding: 1rem;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .resultCard:hover {
    background-color: var(--border-color);
  }
  
  .resultContent {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .resultContent h3 {
    margin: 0;
    font-size: 1rem;
    font-weight: 500;
    color: var(--text-primary);
  }
  
  .brandWebsite {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
    color: var(--text-secondary);
  }
  
  .loading {
    display: flex;
    justify-content: center;
    padding: 2rem 0;
  }
  
  .noResults {
    text-align: center;
    padding: 2rem;
    color: var(--text-secondary);
    font-size: 0.875rem;
  }
  
  .searchPrompt {
    text-align: center;
    padding: 2rem;
    color: var(--text-secondary);
    font-size: 0.875rem;
  }
  
  /* Alert Modal Styles */
  .alertModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(4px);
  }
  
  .alertModal {
    background-color: var(--bg-primary);
    border-radius: 12px;
    padding: 24px;
    width: min(400px, 90%);
    border: 1px solid var(--border-color);
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
  }
  
  .alertHeader {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;
  }
  
  .alertIcon {
    font-size: 24px;
  }
  
  .alertIcon.success {
    color: #10b981;
  }
  
  .alertIcon.error {
    color: #ef4444;
  }
  
  .alertIcon.warning {
    color: #f59e0b;
  }
  
  .alertTitle {
    color: var(--text-primary);
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }
  
  .alertMessage {
    color: var(--text-secondary);
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 1.5;
  }
  
  .alertActions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
  
  .alertButton {
    padding: 8px 16px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .alertButton.cancel {
    background: var(--bg-secondary);
    border: 1px solid var(--border-color);
    color: var(--text-primary);
  }
  
  .alertButton.confirm {
    background: #E2384D;
    border: none;
    color: white;
  }
  
  .alertButton.success {
    background: #10b981;
    border: none;
    color: white;
  }
  
  .alertButton.error {
    background: #ef4444;
    border: none;
    color: white;
  }
  
  .alertButton.warning {
    background: #f59e0b;
    border: none;
    color: white;
  }
  
  .alertButton:hover {
    transform: translateY(-1px);
  }
  
  .alertButton.cancel:hover {
    background: var(--border-color);
  }
  
  .alertButton.confirm:hover {
    background: #cc2d43;
  }
  
  /* Loading Overlay */
  .loadingOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    backdrop-filter: blur(4px);
  }
  
  .spinner {
    width: 40px;
    height: 40px;
    border: 3px solid #fff;
    border-radius: 50%;
    border-top-color: #E2384D;
    animation: spin 0.8s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Dark mode specific styles */
  :root[class="dark"] .formContainer {
    background-color: var(--bg-secondary);
  }
  
  :root[class="dark"] .input,
  :root[class="dark"] .textarea {
    background-color: var(--bg-secondary);
    border-color: var(--border-color);
  }
  
  :root[class="dark"] .modal {
    background-color: var(--bg-secondary);
  }
  
  :root[class="dark"] .alertModal {
    background-color: var(--bg-secondary);
  }
  
  :root[class="dark"] .resultCard:hover {
    background-color: var(--bg-primary);
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .editProfileMain {
      margin: 0;
      padding: 1rem;
    }
  
    .editProfileContent {
      padding: 1rem;
    }
  
    .formContainer {
      padding: 1rem;
    }
  
    .inputGroup {
      flex-direction: column;
    }
  
    .updateButton {
      width: 100%;
      min-width: unset;
    }
  
    .alertModal {
      width: 90%;
      margin: 0 1rem;
    }
  
    .profilePictureContainer {
      left: 50%;
      transform: translateX(-50%);
    }
  
    .modal {
      width: 95%;
      margin: 0 1rem;
      max-height: 85vh;
    }
  
    .searchBar {
      flex-direction: column;
    }
  
    .searchButton {
      width: 100%;
    }
  }