.route-container {
    display: flex;
    height: calc(100vh - 200px);
    background-color: var(--bg-primary);
    gap: 20px;
    padding: 20px;
  }
  
  .map-section {
    flex: 1;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    background-color: var(--bg-secondary);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .google-map {
    width: 100%;
    height: 100%;
    border: none;
  }
  
  .external-map-button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
    background-color: var(--bg-primary);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: #E2384D;
    transition: all 0.2s ease;
  }
  
  .external-map-button:hover {
    background-color: var(--bg-hover);
    transform: scale(1.05);
  }
  
  .locations-section {
    width: 400px;
    background-color: var(--bg-primary);
    border-radius: 12px;
    border: 1px solid var(--border-color);
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .navigation {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid var(--border-color);
  }
  
.schedule-list {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE/Edge */
  }
  
  .schedule-list::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
  
  .schedule-title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 15px 45px;
    color: var(--text-primary);
    position: relative;
  }
  
  .schedule-title h3 {
    font-size: 16px;
    font-weight: 600;
    color: #E2384D;
    margin-right: 8px;
  }
  
  .info-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    color: #E2384D;
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    transition: background-color 0.2s;
  }
  
  .info-button:hover {
    background-color: rgba(226, 56, 77, 0.1);
  }
  
  .location-block {
    position: relative;
    padding: 0 0 20px 45px;
    min-height: 60px;
    display: flex;
    flex-direction: column;
  }

  .marker-line {
    position: absolute;
    left: 14px;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: transparent;
  }
  
  .marker-line::before {
    content: "";
    position: absolute;
    left: 0;
    top: 30px;
    bottom: 0;
    width: 2px;
    border-left: 2px dashed #E2384D;
  }
  
  .marker {
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
    height: 30px;
    background-color: var(--bg-primary);
    border: 2px solid #E2384D;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }
  
  .marker-letter {
    color: #E2384D;
    font-weight: bold;
    font-size: 14px;
  }
  
  .location-info {
    display: flex;
    flex-direction: column;
    padding-right: 40px; /* Make space for map button */
  }
  
  .location-name {
    font-size: 16px;
    font-weight: 600;
    color: var(--text-primary);
    margin-bottom: 4px;
  }

  .location-address {
    font-size: 14px;
    color: var(--text-secondary);
    margin-bottom: 4px;
    line-height: 1.4;
    text-align: left;
  }
  
  .location-time {
    font-size: 14px;
    color: var(--text-secondary);
    margin-top: 4px;
  }
  
  .map-button {
    position: absolute;
    right: 0;
    top: 2px; /* Align with location name */
    background: none;
    border: none;
    color: #E2384D;
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
  }
  
  .map-button:hover {
    background-color: rgba(226, 56, 77, 0.1);
  }
  
  /* Navigation adjustments */
  .navigation {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    border-bottom: 1px solid var(--border-color);
  }
  
  .nav-button {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 6px 12px;
    background: none;
    border: none;
    color: #E2384D;
    cursor: pointer;
    font-size: 14px;
    border-radius: 4px;
    transition: background-color 0.2s;
  }

  .nav-button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .nav-button:not(.disabled):hover {
    background-color: rgba(226, 56, 77, 0.1);
  }

  /* Empty state */
  .empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 40px;
    text-align: center;
    color: var(--text-secondary);
  }
  
  .empty-state svg {
    font-size: 50px;
    color: #E2384D;
    margin-bottom: 20px;
  }