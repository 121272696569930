.main-layout {
  display: flex;
  min-height: 100vh;
  padding-top: 3.5rem;
  background-color: var(--page-bg);
}

.crew-main {
  flex: 1;
  margin-left: 240px;
  margin-right: 270px;
  min-width: 0;
  height: calc(100vh - 3.5rem);
  overflow-y: auto;
}

.crew-content {
  padding: 2rem;
  max-width: 100%;
  margin: 0 auto;
}

.add-crew-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  background-color: var(--bg-primary);
  border: 1px solid #E2384D;
  border-radius: 8px;
  color: #E2384D;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  gap: 8px;
}

.add-crew-button:hover {
  background-color: var(--bg-hover);
}

.crew-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.crew-card {
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s ease;
}

.crew-card:hover {
  transform: translateY(-2px);
}

  
  .crew-header {
    display: flex;
    align-items: center;
    padding: 16px;
    gap: 16px;
  }
  
  .crew-photo {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    object-fit: cover;
  }
  
  .crew-info {
    flex: 1;
  }
  
  .name-container {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 4px;
  }
  
  .name-container h3 {
    font-size: 16px;
    font-weight: 600;
    color: var(--text-primary);
    margin: 0;
  }
  
  .verified-badge {
    width: 16px;
    height: 16px;
  }
  
  .crew-type {
    font-size: 14px;
    color: var(--text-secondary);
    margin: 0;
  }
  
  .crew-actions {
    display: flex;
    gap: 8px;
  }
  
  .action-button {
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    color: var(--text-secondary);
    border-radius: 4px;
    transition: all 0.2s ease;
  }
  
  .action-button:hover {
    background-color: var(--bg-hover);
    color: #E2384D;
  }
  
  .empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 48px 0;
    text-align: center;
  }
  
  .empty-icon {
    font-size: 48px;
    color: #E2384D;
    margin-bottom: 16px;
  }
  
  .empty-text {
    color: var(--text-secondary);
    font-size: 16px;
  }

  @media (max-width: 768px) {
    .crew-main {
      margin: 0;
      padding: 1rem;
      padding-bottom: 5rem;
    }
  
    .crew-content {
      padding: 1rem;
    }
  
    .crew-card {
      margin-bottom: 1rem;
    }
  }
  
  /* Add these new styles to your existing Crew.module.css */

  

.dates-container::-webkit-scrollbar {
  display: none;
}



.scroll-button:hover {
  background-color: var(--bg-hover);
  color: #E2384D;
}

.date-selector {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 2px;
  width: 100%;
  padding: 0;
}

.dates-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  gap: 4px;
  padding: 4px 0;
  width: calc(100% - 80px); /* Subtract space for scroll buttons */
  flex: 1; /* Add this to allow container to take available space */
}

.date-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 16px; /* Reduced padding */
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
  color: var(--text-primary);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 160px; /* Reduced from 180px */
  white-space: nowrap;
  margin: 0; /* Removed margin */
}

.scroll-button {
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  flex-shrink: 0; /* Prevent scroll buttons from shrinking */
  width: 40px; /* Fixed width for scroll buttons */
}


.date-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.date-button.cancelled .date-content {
  text-decoration: line-through;
  opacity: 0.7;
}

.status-badges {
  display: flex;
  gap: 4px;
  margin-top: 4px;
  font-size: 10px;
}

.cancelled-badge, .rescheduled-badge {
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
}

.cancelled-badge {
  background-color: rgba(239, 68, 68, 0.1);
  color: #ef4444;
}

.original-date {
  font-size: 10px;
  color: var(--text-secondary);
  margin-top: 4px;
}

.date-button.selected {
  background-color: #E2384D;
  border-color: #E2384D;
  color: white;
}

.date-button.selected .cancelled-badge,
.date-button.selected .rescheduled-badge,
.date-button.selected .original-date {
  color: white;
  background-color: rgba(255, 255, 255, 0.2);
}

.rescheduled-badge {
  background-color: rgba(245, 158, 11, 0.1);
  color: #f59e0b;
}

.date-day {
  font-weight: 600;
  font-size: 16px; /* Increased font size */
  margin-bottom: 6px;
}

.date-venue {
  font-size: 13px; /* Slightly larger font size */
  opacity: 0.8;
  text-align: center; /* Center align venue text */
  max-width: 100%; /* Ensure text doesn't overflow */
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis for long venue names */
}

.member-section {
  margin-bottom: 24px;
}

.section-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
  color: var(--text-primary);
}

.members-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.member-card {
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s ease;
}

.member-card:hover {
  transform: translateY(-2px);
}

.member-header {
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 16px;
}

.member-photo {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  object-fit: cover;
}

.member-info {
  flex: 1;
}

.name-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
}

.name-container h3 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: var(--text-primary);
}

.verified-badge {
  width: 16px;
  height: 16px;
}

.member-type {
  font-size: 14px;
  color: var(--text-secondary);
  margin: 0;
}

.member-actions {
  display: flex;
  gap: 8px;
}

.pending-member {
  background-color: var(--bg-secondary);
  border: 1px dashed var(--border-color);
}

.pending-badge {
  display: inline-block;
  padding: 4px 8px;
  background-color: #FFF3E0;
  color: #E65100;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  margin-top: 4px;
}

.member-username {
  font-size: 14px;
  color: var(--text-secondary);
  margin: 2px 0;
}

/* Modal Styles with Theme Support */
.modalOverlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 40;
}

/* Light Theme (default) */
.modalContent {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 2rem;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modalTitle {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #111827;
}

.modalText {
  margin-bottom: 1.5rem;
  line-height: 1.5;
  color: #4b5563;
}

.modalActions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

.modalCancelButton {
  padding: 8px 16px;
  background-color: #f3f4f6;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  color: #111827;
  transition: all 0.2s ease;
}

.modalCancelButton:hover {
  background-color: #e5e7eb;
}

.modalConfirmButton {
  padding: 8px 16px;
  background-color: #E2384D;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.modalConfirmButton:hover {
  background-color: #d42e41;
}

/* Dark Theme */
:global(.dark) .modalContent {
  background-color: #1f2937;
  border: 1px solid #374151;
}

:global(.dark) .modalTitle {
  color: #ffffff;
}

:global(.dark) .modalText {
  color: #9ca3af;
}

:global(.dark) .modalCancelButton {
  background-color: #374151;
  border-color: #4b5563;
  color: #ffffff;
}

:global(.dark) .modalCancelButton:hover {
  background-color: #4b5563;
  border-color: #6b7280;
}

/* Add responsive styles */
@media (max-width: 640px) {
  .modalContent {
    width: 95%;
    padding: 1.5rem;
  }

  .modalActions {
    flex-direction: column-reverse;
    gap: 8px;
  }

  .modalCancelButton,
  .modalConfirmButton {
    width: 100%;
    padding: 12px;
  }
}

@media (max-width: 768px) {
  .date-button {
    min-width: 140px;
    padding: 8px 12px;
  }
  
  .original-date {
    font-size: 9px;
  }
}


/* Dark mode styles */
:root[class="dark"] .date-button {
  background-color: var(--bg-secondary);
}

:root[class="dark"] .date-button.selected {
  background-color: #E2384D;
}
  /* Dark mode styles */
  :root[class="dark"] .crew-card {
    background-color: var(--bg-secondary);
  }
  
  :root[class="dark"] .add-crew-button {
    background-color: var(--bg-secondary);
  }