.location-search-modal {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
    padding: 24px;
    position: relative;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .modal-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
  }
  
  .search-container {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
  }
  
  .search-input {
    flex: 1;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .search-button {
    padding: 8px 16px;
    background-color: #E2384D;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .search-button:hover {
    background-color: #c62236;
  }
  
  .suggestions-container {
    max-height: 200px;
    overflow-y: auto;
    margin-bottom: 16px;
  }
  
  .suggestion-item {
    padding: 8px 12px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
    transition: background-color 0.2s;
  }
  
  .suggestion-item:hover {
    background-color: #f5f5f5;
  }
  
  .selected-location {
    margin-bottom: 16px;
  }
  
  .selected-location-header {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 8px;
  }
  
  .location-card {
    background: #f5f5f5;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  .location-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .location-details {
    flex: 1;
  }
  
  .location-name {
    font-weight: 600;
    margin-bottom: 4px;
  }
  
  .location-address {
    color: #666;
    font-size: 0.9rem;
  }
  
  .map-container {
    height: 200px;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 16px;
  }
  
  .button-container {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
  
  .cancel-button {
    padding: 8px 16px;
    background-color: #ccc;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .select-button {
    padding: 8px 16px;
    background-color: #E2384D;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .select-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }