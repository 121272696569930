
.modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 1rem;
 }
 
 .modal-content {
  background-color: var(--bg-primary);
  border-radius: 1rem;
  padding: 2rem;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  color: var(--text-primary);
 }
 
 .modal-content h2 {
  color: var(--text-primary);
  margin-bottom: 1.5rem;
 }
 
 .modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
 }
 
 .modal-close:hover {
  background-color: var(--bg-secondary);
 }
 
 .tours-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  padding: 1rem 0;
 }
 
 .tour-card {
  background: var(--bg-secondary);
  border-radius: 0.75rem;
  overflow: hidden;
  transition: transform 0.2s ease;
 }
 
 .tour-card:hover {
  transform: translateY(-2px);
 }
 
 .tour-cover-photo {
  width: 100%;
  height: 160px;
  object-fit: cover;
 }
 
 .tour-card-content {
  padding: 1rem;
 }
 
 .tour-card-content h3 {
  margin: 0 0 0.5rem 0;
  font-size: 1.125rem;
  color: var(--text-primary); 
 }
 
 .tour-name,
 .act-name {
  color: var(--text-secondary);
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
 }
 
 .tour-dates,
 .shows-count {
  color: var(--text-secondary);
  font-size: 0.75rem;
 }
 
 .shows-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
 }
 
 .show-card {
  background: var(--bg-secondary);
  border-radius: 0.75rem;
  padding: 1rem;
 }
 
 .show-date {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
 }
 
 .show-details h3 {
  color: var(--text-primary);
  margin: 0 0 0.5rem 0;
 }
 
 .venue-name {
  font-weight: 500;
  margin: 0.5rem 0 0.25rem 0;
  color: var(--text-primary);
 }
 
 .venue-address {
  color: var(--text-secondary);
  font-size: 0.875rem;
 }
 
 .rescheduled-note {
  color: #E2384D;
  font-style: italic;
  font-size: 0.875rem;
  margin-top: 0.5rem;
 }
 
 .users-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
 }
 
 .user-card {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  background: var(--bg-secondary);
  border-radius: 0.75rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
 }
 
 .user-card:hover {
  background: var(--border-color);
 }
 
 .user-photo {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  object-fit: cover;
 }
 
 .user-info {
  flex: 1;
 }
 
 .user-name-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.25rem;
 }
 
 .user-name-container h3 {
  margin: 0;
  font-size: 1rem;
  color: var(--text-primary);
 }
 
 .verified-badge {
  width: 16px;
  height: 16px;
 }
 
 .username {
  color: var(--text-secondary);
  font-size: 0.875rem;
  margin: 0 0 0.25rem 0;
 }
 
 .user-type,
 .user-subtype {
  color: var(--text-secondary);
  font-size: 0.875rem;
  margin: 0;
 }
 
 .load-more-button {
  width: 100%;
  padding: 0.75rem;
  background: var(--bg-secondary);
  border: none;
  border-radius: 0.5rem;
  color: var(--text-primary);
  cursor: pointer;
  margin-top: 1rem;
  transition: background-color 0.2s ease;
 }
 
 .load-more-button:hover {
  background: var(--border-color);
 }
 
 @media (max-width: 768px) {
  .modal-content {
    width: 95%;
    padding: 1.5rem;
  }
 
  .tours-list {
    grid-template-columns: 1fr;
  }
 
  .user-card {
    flex-direction: row;
    padding: 0.75rem;
  }
 
  .user-photo {
    width: 50px;
    height: 50px;
  }
 
  .tour-card-content h3 {
    font-size: 1rem;
  }
 }