.profile-container {
  min-height: 100vh;
  width: 100%;
  background-color: #f9fafb;
}

.main-layout {
  display: flex;
  width: 100%;
  min-height: calc(100vh - 64px);
}

.profile-main {
  flex: 1;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
}

.cover-photo-container {

  width: 1500px;
  height: 675px;

  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
}

.cover-photo {
  width: 1500px;
  height: 675px;

}

.cover-overlay {

  inset: 0;
  background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(255, 0, 0, 0.4) 100%
  );
}

.profile-content {
  max-width: 1280px;
  margin: -8rem auto 2rem;
  padding: 0 1.5rem;
  position: relative;
  z-index: 10;
}

.profile-card {
  background: white;
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1);
  padding: 2rem;
}

.profile-header {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 2rem;
  margin-bottom: 2rem;
}

.profile-photo {
  width: 10rem;
  height: 10rem;
  border-radius: 9999px;
  border: 4px solid white;
  box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1);
  object-fit: cover;
}

.profile-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.name-section {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.name-section h1 {
  font-size: 2rem;
  font-weight: 700;
  color: #111827;
  margin: 0;
}

.verified-badge {
  width: 1.5rem;
  height: 1.5rem;
}

.user-type {
  font-size: 1.125rem;
  color: #6b7280;
}

.info-items {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: 0.5rem;
}

.info-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #4b5563;
}

.info-item.on-tour {
  color: #E2384D;
}

.info-item.available {
  color: #10b981;
}

.bio {
  font-size: 1rem;
  color: #4b5563;
  line-height: 1.5;
  margin-top: 1rem;
}

.profile-actions {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

.action-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  transition: all 0.2s;
  border: none;
  cursor: pointer;
}

.action-button.edit {
  background-color: #111827;
  color: white;
}

.action-button.contact {
  background-color: #E2384D;
  color: white;
}

.action-button.share {
  background-color: #f3f4f6;
  color: #4b5563;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1.5rem;
  padding: 1.5rem;
  background-color: #f9fafb;
  border-radius: 0.75rem;
  margin: 2rem 0;
}

.stat-card {
  text-align: center;
  padding: 1rem;
}

.stat-value {
  font-size: 1.875rem;
  font-weight: 600;
  color: #111827;
}

.stat-label {
  color: #6b7280;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.content-tabs {
  display: flex;
  gap: 1rem;
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 2rem;
}

.tab {
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  color: #6b7280;
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
}

.tab.active {
  color: #E2384D;
}

.tab.active::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #E2384D;
}

.tour-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.tour-card {
  background: white;
  border-radius: 0.75rem;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.2s;
}

.tour-card:hover {
  transform: translateY(-2px);
}

.tour-cover {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.tour-info {
  padding: 1.5rem;
}

.tour-info h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #111827;
  margin: 0 0 0.5rem 0;
}

.act-name {
  color: #6b7280;
  margin-bottom: 1rem;
}

.tour-dates {
  font-size: 0.875rem;
  color: #4b5563;
  margin-bottom: 0.5rem;
}

.shows-count {
  color: #E2384D;
  font-weight: 600;
  font-size: 0.875rem;
}

.dates-container {
  margin-top: 2rem;
}

.date-filters {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
  overflow-x: auto;
  padding-bottom: 0.5rem;
}

.filter-btn {
  padding: 0.5rem 1.5rem;
  border-radius: 9999px;
  background: #f3f4f6;
  color: #4b5563;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
  white-space: nowrap;
}

.filter-btn.active {
  background: #E2384D;
  color: white;
}

.dates-list {
  display: grid;
  gap: 1rem;
}

.date-card {
  background: white;
  border-radius: 0.75rem;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.date-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.date {
  font-weight: 600;
  color: #111827;
}

.status {
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
}

.status.on-tour {
  background: #fee2e2;
  color: #E2384D;
}

.status.available {
  background: #d1fae5;
  color: #059669;
}

.venue-info h4 {
  font-size: 1rem;
  font-weight: 600;
  color: #111827;
  margin: 0 0 0.25rem 0;
}

.venue-info p {
  color: #6b7280;
  font-size: 0.875rem;
  margin: 0;
}

.load-more {
  width: 100%;
  padding: 0.75rem;
  background: none;
  border: 2px solid #E2384D;
  color: #E2384D;
  border-radius: 0.5rem;
  margin-top: 1.5rem;
  cursor: pointer;
  transition: all 0.2s;
  font-weight: 500;
}

.load-more:hover {
  background: #E2384D;
  color: white;
}

.loading-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  width: 3rem;
  height: 3rem;
  border: 3px solid #f3f4f6;
  border-top-color: #E2384D;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.bottom-nav {
  display: none;
}

@media (max-width: 768px) {
  .profile-header {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .profile-photo {
    width: 8rem;
    height: 8rem;
    margin: 0 auto;
  }

  .info-items {
    justify-content: center;
  }

  .profile-actions {
    justify-content: center;
  }

  .bottom-nav {
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 0.75rem;
    box-shadow: 0 -2px 4px rgba(0,0,0,0.1);
    z-index: 50;
  }

  .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #6b7280;
    font-size: 0.75rem;
    padding: 0.5rem;
    border: none;
    background: none;
    cursor: pointer;
  }

  .nav-item.active {
    color: #E2384D;
  }

  .nav-icon {
    font-size: 1.25rem;
    margin-bottom: 0.25rem;
  }

  .tour-grid {
    grid-template-columns: 1fr;
  }

  .date-filters {
    margin: 0 -1.5rem;
    padding: 0 1.5rem 0.5rem;
  }

  .profile-main {
    padding-bottom: 4rem;
  }
}