.section {
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 2rem;
    margin-bottom: 2rem;
  }
  
  .section:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  
  .section h3 {
    font-size: 1.25rem;
    color: var(--text-primary);
    margin: 0 0 1.5rem 0;
  }
  
  .buttonContainer {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .actionButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1.5rem;
    background-color: var(--bg-secondary);
    border: 1px solid var(--border-color);
    border-radius: 6px;
    color: var(--text-primary);
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .actionButton:hover {
    background-color: var(--border-color);
  }
  
  .actsList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .actCard {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    background-color: var(--bg-secondary);
    border-radius: 8px;
    position: relative;
  }
  
  .actPhoto {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .actInfo {
    flex: 1;
  }
  
  .actInfo h4 {
    font-size: 1rem;
    color: var(--text-primary);
    margin: 0 0 0.25rem 0;
  }
  
  .actInfo p {
    font-size: 0.875rem;
    color: var(--text-secondary);
    margin: 0 0 0.25rem 0;
  }
  
  .subtype,
  .genre {
    color: var(--text-primary) !important;
  }
  
  .removeButton {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: var(--bg-primary);
    border: none;
    color: var(--text-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .removeButton:hover {
    background-color: var(--border-color);
    color: #E2384D;
  }
  
  /* Dark Mode Support */
  :root[class="dark"] .actCard {
    background-color: var(--bg-primary);
  }
  
  :root[class="dark"] .removeButton {
    background-color: var(--bg-secondary);
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .buttonContainer {
      flex-direction: column;
    }
  
    .actionButton {
      width: 100%;
      justify-content: center;
    }
  
    .actCard {
      padding: 0.75rem;
    }
  
    .actPhoto {
      width: 40px;
      height: 40px;
    }
  }