.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: opacity 0.3s ease;
}

.visible {
  opacity: 1;
}

.hidden {
  opacity: 0;
}

.popup {
  background-color: var(--bg-primary, white);
  border-radius: 16px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 480px;
  max-height: 90vh;
  position: relative;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  display: flex;
  flex-direction: column;
  color: var(--text-primary, #333);
}

.popupContent {
  padding: 32px;
  overflow-y: auto;
  text-align: center;
  scrollbar-width: thin;
  scrollbar-color: var(--text-secondary, #999) transparent;
}

.popupContent::-webkit-scrollbar {
  width: 6px;
}

.popupContent::-webkit-scrollbar-track {
  background: transparent;
}

.popupContent::-webkit-scrollbar-thumb {
  background-color: var(--text-secondary, #999);
  border-radius: 6px;
}

.animateIn {
  transform: scale(1);
  opacity: 1;
}

.animateOut {
  transform: scale(0.8);
  opacity: 0;
}

.closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 18px;
  color: var(--text-secondary, #999);
  cursor: pointer;
  transition: color 0.2s;
  z-index: 10;
}

.closeButton:hover {
  color: var(--text-primary, #555);
}

.successIcon {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto 20px;
}

.checkIcon {
  font-size: 80px;
  color: #4CAF50;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 15px rgba(76, 175, 80, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
  }
}

.confetti {
  position: absolute;
  top: -20px;
  left: -20px;
  right: -20px;
  bottom: -20px;
  z-index: -1;
  background-image: 
    radial-gradient(circle, #ff0000 2px, transparent 2px),
    radial-gradient(circle, #00ff00 2px, transparent 2px),
    radial-gradient(circle, #0000ff 2px, transparent 2px),
    radial-gradient(circle, #ffff00 2px, transparent 2px),
    radial-gradient(circle, #ff00ff 2px, transparent 2px);
  background-size: 10% 10%, 20% 20%, 15% 15%, 18% 18%, 12% 12%;
  animation: confettiAnimation 3s ease-in-out infinite;
}

@keyframes confettiAnimation {
  0% {
    background-position: 0% 0%, 20% 20%, 30% 30%, 40% 40%, 50% 50%;
    opacity: 1;
  }
  50% {
    background-position: 5% 5%, 25% 25%, 35% 35%, 45% 45%, 55% 55%;
    opacity: 0.5;
  }
  100% {
    background-position: 10% 10%, 30% 30%, 40% 40%, 50% 50%, 60% 60%;
    opacity: 1;
  }
}

.title {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 12px;
  color: var(--text-primary, #333);
}

.message {
  font-size: 18px;
  color: var(--text-secondary, #666);
  margin-bottom: 25px;
  line-height: 1.5;
}

.tierName {
  font-weight: 700;
  color: #E2384D;
}

.benefitsContainer {
  background-color: rgba(226, 56, 77, 0.05);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 25px;
  text-align: left;
  border: 1px solid rgba(226, 56, 77, 0.1);
}

/* Dark mode adjustments */
:global(.dark) .benefitsContainer {
  background-color: rgba(226, 56, 77, 0.08);
  border-color: rgba(226, 56, 77, 0.15);
}

.benefitsTitle {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-bottom: 15px;
  color: var(--text-primary, #333);
}

.giftIcon {
  color: #E2384D;
  margin-right: 10px;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.benefitsList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.benefitItem {
  padding: 8px 0;
  display: flex;
  align-items: center;
  animation: fadeIn 0.5s ease forwards;
  opacity: 0;
  color: var(--text-primary, #333);
}

.benefitItem:nth-child(1) { animation-delay: 0.1s; }
.benefitItem:nth-child(2) { animation-delay: 0.2s; }
.benefitItem:nth-child(3) { animation-delay: 0.3s; }
.benefitItem:nth-child(4) { animation-delay: 0.4s; }
.benefitItem:nth-child(5) { animation-delay: 0.5s; }
.benefitItem:nth-child(6) { animation-delay: 0.6s; }
.benefitItem:nth-child(7) { animation-delay: 0.7s; }
.benefitItem:nth-child(8) { animation-delay: 0.8s; }
.benefitItem:nth-child(9) { animation-delay: 0.9s; }

@keyframes fadeIn {
  from { 
    opacity: 0;
    transform: translateY(10px);
  }
  to { 
    opacity: 1;
    transform: translateY(0);
  }
}

.checkMark {
  color: #4CAF50;
  font-weight: bold;
  margin-right: 10px;
  font-size: 16px;
}

.exploreButton {
  background-color: #E2384D;
  color: white;
  border: none;
  border-radius: 50px;
  padding: 15px 30px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 4px 15px rgba(226, 56, 77, 0.3);
  margin-bottom: 10px;
}

.exploreButton:hover {
  background-color: #c61c3e;
  transform: translateY(-2px);
  box-shadow: 0 6px 18px rgba(226, 56, 77, 0.4);
}

.exploreButton:active {
  transform: translateY(0);
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .popup {
    width: 85%;
  }
  
  .popupContent {
    padding: 24px;
  }
  
  .title {
    font-size: 24px;
  }
  
  .message {
    font-size: 16px;
  }
  
  .benefitsTitle {
    font-size: 16px;
  }
  
  .exploreButton {
    padding: 12px 25px;
    font-size: 14px;
  }
}