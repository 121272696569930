.mainLayout {
    display: flex;
    min-height: 100vh;
    padding-top: 3.5rem;
    background-color: var(--page-bg);
  }
  
  .profileSettingsMain {
    flex: 1;
    margin-left: 240px;
    margin-right: 270px;
    min-width: 0;
    height: calc(100vh - 3.5rem);
    overflow-y: auto;
  }
  
  .profileSettingsContent {
    padding: 2rem;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .profileSettingsHeader {
    margin-bottom: 2rem;
  }
  
  .profileSettingsTitle {
    font-size: 24px;
    font-weight: bold;
    color: var(--text-primary);
    text-align: center;
  }
  
  .menuOptions {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .menuItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.25rem;
    background-color: var(--bg-primary);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    color: var(--text-primary);
    text-decoration: none;
    transition: all 0.2s ease;
    cursor: pointer;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .menuItem:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  .menuItemContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    margin-right: 1rem;
  }
  
  .menuItemText {
    font-size: 1rem;
    font-weight: 500;
  }
  
  .menuItemValue {
    font-size: 1rem;
    font-weight: 500;
  }
  
  .menuItemIcon {
    color: var(--text-secondary);
    width: 20px;
    height: 20px;
  }
  
  .versionInfo {
    margin-top: 2rem;
    text-align: center;
  }
  
  .versionText {
    color: var(--text-secondary);
    font-size: 0.875rem;
  }
  
  .loadingOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    backdrop-filter: blur(4px);
  }
  
  .loadingSpinner {
    width: 40px;
    height: 40px;
    border: 3px solid #fff;
    border-radius: 50%;
    border-top-color: #E2384D;
    animation: spin 0.8s linear infinite;
  }
  
  .loadingText {
    color: white;
    font-size: 1.25rem;
    margin-top: 1rem;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Dark mode styles */
  :root[class="dark"] .menuItem {
    background-color: var(--bg-secondary);
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .profileSettingsMain {
      margin: 0;
      padding: 1rem;
      padding-bottom: 5rem;
    }
  
    .profileSettingsContent {
      padding: 1rem;
    }
  
    .menuItem {
      padding: 0.875rem 1rem;
    }
  }