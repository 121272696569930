/* src/components/ShareModal/ShareModal.css */
.share-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  margin-top: 50px;
}

.share-modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.share-modal-content {
  background: var(--bg-primary);
  border-radius: 1rem;
  padding: 1.5rem;
  width: 95%;
  max-width: 500px;
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.share-modal-title {
  font-size: 1.25rem;
  color: var(--text-primary);
  margin: 0;
}

.share-modal-close {
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 0.5rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.share-modal-close:hover {
  background-color: var(--bg-secondary);
}

.share-url-container {
  margin-bottom: 1.5rem;
}

/* ShareModal.css updates */
.share-url-input-container {
  display: flex;
  align-items: center;
  background: var(--bg-secondary);
  border-radius: 0.5rem;
  padding: 0.5rem;
  gap: 0.5rem;
  
}

.url-wrapper {
  flex: 1;
  min-width: 0;
  padding-right: 2.5rem;
  margin-top: 15px;
}

.share-url-input {
  width: 100%;
  border: none;
  background: none;
  color: var(--text-primary);
  padding: 0.5rem;
  font-size: 0.875rem;
  outline: none;
  text-overflow: ellipsis;
}

.copy-button {
  white-space: nowrap;
  background: var(--bg-primary);
  border: none;
  color: var(--text-primary);
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  flex-shrink: 0;
  width: auto;
  min-width: 85px;
}

.copy-button:hover {
  background: var(--text-primary);
  color: var(--bg-primary);
}

.copy-button.copied {
  background: #10b981;
  color: white;
}

.share-preview {
  background: var(--bg-secondary);
  border-radius: 0.75rem;
  margin-bottom: 1.5rem;
  overflow: hidden;
}

.preview-header {
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-secondary);
  font-size: 0.875rem;
  border-bottom: 1px solid var(--border-color);
}

.preview-type-icon {
  opacity: 0.7;
}

.preview-content {
  padding: 1rem;
}

.preview-caption {
  color: var(--text-primary);
  font-size: 0.875rem;
  line-height: 1.5;
  margin: 0 0 0.75rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.preview-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.preview-tag {
  font-size: 0.75rem;
  color: var(--text-secondary);
  background: var(--bg-primary);
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
}

.native-share-button {
  width: 100%;
  padding: 0.75rem;
  background: var(--bg-secondary);
  border: none;
  border-radius: 0.5rem;
  color: var(--text-primary);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  transition: all 0.2s ease;
}

.native-share-button:hover {
  background: var(--text-primary);
  color: var(--bg-primary);
}

.share-divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 1.5rem 0;
}

.share-divider::before,
.share-divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid var(--border-color);
}

.share-divider span {
  padding: 0 0.5rem;
  color: var(--text-secondary);
  font-size: 0.875rem;
}

.social-share-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.social-share-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  background: var(--bg-secondary);
  border: none;
  border-radius: 0.5rem;
  color: var(--text-primary);
  cursor: pointer;
  transition: all 0.2s ease;
}

.social-share-button:hover {
  background: var(--platform-color);
  color: white;
}

.social-share-button svg {
  font-size: 1.5rem;
}

.social-share-button span {
  font-size: 0.75rem;
}

/* Dark mode styles */
:root[class="dark"] .share-modal-content {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

 
  
  /* Mobile adjustments */
  @media (max-width: 480px) {
    .share-modal-wrapper {
      align-items: flex-end;
    }
  
    .share-modal-overlay {
      align-items: flex-end;
    }
  
    .share-modal-content {
      width: 100%;
      max-width: 100%;
      margin: 0;
      border-radius: 1rem 1rem 0 0;
      max-height: 85vh;
      padding: 1rem;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch; /* For smooth scrolling on iOS */
    }
  
    /* Add padding at the bottom to ensure last elements are visible */
    .social-share-grid {
      margin-bottom: 6rem; /* Safe area for bottom of screen */
    }
  
  
    .modal-header {
      padding-bottom: 1rem;
    }
  
    .share-url-input-container {
      flex-direction: column;
      gap: 0.75rem;
    }
  
    .url-wrapper {
      width: 100%;
      border-right: none;
      border-bottom: 1px solid var(--border-color);
      padding-right: 0;
      padding-bottom: 0.5rem;
    }
  
    .copy-button {
      width: 100%;
      justify-content: center;
      padding: 0.75rem;
    }
  
    .social-share-grid {
      grid-template-columns: repeat(3, 1fr);
      gap: 0.75rem;
    }
  
    .social-share-button {
      padding: 0.75rem;
    }
  
    .social-share-button span {
      display: none;
    }
  
    .social-share-button svg {
      font-size: 1.25rem;
    }
  
    .share-preview {
      margin: -1rem -1rem 1rem -1rem;
      border-radius: 1rem 1rem 0 0;
    }
  
    .preview-content {
      padding: 0.75rem 1rem;
    }
  
    .preview-caption {
      -webkit-line-clamp: 3;
    }
  
    .native-share-button {
      margin: 1rem 0;
    }
  
  
  /* iPhone SE and smaller devices */
  @media (max-width: 375px) {
    .social-share-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  
    .share-modal-content {
      padding: 0.75rem;
    }
  }
}