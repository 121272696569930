.mainLayout {
  display: flex;
  min-height: 100vh;
  padding-top: 3.5rem;
  background-color: var(--page-bg);
}

.accountDetailsMain {
  flex: 1;
  margin-left: 240px;
  margin-right: 270px;
  min-width: 0;
  height: calc(100vh - 3.5rem);
  overflow-y: auto;
}

.accountDetailsContent {
  padding: 2rem;
  max-width: 100%;
  margin: 0 auto;
}

.accountDetailsHeader {
  margin-bottom: 2rem;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: var(--text-primary);
  text-align: center;
}

.formContainer {
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 2rem;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.field {
  margin-bottom: 1.5rem;
}

.field label {
  display: block;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: var(--text-primary);
}

.inputGroup {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.inputGroup .input {
  flex: 1;
}

.input {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 1rem;
  color: var(--text-primary);
  background-color: var(--bg-primary);
}

.input:focus {
  outline: none;
  border-color: #E2384D;
}

.readonly {
  background-color: var(--bg-secondary);
  cursor: not-allowed;
}

.updateButton {
  max-width: 100px;
  padding: 0.75rem 1.5rem;
  background-color: #E2384D;
  color: white;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  flex-shrink: 0;
}

.updateButton:hover {
  background-color: #cc2d43;
}

.updateButton:disabled {
  background-color: var(--text-secondary);
  cursor: not-allowed;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.checkbox {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
  cursor: pointer;
}

.checkbox:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.checkboxLabel {
  font-size: 0.875rem;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.eliteTag {
  background-color: #E2384D;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 500;
}

.resetButton {
  background-color: #E2384D;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.resetButton:hover {
  background-color: #cc2d43;
}

.managersSection {
  margin-top: 1.5rem;
  padding: 1.5rem;
  background-color: var(--bg-secondary);
  border-radius: 8px;
}

.searchManagerButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  padding: 0.75rem;
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 6px;
  color: var(--text-primary);
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 1rem;
}

.searchManagerButton:hover {
  background-color: var(--bg-secondary);
}

.selectedManagersGrid {
  display: grid;
  gap: 1rem;
  margin-top: 1rem;
}

.managerCard {
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  overflow: hidden;
}

.managerContent {
  display: flex;
  padding: 1rem;
  gap: 1rem;
  position: relative;
}

.profilePhoto {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
}

.managerInfo {
  flex: 1;
}

.nameContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.nameContainer h3 {
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

.verifiedBadge {
  width: 16px;
  height: 16px;
}

.username {
  font-size: 0.875rem;
  color: var(--text-secondary);
  margin: 0.25rem 0;
}

.subtype {
  font-size: 0.875rem;
  color: var(--text-primary);
  margin: 0.25rem 0;
}

.location {
  font-size: 0.75rem;
  color: var(--text-secondary);
  margin: 0.25rem 0;
}

.removeButton {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 0.25rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 40px;
}

.removeButton:hover {
  background-color: var(--bg-secondary);
  color: #E2384D;
}

/* Modal Styles */

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.modal {
  background-color: var(--bg-primary);
  border-radius: 12px;
  padding: 1.5rem;
  width: 90%;
  max-width: 500px;
  max-height: 80vh;
  overflow-y: auto;
  border: 1px solid var(--border-color);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
}


.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.modalHeader h2 {
  margin: 0;
  font-size: 1.25rem;
  color: var(--text-primary);
}

.searchBar {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
  align-items: center;
}

.searchBar input {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 1rem;
  color: var(--text-primary);
  background-color: var(--bg-primary);
}

.searchButton {
  padding: 0.75rem 1rem;
  max-width: 120px;
  background-color: #E2384D;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.searchButton:hover {
  background-color: #cc2d43;
}

.searchButton:disabled {
  background-color: var(--text-secondary);
  cursor: not-allowed;
}

.searchResults {
  max-height: 400px;
  overflow-y: auto;
  margin-top: 1rem;
}

.resultCard {
  display: flex;
  padding: 1rem;
  gap: 1rem;
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.resultCard:hover {
  background-color: var(--bg-secondary);
}

.resultPhoto {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
}

.resultInfo {
  flex: 1;
}

.resultName {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.25rem;
}

.resultName span {
  font-weight: 600;
  color: var(--text-primary);
}

.resultUsername {
  display: block;
  font-size: 0.875rem;
  color: var(--text-secondary);
  margin-bottom: 0.25rem;
}

.resultSubtype {
  display: block;
  font-size: 0.875rem;
  color: var(--text-primary);
  margin-bottom: 0.25rem;
}

.resultLocation {
  display: block;
  font-size: 0.75rem;
  color: var(--text-secondary);
}



.spinner {
  width: 40px;
  height: 40px;
  border: 3px solid var(--border-color);
  border-radius: 50%;
  border-top-color: #E2384D;
  animation: spin 0.8s linear infinite;
  margin-bottom: 1rem;
}

.noResults {
  text-align: center;
  padding: 2rem;
  color: var(--text-secondary);
  font-size: 0.875rem;
}

.searchPrompt {
  text-align: center;
  padding: 2rem;
  color: var(--text-secondary);
  font-size: 0.875rem;
}

.eliteTag {
  background-color: #E2384D;
  color: white;
  padding: 0.125rem 0.375rem;
  border-radius: 4px;
  font-size: 0.75rem;
  margin-left: 0.5rem;
  font-weight: 500;
}

.datePickerContainer {
  position: relative;
}

.calendarIcon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-secondary);
  pointer-events: none;
}

.moreSection {
  margin-top: 2rem;
  border-top: 1px solid var(--border-color);
  padding-top: 1rem;
}

.moreButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
  background: none;
  border: none;
  color: var(--text-primary);
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
}

.moreButton:hover {
  background-color: var(--bg-secondary);
  border-radius: 6px;
}

.deleteButton {
  width: 100%;
  padding: 0.75rem;
  background-color: #ef4444;
  color: white;
  border: none;
  border-radius: 6px;
  margin-top: 1rem;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.deleteButton:hover {
  background-color: #dc2626;
}

.loading {
  text-align: center;
  padding: 2rem;
  color: var(--text-secondary);
}

.closeButton {
  max-width: 40px;
  padding: 0.3rem;
  background-color: var(--bg-secondary);
  border-radius: 5px;
  color: var(--text-primary);
  margin-top: 1rem;
  cursor: pointer;
  font-weight: 500;
}

.closeButton:hover {
  background-color: var(--border-color);
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.delete {
  background-color: #dc3545;
}

.alertButton.delete:hover {
  background-color: #c82333;
}

/* Dark mode specific styles */
:root[class="dark"] .modal {
  background-color: var(--bg-secondary);
}

:root[class="dark"] .managerResult:hover {
  background-color: var(--bg-primary);
}
/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .managersSection {
    padding: 1rem;
  }

  .searchManagerButton {
    padding: 0.625rem;
  }

  .managerContent {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .removeButton {
    position: static;
    margin-top: 0.5rem;
  }

  .modal {
    width: 95%;
    margin: 1rem;
    max-height: 90vh;
  }

  .managerTag {
    width: 100%;
  }

  .searchContainer {
    flex-direction: column;
  }

  .searchBar {
    flex-direction: column;
  }

  .searchButton {
    width: 100%;
  }

  .resultCard {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0.75rem;
  }

  .resultInfo {
    width: 100%;
  }

  .resultName {
    justify-content: center;
  }
}

/* Dark Mode Specific Styles */
:root[class="dark"] .managersSection {
  background-color: var(--bg-secondary);
}

:root[class="dark"] .managerCard {
  background-color: var(--bg-secondary);
}

:root[class="dark"] .resultCard:hover {
  background-color: var(--bg-primary);
}

/* Animation */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Error States */
.errorState {
  color: #ef4444;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

/* Success States */
.successState {
  color: #10b981;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}



/* List Transitions */
.managerList-enter {
  opacity: 0;
  transform: translateY(10px);
}

.managerList-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.managerList-exit {
  opacity: 1;
}

.managerList-exit-active {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 300ms, transform 300ms;
}

/* Hover Effects */
.managerCard:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

.removeButton:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--bg-primary), 0 0 0 4px #E2384D;
}

/* Accessibility */
.searchBar input:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--bg-primary), 0 0 0 4px #E2384D;
}

.searchManagerButton:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--bg-primary), 0 0 0 4px #E2384D;
}

/* Empty States */
.emptyState {
  text-align: center;
  padding: 2rem;
  color: var(--text-secondary);
  font-size: 0.875rem;
  background-color: var(--bg-secondary);
  border-radius: 8px;
  margin-top: 1rem;
}

.emptyState svg {
  width: 48px;
  height: 48px;
  color: var(--text-secondary);
  margin-bottom: 1rem;
}

/* Tooltip */
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border-radius: 4px;
  font-size: 0.75rem;
  white-space: nowrap;
  z-index: 1000;
}

.saveButton {
  width: 100%;
  padding: 1rem;
  background-color: #E2384D;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 1rem;
}

.saveButton:hover {
  background-color: #cc2d43;
}

.saveButton:disabled {
  background-color: var(--text-secondary);
  cursor: not-allowed;
}

/* Alert Modal Styles */
.alertModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.alertModal {
  background-color: var(--bg-primary);
  border-radius: 12px;
  padding: 24px;
  width: min(400px, 90%);
  border: 1px solid var(--border-color);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
}

.alertHeader {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.alertIcon {
  color: #E2384D;
  font-size: 24px;
}

.alertTitle {
  color: var(--text-primary);
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.alertMessage {
  color: var(--text-secondary);
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 1.5;
}

.alertActions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.alertButton {
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.alertButton.cancel {
  background: var(--bg-secondary);
  border: 1px solid var(--border-color);
  color: var(--text-primary);
}

.alertButton.confirm {
  background: #E2384D;
  border: none;
  color: white;
}

.alertButton:hover {
  transform: translateY(-1px);
}

.alertButton.cancel:hover {
  background: var(--border-color);
}

.alertButton.confirm:hover {
  background: #cc2d43;
}

.alertButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
}

/* Loading Overlay */
.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.loadingSpinner {
  width: 40px;
  height: 40px;
  border: 3px solid #fff;
  border-radius: 50%;
  border-top-color: #E2384D;
  animation: spin 0.8s linear infinite;
}

.loadingText {
  color: white;
  font-size: 1.25rem;
  margin-top: 1rem;
}

.helperText {
  font-size: 0.875rem;
  color: var(--text-secondary);
  margin-top: 0.5rem;
}

.readonly {
  background-color: var(--bg-secondary);
  cursor: not-allowed;
}

/* Select styles */
.select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 1rem;
  color: var(--text-primary);
  background-color: var(--bg-primary);
  cursor: pointer;
}

.select:focus {
  outline: none;
  border-color: #E2384D;
}

/* Dark mode specific styles */
:root[class="dark"] .formContainer {
  background-color: var(--bg-secondary);
}

:root[class="dark"] .input,
:root[class="dark"] .select {
  background-color: var(--bg-secondary);
  border-color: var(--border-color);
}

:root[class="dark"] .readonly {
  background-color: var(--bg-primary);
}

:root[class="dark"] .alertModal {
  background-color: var(--bg-secondary);
}

/* Responsive styles */
@media (max-width: 768px) {
  .accountDetailsMain {
    margin: 0;
    padding: 1rem;
  }

  .accountDetailsContent {
    padding: 1rem;
  }

  .formContainer {
    padding: 1rem;
  }

  .inputGroup {
    flex-direction: column;
  }

  .updateButton {
    width: 100%;
    min-width: unset;
  }

  .alertModal {
    width: 90%;
    margin: 0 1rem;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Error and success states */
.error {
  border-color: #ef4444;
}

.errorMessage {
  color: #ef4444;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.successMessage {
  color: #10b981;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}