.dialog-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    animation: fadeIn 0.2s ease;
  }
  
  .dialog-container {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1001;
    padding: 20px;
  }
  
  .dialog-content {
    background-color: var(--bg-primary);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
    max-height: 85vh;
    overflow-y: auto;
    position: relative;
    animation: dialogAppear 0.3s ease;
  }
  
  .dialog-content-wrapper {
    padding: 24px;
  }
  
  .dialog-header {
    margin-bottom: 20px;
  }
  
  .dialog-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--text-primary);
    margin: 0;
  }
  
  .dialog-close {
    position: absolute;
    top: 16px;
    right: 16px;
    background: transparent;
    border: none;
    color: var(--text-secondary);
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
  }
  
  .dialog-close:hover {
    background-color: var(--bg-hover);
    color: var(--text-primary);
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes dialogAppear {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  /* Dark mode support */
  @media (prefers-color-scheme: dark) {
    .dialog-content {
      background-color: var(--bg-primary);
    }
    
    .dialog-close {
      color: var(--text-secondary);
    }
    
    .dialog-close:hover {
      background-color: var(--bg-hover);
      color: var(--text-primary);
    }
  }