.hotel-container {
  padding: 20px;
}

.add-hotel-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  background-color: var(--bg-primary);
  border: 1px solid #E2384D;
  border-radius: 8px;
  color: #E2384D;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.add-hotel-button:hover {
  background-color: var(--bg-hover);
}

.add-hotel-button svg {
  margin-right: 8px;
}

.hotel-card {
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin-bottom: 20px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.group-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #E2384D;
  padding: 12px 16px;
  color: white;
}

.group-info {
  flex: 1;
}

.group-name {
  font-weight: 600;
  margin-bottom: 4px;
}

.visibility-text {
  font-size: 14px;
  opacity: 0.9;
}

.group-actions {
  display: flex;
  gap: 12px;
}

.action-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  transition: background-color 0.2s;
  color: var(--text-primary);
}

.group-actions .action-button {
  color: white;
}

.action-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.hotel-content {
  padding: 16px;
}

.hotel-header {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.hotel-image {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  object-fit: cover;
}

.hotel-time-details {
  display: flex;
  flex: 1;
  justify-content: space-around;
}

.time-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.time-label {
  font-size: 14px;
  color: var(--text-secondary);
  margin-bottom: 4px;
}

.time-date {
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 2px;
}

.time-hour {
  font-size: 14px;
  color: var(--text-secondary);
}

.room-count {
  font-size: 24px;
  font-weight: 600;
  color: #E2384D;
}

.hotel-info {
  margin-bottom: 16px;
}

.hotel-name {
  font-size: 18px;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 4px;
}

.hotel-address {
  color: var(--text-secondary);
  font-size: 14px;
}

.hotel-actions {
  display: flex;
  gap: 8px;
}

.hotel-actions .action-button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  color: var(--text-primary);
  padding: 8px 16px;
  transition: all 0.2s ease;
}

.hotel-actions .action-button:hover {
  background-color: var(--bg-hover);
}

.hotel-actions .action-button svg {
  color: #E2384D;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 0;
  color: var(--text-secondary);
  text-align: center;
}

.empty-state-icon {
  font-size: 50px;
  color: #E2384D;
  margin-bottom: 20px;
}

.empty-text {
  font-size: 16px;
  color: var(--text-secondary);
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 0;
}

.loading-spinner {
  font-size: 24px;
  color: #E2384D;
  margin-bottom: 16px;
}

.loading-text {
  color: var(--text-secondary);
}

/* Loading Overlay */
.loading-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.overlay-text {
  color: white;
  margin-top: 1rem;
  font-size: 1rem;
}