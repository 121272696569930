/* FileViewerModal.module.css */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: var(--bg-primary);
  border-radius: 12px;
  position: relative;
  width: 90%;
  max-width: 1200px;
  height: 90vh;
  padding: 20px;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: var(--text-secondary);
  z-index: 1;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.closeButton:hover {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
}

/* PDF Container */
.pdfContainer {
  width: 100%;
  height: 100%;
  position: relative;
}

.pdfFrame {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 8px;
}

/* Image Container */
.imageContainer {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageFrame {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 8px;
}

/* Preview Container for other file types */
.previewContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.previewCard {
  background-color: var(--bg-secondary);
  border-radius: 12px;
  padding: 32px;
  text-align: center;
  max-width: 400px;
}

.fileName {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0 0 8px;
  word-break: break-word;
}

.fileType {
  font-size: 1rem;
  color: var(--text-secondary);
  margin: 0 0 16px;
}

.fileSize {
  font-size: 0.875rem;
  color: var(--text-secondary);
  margin: 0 0 24px;
}

.downloadButton {
  display: inline-block;
  padding: 12px 24px;
  background-color: #E2384D;
  color: white;
  text-decoration: none;
  border-radius: 8px;
  font-weight: 500;
  transition: background-color 0.2s;
}

.downloadButton:hover {
  background-color: #cc2b3d;
}

/* Loading spinner styles */
.loaderContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-primary);
  z-index: 1;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 4px solid var(--border-color);
  border-top: 4px solid #E2384D;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 16px;
}

.loadingText {
  color: var(--text-secondary);
  font-size: 16px;
  font-weight: 500;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Dark mode support */
:root[class="dark"] .previewCard {
  background-color: var(--bg-secondary);
}

/* Responsive styles */
@media (max-width: 768px) {
  .modalContent {
    width: 95%;
    height: 95vh;
    padding: 16px;
  }

  .previewCard {
    padding: 24px;
    width: 90%;
  }
}