/* Theme variables */
:root {
    --bg-primary: #ffffff;
    --bg-secondary: #f3f4f6;
    --text-primary: #111827;
    --text-secondary: #6b7280;
    --border-color: #e5e7eb;
    --page-bg: #f5f7fb;
  }
  
  :root[class="dark"] {
    --bg-primary: #1f2937;
    --bg-secondary: #111827;
    --text-primary: #ffffff;
    --text-secondary: #9ca3af;
    --border-color: #374151;
    --page-bg: #111827;
  }
  
  .container {
    width: 100%;
    min-height: 100vh;
    background-color: var(--page-bg);
  }
  
  .main-layout {
    display: flex;
    min-height: calc(100vh - 3.5rem);
    margin-top: 3.5rem;
  }
  
  .membership-content {
    flex: 1;
    margin-left: 240px;
    margin-right: 270px;
    padding: 2rem;
    background-color: var(--page-bg);
  }
  
  .membership-header {
    margin-bottom: 2rem;
  }
  
  .membership-header h1 {
    font-size: 2rem;
    font-weight: 600;
    color: var(--text-primary);
    margin-bottom: 1rem;
  }
  
  .current-plan-alert {
    background-color: var(--bg-primary);
    border: 1px solid var(--border-color);
    border-radius: 0.5rem;
    padding: 1rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .alert-content {
    color: var(--text-primary);
    font-size: 0.95rem;
  }
  
  .highlight {
    color: #E2384D;
    font-weight: 600;
    margin: 0 0.5rem;
  }
  
  .end-date {
    margin-left: 1rem;
    color: var(--text-secondary);
    font-size: 0.9rem;
  }
  
  .plans-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    padding: 1rem 0;
    max-width: 100%;
    overflow-x: auto;
  }
  
  .plan-card {
    background: var(--bg-primary);
    border-radius: 1rem;
    transition: all 0.2s ease;
    border: 2px solid transparent;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    min-width: 280px;
  }
  
  .plan-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  }
  
  .plan-card.current {
    border-color: #E2384D;
  }
  
  .plan-header {
    padding: 1.5rem;
    border-bottom: 1px solid var(--border-color);
  }
  
  .plan-name {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text-primary);
    margin-bottom: 0.5rem;
  }
  
  .plan-description {
    color: var(--text-primary);
    font-size: 0.9rem;
    margin-bottom: 1.5rem;
  }
  
  .plan-price {
    display: flex;
    align-items: baseline;
    gap: 0.25rem;
  }
  
  .amount {
    font-size: 2rem;
    font-weight: 700;
    color: var(--text-primary);
  }
  
  .period {
    color: var(--text-secondary);
  }
  
  .plan-content {
    padding: 1.5rem;
  }
  
  .feature-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .feature-item {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: var(--text-primary);
    font-size: 0.95rem;
  }
  
  .included {
    color: #10b981;
    font-size: 1rem;
  }
  
  .excluded {
    color: #ef4444;
    font-size: 1rem;
  }
  
  .upgrade-button {
    width: 100%;
    margin-top: 1.5rem;
    background: #E2384D;
    color: white;
    padding: 0.75rem;
    border-radius: 0.5rem;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 1rem;
  }
  
  .upgrade-button:hover {
    background: #cb1d32;
  }
  
  /* Bottom Navigation */
  .bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--bg-primary);
    padding: 0.75rem;
    display: none;
    justify-content: space-around;
    border-top: 1px solid var(--border-color);
    z-index: 50;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
    color: var(--text-secondary);
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    font-size: 0.75rem;
  }
  
  .nav-item.active {
    color: #E2384D;
  }
  
  /* Animation for Plan Cards */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .plan-card {
    animation: fadeInUp 0.3s ease-out;
    animation-fill-mode: both;
  }
  
  .plan-card:nth-child(1) { animation-delay: 0.1s; }
  .plan-card:nth-child(2) { animation-delay: 0.2s; }
  .plan-card:nth-child(3) { animation-delay: 0.3s; }
  .plan-card:nth-child(4) { animation-delay: 0.4s; }
  
  /* Hover Effects */
  .upgrade-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(226, 56, 77, 0.3);
  }
  
  .feature-item:hover {
    background-color: rgba(226, 56, 77, 0.05);
    border-radius: 0.25rem;
    padding: 0.25rem;
    margin: -0.25rem;
  }
  

  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 3px solid var(--bg-primary);
    border-top: 3px solid #E2384D;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  .error-message {
    background-color: #FEE2E2;
    border: 1px solid #EF4444;
    color: #B91C1C;
    padding: 1rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .success-message {
    background-color: #D1FAE5;
    border: 1px solid #10B981;
    color: #047857;
    padding: 1rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .button-loading {
    position: relative;
    cursor: not-allowed;
    opacity: 0.7;
  }
  
  .button-loading::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    top: calc(50% - 10px);
    right: 1rem;
    border: 2px solid white;
    border-top: 2px solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  .tab-container {
    border-bottom: 1px solid var(--border-color);
    margin-bottom: 2rem;
  }
  
  .tabs {
    display: flex;
    gap: 2rem;
  }
  
  .tab {
    padding: 1rem 0;
    color: var(--text-secondary);
    border-bottom: 2px solid transparent;
    cursor: pointer;
  }
  
  .tab.active {
    color: #E2384D;
    border-bottom-color: #E2384D;
  }
  
  .history-card {
    background: var(--bg-primary);
    border-radius: 0.5rem;
    padding: 1.5rem;
    margin-bottom: 1rem;
    border: 1px solid var(--border-color);
  }
  
  .history-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .history-date {
    font-size: 0.875rem;
    color: var(--text-secondary);
  }
  
  .history-amount {
    font-weight: 600;
    color: var(--text-primary);
  }
  
  .history-status {
    padding: 0.25rem 0.75rem;
    border-radius: 1rem;
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--text-primary);
    justify-content: center;
  }
  
  .status-completed {
    background-color: #D1FAE5;
    color: #047857;
  }
  
  .status-cancelled {
    background-color: #FEE2E2;
    color: #B91C1C;
  }
  
  .history-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid var(--border-color);
  }
  
  .detail-item {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .detail-label {
    font-size: 0.875rem;
    color: var(--text-secondary);
    
  }
  
  .detail-value {
    color: var(--text-primary);
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @media (max-width: 768px) {
    .history-details {
      grid-template-columns: 1fr;
    }
  }

  .history-container {
    margin-top: 2rem;
  }
  
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
  }
  
  .loading-spinner {
    font-size: 2rem;
    color: #E2384D;
  }
  
  .empty-state {
    text-align: center;
    padding: 3rem;
    color: var(--text-secondary);
  }
  
  .history-card {
    background: var(--bg-primary);
    border: 1px solid var(--border-color);
    border-radius: 0.75rem;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  .history-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
  }
  
  .history-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--text-primary);
    margin: 0;
  }
  
  .history-date {
    display: block;
    font-size: 0.875rem;
    color: var(--text-secondary);
    margin-top: 0.25rem;
  }
  
  .history-amount {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text-primary);
  }
  
  .history-period {
    font-size: 0.875rem;
    color: var(--text-secondary);
    margin-left: 0.25rem;
  }
  
  .history-status {
    margin-bottom: 1rem;
  }
  
  .status-badge {
    display: inline-block;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  .status-completed {
    background-color: #D1FAE5;
    color: #047857;
  }
  
  .status-cancelled {
    background-color: #FEE2E2;
    color: #B91C1C;
  }
  
  .status-pending {
    background-color: #FEF3C7;
    color: #92400E;
  }
  
  .history-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid var(--border-color);
  }
  
  .detail-item {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .detail-label {
    font-size: 0.875rem;
    color: var(--text-secondary);
  }
  
  .detail-value {
    color: var(--text-primary);
    font-weight: 500;
  }
  
  .invoice-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
    margin-top: 1.5rem;
    padding: 0.75rem;
    background-color: transparent;
    border: 1px solid var(--border-color);
    border-radius: 0.5rem;
    color: var(--text-primary);
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .invoice-button:hover {
    background-color: var(--bg-secondary);
  }
  
  .button-icon {
    font-size: 1rem;
  }

  .trial-button {
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
    background: transparent;
    color: #E2384D;
    padding: 0.75rem;
    border-radius: 0.5rem;
    font-weight: 500;
    border: 2px solid #E2384D;
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 1rem;
  }
  
  .trial-button:hover {
    background: rgba(226, 56, 77, 0.1);
  }
  

  .trial-banner {
    background-color: #F0F8FF;
    border-left: 4px solid #E2384D;
    padding: 0.75rem 1.5rem;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.5rem;
  }
  
  .trial-info {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .trial-icon {
    color: #E2384D;
    font-size: 1.25rem;
  }
  
  .trial-badge {
    background-color: #f0f4ff;
    color: #E2384D;
    font-size: 0.8rem;
    padding: 0.2rem 0.5rem;
    border-radius: 1rem;
    margin-left: 0.5rem;
    font-weight: 500;
    vertical-align: middle;
  }

  .downgrade-button {
    width: 100%;
    margin-top: 1.5rem;
    background: #6B7280;
    color: white;
    padding: 0.75rem;
    border-radius: 0.5rem;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 1rem;
  }
  
  .downgrade-button:hover {
    background: #4B5563;
  }

  .payment-method {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .payment-icon {
    color: var(--text-primary);
    font-size: 1rem;
  }

  .next-due {
    display: flex;
    align-items: center;
    margin-top: 0.75rem;
    font-size: 0.875rem;
    color: var(--text-secondary);
    gap: 0.5rem;
  }
  
  .next-due.approaching {
    color: #F59E0B;
    font-weight: 500;
  }
  
  .calendar-icon {
    color: var(--text-secondary);
  }
  
  .next-due.approaching .calendar-icon {
    color: #F59E0B;
  }
  
  .warning-icon {
    color: #F59E0B;
    margin-left: 0.5rem;
    cursor: help;
  }

  .cancelled-badge {
    font-size: 0.8rem;
    color: #dc2626;
    margin-left: 0.5rem;
    font-weight: 500;
    vertical-align: middle;
  }
  
  /* Adding a hover effect for the warning icon tooltip */
  .warning-icon:hover::after {
    content: attr(title);
    position: absolute;
    background: #1F2937;
    color: white;
    padding: 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    margin-top: -2.5rem;
    margin-left: -2rem;
    white-space: nowrap;
    z-index: 10;
  }

  /* Cancel Popup Styles */
.cancel-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  backdrop-filter: blur(3px);
}

.cancel-popup-content {
  background-color: var(--bg-primary);
  border-radius: 0.75rem;
  max-width: 90%;
  width: 450px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  animation: fadeInUp 0.3s ease-out;
}

.cancel-popup-header {
  padding: 1.5rem 1.5rem 0.75rem;
  text-align: center;
  border-bottom: 1px solid var(--border-color);
}

.cancel-icon {
  color: #F59E0B;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.cancel-title {
  color: var(--text-primary);
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
}

.cancel-popup-body {
  padding: 1.5rem;
  text-align: center;
  color: var(--text-primary);
}

.access-until {
  margin-top: 1rem;
  padding: 0.75rem;
  background-color: var(--bg-secondary);
  border-radius: 0.5rem;
  font-size: 0.95rem;
}

.date-highlight {
  font-weight: 600;
  color: #E2384D;
}

.cancel-popup-footer {
  display: flex;
  padding: 1rem 1.5rem 1.5rem;
  gap: 1rem;
  border-top: 1px solid var(--border-color);
}

.cancel-popup-button-primary,
.cancel-popup-button-secondary {
  flex: 1;
  padding: 0.75rem;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
  font-size: 1rem;
}

.cancel-popup-button-primary {
  background: #E2384D;
  color: white;
}

.cancel-popup-button-primary:hover {
  background: #cb1d32;
}

.cancel-popup-button-secondary {
  background: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
}

.cancel-popup-button-secondary:hover {
  background: var(--border-color);
}

.cancel-popup-button-primary:disabled,
.cancel-popup-button-secondary:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Existing cancel button styles - update for consistency */
.cancel-button {
  width: 100%;
  margin-top: 1.5rem;
  background: #ef4444;
  color: white;
  padding: 0.75rem;
  border-radius: 0.5rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 1rem;
}

.cancel-button:hover {
  background: #dc2626;
}

/* Animation for the popup */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


  
  @media (max-width: 768px) {
    .next-due {
      flex-wrap: wrap;
    }
  }

  @media (max-width: 640px) {
    .history-details {
      grid-template-columns: 1fr;
    }
  
    .history-header {
      flex-direction: column;
      gap: 1rem;
    }
  
    .history-amount {
      font-size: 1.25rem;
    }
 
      .cancel-popup-content {
        width: 95%;
      }
      
      .cancel-popup-footer {
        flex-direction: column;
      }

  }

  .load-more-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
    padding: 0.75rem;
    margin-top: 1.5rem;
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    border: 1px solid var(--border-color);
    border-radius: 0.5rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .load-more-button:hover:not(:disabled) {
    background-color: #e2e8f0;
  }
  
  .load-more-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .loading-more {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem;
    color: var(--text-secondary);
    font-size: 0.9rem;
  }
  
  .loading-spinner-small {
    font-size: 1rem;
    color: #E2384D;
  }
  
  @media (hover: hover) {
    .load-more-button:hover:not(:disabled) {
      transform: translateY(-2px);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
  }
  
  @media (max-width: 640px) {
    .load-more-button {
      padding: 0.75rem 0.5rem;
    }
  }
  
  /* Responsive Styles */
  @media (max-width: 1400px) {
    .plans-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .membership-content {
      margin: 0;
      padding: 1rem;
      padding-bottom: 5rem;
    }
  
    .plans-grid {
      grid-template-columns: 1fr;
      gap: 1rem;
      padding: 0;
    }
  
    .bottom-nav {
      display: flex;
    }
  
    .membership-header h1 {
      font-size: 1.5rem;
    }
  
    .plan-card {
      min-width: unset;
    }
  
    .plan-header {
      padding: 1rem;
    }
  
    .plan-content {
      padding: 1rem;
    }
  
    .amount {
      font-size: 1.75rem;
    }
  
    .feature-item {
      font-size: 0.9rem;
    }
  }
  
  /* Print Styles */
  @media print {
    .bottom-nav,
    .upgrade-button {
      display: none;
    }
  
    .plan-card {
      break-inside: avoid;
      box-shadow: none;
      border: 1px solid var(--border-color);
    }
  
    .membership-content {
      margin: 0;
      padding: 0;
    }
  }