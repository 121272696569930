/* CreateTour.module.css - Part 1: CSS Variables and Layout */

/* CSS Variables - Light Theme */
:root {
    --bg-primary: #ffffff;
    --bg-secondary: #f3f4f6;
    --text-primary: #111827;
    --text-secondary: #6b7280;
    --border-color: #e5e7eb;
    --bg-hover: #f9fafb;
    --border-hover: #d1d5db;
    --step-completed: #10b981;
    --step-active: #E2384D;
    --step-pending: #9ca3af;
}

/* CSS Variables - Dark Theme */
:root[class="dark"] {
    --bg-primary: #1f2937;
    --bg-secondary: #111827;
    --text-primary: #ffffff;
    --text-secondary: #9ca3af;
    --border-color: #374151;
    --bg-hover: #374151;
    --border-hover: #4b5563;
    --step-completed: #059669;
    --step-active: #E2384D;
    --step-pending: #4b5563;
}

/* Layout Styles */
.pageContainer {
    min-height: 100vh;
    background-color: var(--bg-primary);
}

.mainLayout {
    display: flex;
    min-height: calc(100vh - 3.5rem);
    margin-top: 3.5rem;
    position: relative;
}

.content {
    flex: 1;
    margin-left: 240px;
    margin-right: 270px;
    min-width: 0;
    padding: 2rem;
    background-color: var(--bg-primary);
}

.container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 2rem;
    background-color: var(--bg-secondary);
    border-radius: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.title {
    font-size: 2rem;
    font-weight: 600;
    color: var(--text-primary);
    text-align: center;
    margin-bottom: 3rem;
}

/* Steps Progress Bar */
.stepsContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 3rem;
    position: relative;
    padding: 0 2rem;
}

.stepsContainer::before {
    content: '';
    position: absolute;
    top: 1.5rem;
    left: 4rem;
    right: 4rem;
    height: 2px;
    background-color: var(--border-color);
    z-index: 1;
}

.stepWrapper {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    flex: 1;
}

.step {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: var(--bg-primary);
    border: 2px solid var(--step-pending);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--step-pending);
    transition: all 0.3s ease;
}

.step.completed {
    background-color: var(--step-completed);
    border-color: var(--step-completed);
    color: white;
}

.step.active {
    background-color: var(--step-active);
    border-color: var(--step-active);
    color: white;
    transform: scale(1.1);
}

.stepLabel {
    color: var(--text-secondary);
    font-size: 0.875rem;
    font-weight: 500;
    text-align: center;
    position: absolute;
    top: 100%;
    margin-top: 0.75rem;
    width: 100%;
}

/* Basic Form Section Styles */
.formSection {
    padding: 2rem;
    background-color: var(--bg-primary);
    border-radius: 0.75rem;
    margin-bottom: 2rem;
}

.sectionTitle {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--text-primary);
    margin-bottom: 1.5rem;
}

/* CreateTour.module.css - Part 2: Search and Form Inputs */

/* Search Components */
.searchSection {
    margin-bottom: 2rem;
}

.searchBar,
.searchInputContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    width: 100%;
}

.searchInput,
.venueSearchInput {
    flex: 1;
    padding: 12px 15px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    font-size: 16px;
    background-color: var(--bg-secondary);
    color: var(--text-primary);
}

.searchInput:focus,
.venueSearchInput:focus {
    outline: none;
    border-color: #E2384D;
    box-shadow: 0 0 0 2px rgba(226, 56, 77, 0.1);
}

.searchButton {
    width: 120px; /* Fixed width for all search buttons */
    padding: 12px 20px;
    background-color: #E2384D;
    color: white;
    border: none;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    white-space: nowrap;
}

.searchButton:disabled {
    background-color: var(--step-pending);
    cursor: not-allowed;
}

.searchButton:hover:not(:disabled) {
    background-color: #d42e41;
}

/* Search Results */
.searchResults {
    margin-top: 10px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    max-height: 300px;
    overflow-y: auto;
    background-color: var(--bg-secondary);
}

.searchResult {
    display: flex;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid var(--border-color);
    cursor: pointer;
    transition: background-color 0.2s ease;
    background-color: var(--bg-primary);
}

.searchResult:last-child {
    border-bottom: none;
}

.searchResult:hover:not(.disabledSearchResult) {
    background-color: var(--bg-hover);
}

.disabledSearchResult {
    opacity: 0.6;
    cursor: not-allowed;
    background-color: var(--bg-secondary);
}

/* User Info in Search Results */
.profilePhoto {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    object-fit: cover;
    margin-right: 15px;
}

.userInfo {
    flex: 1;
}

.nameContainer {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 4px;
}

.fullName {
    font-weight: 500;
    color: var(--text-primary);
    font-size: 16px;
}

.verifiedBadge {
    width: 16px;
    height: 16px;
    margin-left: 4px;
}

.userName,
.userSubType {
    color: var(--text-secondary);
    font-size: 14px;
    display: block;
    margin-bottom: 2px;
}

.alreadyAssignedText {
    color: #E2384D;
    font-size: 14px;
    margin-left: auto;
    padding-left: 10px;
}

/* Loading and No Results States */
.searchingMessage,
.noResults {
    padding: 20px;
    text-align: center;
    color: var(--text-secondary);
    background-color: var(--bg-secondary);
    border-radius: 5px;
    margin-top: 10px;
}

/* Form Inputs */
.inputGroup {
    margin-bottom: 1.5rem;
}

.inputLabel {
    display: block;
    color: var(--text-primary);
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid var(--border-color);
    border-radius: 0.5rem;
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    transition: all 0.3s ease;
}

.input:focus {
    outline: none;
    border-color: var(--step-active);
    box-shadow: 0 0 0 3px rgba(226, 56, 77, 0.1);
}

.select {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid var(--border-color);
    border-radius: 0.5rem;
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    cursor: pointer;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 1.25rem;
    padding-right: 2.5rem;
}

/* CreateTour.module.css - Part 3: Calendar and Date Selection */

/* Calendar Container */
.calendarContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: var(--bg-primary);
    border-radius: 0.75rem;
    margin: 2rem 0;
}

.calendarWrapper {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

/* React Calendar Override Styles */
:global(.react-calendar) {
    width: 100%;
    background: var(--bg-secondary);
    border: 1px solid var(--border-color);
    border-radius: 0.75rem;
    padding: 1rem;
    color: var(--text-primary);
}

:global(.react-calendar__tile) {
    padding: 1rem;
    background: none;
    color: var(--text-primary);
    border-radius: 0.5rem;
}

:global(.react-calendar__tile:enabled:hover) {
    background-color: var(--bg-hover);
}

:global(.react-calendar__tile--active) {
    background: #E2384D !important;
    color: white !important;
}

:global(.react-calendar__navigation button) {
    color: var(--text-primary);
}

:global(.react-calendar__navigation button:enabled:hover) {
    background-color: var(--bg-hover);
}

:global(.react-calendar__month-view__weekdays) {
    color: var(--text-secondary);
}

:global(.react-calendar__tile:disabled) {
    background-color: var(--bg-secondary) !important;
    color: var(--text-secondary) !important;
    opacity: 0.5;
}

:global(.react-calendar__navigation button:disabled) {
    background-color: var(--bg-secondary) !important;
    color: var(--text-secondary) !important;
}

:global(.react-calendar__tile--now) {
    background-color: rgba(226, 56, 77, 0.1) !important;
    color: #E2384D !important;
}

/* Selected Dates Display */
.selectedDates {
    width: 100%;
    max-width: 600px;
    margin: 2rem auto;
}

.selectedDatesTitle {
    color: var(--text-primary);
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.dateItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: var(--bg-secondary);
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
}

.dateText {
    color: var(--text-primary);
    font-weight: 500;
}

.removeDate {
    background: none;
    border: none;
    color: #E2384D;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 0.25rem;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.removeDate:hover {
    background-color: rgba(226, 56, 77, 0.1);
}

/* Date Selector Container */
.dateSelectorContainer {
    margin-bottom: 2rem;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 1rem;
}

.datesScrollContainer {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    padding: 10px 0;
    margin-bottom: -20px; /* Hide scrollbar */
    padding-bottom: 20px; /* Hide scrollbar */
    -ms-overflow-style: none; /* Hide scrollbar IE and Edge */
    scrollbar-width: none; /* Hide scrollbar Firefox */
  }

.datesScrollContainer::-webkit-scrollbar {
    display: none;
}

.dateBox {
    min-width: 200px;
    padding: 1rem;
    background-color: var(--bg-secondary);
    border-radius: 0.5rem;
    border: 1px solid var(--border-color);
    cursor: pointer;
    transition: all 0.2s ease;
}

.dateBox.active {
    border-color: #E2384D;
    background-color: rgba(226, 56, 77, 0.1);
}

.dateBoxDay {
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: var(--text-primary);
}

.dateBoxVenue {
    font-size: 0.875rem;
    color: var(--text-secondary);
}

/* Date Selection for Members */
.dateSelect {
    background-color: var(--bg-secondary);
    border: 1px solid var(--border-color);
    border-radius: 0.5rem;
    padding: 0.5rem;
    color: var(--text-primary);
    width: 200px;
    max-height: 100px;
    overflow-y: auto;
}

.dateSelect option {
    padding: 0.25rem 0.5rem;
}

/* Loading States */
.searchingMessage {
    text-align: center;
    color: var(--text-secondary);
    padding: 20px;
}

/* Navigation Between Dates */
.dateTabsContainer {
    display: flex;
    overflow-x: auto;
    gap: 1rem;
    padding: 1rem 0;
    margin-bottom: 2rem;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.dateTabsContainer::-webkit-scrollbar {
    display: none;
}


.membershipInfo {
    display: block;
    color: var(--text-primary);
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

/* CreateTour.module.css - Part 4: Venue Selection and Member Cards */

/* Venue Section Styles */


.venueSection {
    padding: 2rem;
    background-color: var(--bg-primary);
    border-radius: 0.75rem;
    margin-bottom: 1rem;
}

.venueDateTitle {
    color: var(--text-primary);
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.venueSearchContainer {
    margin-bottom: 1.5rem;
}

/* Venue Results */
.venueResults {
    margin-top: 10px;
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid var(--border-color);
    border-radius: 5px;
}

.venueItem {
    display: flex;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid var(--border-color);
    cursor: pointer;
    transition: background-color 0.2s ease;
  }

.venueItem:last-child {
    border-bottom: none;
}

.venueItem:hover {
    background-color: var(--bg-hover);
}

.venueName {
    margin: 0 0 5px 0;
    font-size: 16px;
    font-weight: 500;
    color: var(--text-primary);
}

.venueAddress {
    margin: 0;
    color: var(--text-secondary);
    font-size: 14px;
}

.noVenueResults {
    text-align: center;
    padding: 20px;
    background-color: var(--bg-secondary);
    border-radius: 5px;
    margin-top: 10px;
}

/* Member Selection Styles */
.selectedMembersSection {
    margin-top: 30px;
    color: var(--text-primary);
}

.selectedMembersList {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.selectedMemberCard {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 15px;
    background-color: var(--bg-secondary);
    border-color: var(--border-color);
    margin-bottom: 10px;
    border-radius: 5px;
    position: relative;
    width: 100%;
  }

.memberPhoto {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    object-fit: cover;
}

.memberInfo {
    flex: 0 0 140px; /* Fixed width for member info */
  }

.memberNameContainer {
    display: flex;
    align-items: center;
    gap: 5px;
}

.memberName {
    font-size: 16px;
    font-weight: 500;
    color: var(--text-primary);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

.memberUsername {
    color: var(--text-secondary);
    font-size: 14px;
}

.memberSubtype {
    color: var(--text-secondary);
    font-size: 14px;
}

/* Remove Member Button */
.removeMemberButton {
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: #E2384D;
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }

.removeMemberButton:hover {
    background-color: #d42e41;
}

/* Cover Photo Upload */
.coverPhotoContainer {
    width: 100%;
    height: 200px;
    border: 2px dashed var(--border-color);
    border-radius: 0.75rem;
    overflow: hidden;
    position: relative;
    margin-bottom: 1.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.coverPhotoContainer:hover {
    border-color: var(--step-active);
}

.coverPhotoPreview {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.uploadOverlay {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.coverPhotoContainer:hover .uploadOverlay {
    opacity: 1;
}

/* Map Integration */
.searchMapButton {
    margin-top: 10px;
    padding: 8px 20px;
    background-color: #E2384D;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.2s ease;
}

.searchMapButton:hover {
    background-color: #d42e41;
}

/* Selected Venue Display */
.selectedVenue {
    margin-top: 20px;
    padding: 15px;
    background-color: var(--bg-secondary);
    border-radius: 5px;
    border: 1px solid var(--border-color);
}

.venueInfo {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center; 
}


/* Artist/Crew Tour Date Selection */


  
.selectAllButton {
    width: auto;
    padding: 8px 16px;
    background-color: #E2384D;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
    margin-bottom: 5px;
    align-self: flex-start;
  }
  

  
  .dateCard {
    flex: 0 0 auto; /* Prevent date cards from shrinking */
    min-width: 100px;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    transition: all 0.2s ease;
    position: relative;
  }
  
  .dateCard:not(.pendingDate):hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .dateCard.selected {
    background-color: rgba(226, 56, 77, 0.1);
    border-color: #E2384D;
  }
  
  .dateCard.confirmedDate {
    background-color: rgba(16, 185, 129, 0.1);
    border-color: #10B981;
  }
  
  .dateCard.confirmedDate.selected {
    background-color: rgba(239, 68, 68, 0.1);
    border-color: #EF4444;
  }
  
  .dateCard.pendingDate {
    background-color: rgba(245, 158, 11, 0.1);
    border-color: #F59E0B;
    cursor: not-allowed;
    opacity: 0.8;
  }

  .dateCard.selected:not(.confirmedDate):not(.pendingDate) {
    background-color: rgba(226, 56, 77, 0.1);
    border-color: #E2384D;
  }
  
  .dateCardDay {
    font-weight: 500;
    color: var(--text-secondary);
    margin-bottom: 5px;
  }
  
  .dateCardDate {
    font-size: 14px;
    color: var(--text-primary);
  }

  .dateStatus {
    position: absolute;
    top: -8px;
    right: -8px;
    padding: 2px 6px;
    border-radius: 12px;
    font-size: 10px;
    font-weight: 500;
    color: white;
    z-index: 1;
  }

  .confirmedStatus {
    background-color: #10B981;
  }
  
  .pendingStatus {
    background-color: #F59E0B;
  }
  
  .confirmedDate .dateStatus {
    background-color: #10B981;
  }
  
  .pendingDate .dateStatus {
    background-color: #F59E0B;
  }

/* CreateTour.module.css - Part 5: Navigation, Loading States, and Responsive Styles */

/* Navigation Buttons */
.navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    padding-top: 2rem;
    border-top: 1px solid var(--border-color);
}

.navButton {
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    min-width: 120px;
    text-align: center;
}

.prevButton {
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    border: 1px solid var(--border-color);
}

.prevButton:hover {
    background-color: var(--bg-hover);
}

.nextButton {
    background-color: #E2384D;
    color: white;
    border: none;
}

.nextButton:hover:not(:disabled) {
    background-color: #d42e41;
}

.nextButton:disabled {
    background-color: var(--step-pending);
    cursor: not-allowed;
}

.pendingMembersSection {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid var(--border-color);
  }
  
  .pendingBadge {
    display: inline-block;
    padding: 4px 8px;
    background-color: #FFA500;
    color: white;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 8px;
  }
  
  .pendingDate {
    opacity: 0.7;
    cursor: not-allowed;
    background-color: var(--bg-hover);
  }
  
  .pendingDate:hover {
    transform: none;
  }

  
/* Loading States */
.loadingOverlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
  }
  
  .loadingContent {
    background-color: var(--bg-primary);
    padding: 2rem;
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  
  .loadingSpinner {
    width: 3rem;
    height: 3rem;
    border: 3px solid var(--bg-secondary);
    border-top-color: #E2384D;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  .loadingMessage {
    color: var(--text-primary);
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    min-width: 200px;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  .newSelectionsSection {
    margin-top: 2rem;
  }
  
  .selectedMembersSection h3 {
    color: var(--text-primary);
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  
  .memberNameContainer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.25rem;
  }
  
  .dateSelectionContainer {
    flex: 1;
    min-width: 0; /* Important for preventing flex item overflow */
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-right: 10px; /* Space for remove button */
  }
  

  
  /* Status badges base styles */
  .confirmedBadge,
  .pendingBadge {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  
  .confirmedBadge {
    background-color: #10B981;
    color: white;
  }
  
  .pendingBadge {
    background-color: #F59E0B;
    color: white;
  }
  
  
  
  /* Section separators */
  .confirmedMembersSection,
  .pendingMembersSection {
    border-bottom: 1px solid var(--border-color);
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .dateSelectionContainer {
      margin-right: 0;
      margin-bottom: 2.5rem; /* Space for remove button */
    }
    
    .selectAllButton {
      width: 100%;
    }
    
    .removeMemberButton {
      bottom: 0.5rem;
      right: 0.5rem;
      top: auto;
    }
  }

/* Modal Styles */
.modalOverlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 40;
}

.modalContent {
    background-color: var(--bg-primary);
    border-radius: 10px;
    padding: 2rem;
    width: 90%;
    max-width: 500px;
    max-height: 90vh;
    overflow-y: auto;
}

.modalTitle {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--text-primary);
    margin-bottom: 1rem;
  }
  
  .modalText {
    color: var(--text-primary);
    margin-bottom: 1rem;
    line-height: 1.5;
  }
  
  .modalSubText {
    color: var(--text-secondary);
    margin-bottom: 1.5rem;
    font-size: 0.875rem;
  }
  
  .modalActions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;
  }
  
  .modalCancelButton {
    padding: 8px 16px;
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    border: 1px solid var(--border-color);
    border-radius: 6px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s ease;
  }
  
  .modalCancelButton:hover {
    background-color: var(--bg-hover);
    border-color: var(--border-hover);
  }
  
  .modalConfirmButton {
    padding: 8px 16px;
    background-color: #E2384D;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s ease;
  }
  
  .modalConfirmButton:hover {
    background-color: #d42e41;
  }
  
  /* Dark theme specific styles */
  :root[class="dark"] .modalContent {
    background-color: var(--bg-primary);
    border: 1px solid var(--border-color);
  }

  .venueResultImage {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 15px;
    flex-shrink: 0;
  }

  .venueInfoContainer {
    flex: 1;
  }
  
  
  .selectedVenueImage {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
  }
  
  .selectedVenueContainer {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .mapContainer {
    height: 300px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid var(--border-color);
    margin-top: 16px;
  }
  
  .noResultsText {
    color: var(--text-secondary);
    margin-bottom: 10px;
  }

/* Error States */
.errorMessage {
    color: #E2384D;
    font-size: 14px;
    margin-top: 5px;
}

/* Success States */
.successMessage {
    color: var(--step-completed);
    font-size: 14px;
    margin-top: 5px;
}

/* Additional Helper Classes */
.hidden {
    display: none;
}

.disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

/* Dark Theme Specific Overrides */
:root[class="dark"] .input,
:root[class="dark"] .select,
:root[class="dark"] .venueSearchInput,
:root[class="dark"] .searchInput {
    background-color: var(--bg-secondary);
    color: var(--text-primary);
}

:root[class="dark"] .calendarContainer {
    background-color: var(--bg-secondary);
}

:root[class="dark"] :global(.react-calendar) {
    background-color: var(--bg-primary);
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .content {
        margin-left: 200px;
        margin-right: 200px;
    }
}

@media (max-width: 992px) {
    .content {
        margin-left: 100px;
        margin-right: 100px;
    }
}

@media (max-width: 768px) {
    .content {
        margin: 0;
        padding: 1rem;
    }

    .container {
        padding: 1rem;
    }

    .title {
        font-size: 1.5rem;
        margin-bottom: 2rem;
    }

    .stepsContainer {
        padding: 0 1rem;
    }

    .stepLabel {
        font-size: 0.75rem;
    }

    .formSection {
        padding: 1rem;
    }

    .calendarContainer {
        padding: 1rem;
    }

    .selectedMemberCard {
        flex-direction: column;
        align-items: flex-start;
    }

    .dateSelect {
        width: 100%;
        max-width: none;
    }

    .navigation {
        flex-direction: column;
        gap: 1rem;
    }

    .navButton {
        width: 100%;
    }

    .searchButton {
        min-width: 80px;
    }
}

@media (max-width: 480px) {
    .modalContent {
        width: 95%;
        padding: 1rem;
    }

    .searchBar {
        flex-direction: column;
    }

    .searchButton {
        width: 100%;
    }

    .dateBox {
        min-width: 150px;
    }
}

/* Print Styles */
@media print {
    .pageContainer {
        background: white;
    }

    .navigation,
    .searchButton,
    .removeMemberButton {
        display: none;
    }
}