/* Modal Base Styles */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 20px;
  }
  
  .modalContent {
    background-color: var(--bg-primary);
    border-radius: 12px;
    padding: 24px;
    width: 90%;
    max-width: 500px;
    max-height: 90vh;
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--border-color);
  }
  
  .closeButton {
    position: absolute;
    top: 16px;
    right: 16px;
    background: none;
    border: none;
    color: var(--text-secondary);
    cursor: pointer;
    padding: 8px;
    border-radius: 4px;
    transition: all 0.2s;
  }
  
  .closeButton:hover {
    background-color: var(--bg-secondary);
    color: var(--text-primary);
  }
  
  .modalTitle {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text-primary);
    margin-bottom: 24px;
  }
  
  /* Form Styles */
  .form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
    padding-right: 8px;
    max-height: calc(90vh - 150px);
  }

  /* Custom scrollbar */
  .form::-webkit-scrollbar {
    width: 6px;
  }
  
  .form::-webkit-scrollbar-track {
    background: var(--bg-secondary);
    border-radius: 3px;
  }
  
  .form::-webkit-scrollbar-thumb {
    background: var(--border-color);
    border-radius: 3px;
  }
  
  /* Upload Section Styles */
  .uploadSection {
    background-color: var(--bg-secondary);
    border-radius: 12px;
    padding: 24px;
    margin-bottom: 24px;
  }
  
  .uploadArea {
    border: 2px dashed var(--border-color);
    border-radius: 8px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s;
    background-color: var(--bg-primary);
  }
  
  .uploadArea:hover {
    border-color: #E2384D;
    background-color: rgba(226, 56, 77, 0.05);
  }
  
  .uploadIcon {
    font-size: 32px;
    color: #E2384D;
    margin-bottom: 16px;
  }
  
  .uploadText {
    text-align: center;
  }
  
  .uploadText p {
    margin: 0;
    font-weight: 500;
    color: var(--text-primary);
  }
  
  .uploadInfo {
    display: block;
    margin-top: 8px;
    font-size: 12px;
    color: var(--text-secondary);
    line-height: 1.5;
  }
  
  .fileInput {
    display: none;
  }
  
  /* Selected File Info Styles */
  .selectedFileInfo {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px;
    background-color: var(--bg-primary);
    border-radius: 8px;
    border: 1px solid var(--border-color);
  }
  
  .fileTypeIcon {
    font-size: 24px;
    color: #E2384D;
  }
  
  .fileDetails {
    flex: 1;
  }
  
  .fileName {
    margin: 0;
    font-weight: 500;
    color: var(--text-primary);
  }
  
  .fileSize {
    margin: 4px 0 0;
    font-size: 12px;
    color: var(--text-secondary);
  }
  
  .removeFileButton {
    padding: 8px;
    border: none;
    background: none;
    color: var(--text-secondary);
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.2s;
  }
  
  .removeFileButton:hover {
    background-color: rgba(226, 56, 77, 0.1);
    color: #E2384D;
  }
  
  /* Form Group Styles */
  .formGroup {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .formGroup label {
    font-weight: 500;
    color: var(--text-primary);
  }
  
  .textarea {
    width: 95%;
    min-height: 100px;
    padding: 12px;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    resize: vertical;
    font-family: inherit;
  }
  
  .textarea:focus {
    outline: none;
    border-color: #E2384D;
    box-shadow: 0 0 0 2px rgba(226, 56, 77, 0.2);
  }
  
  /* Visibility Control Styles */
  .visibilityHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  
  .upgradeButton {
    display: flex;
    align-items: center;
    max-width: 100px;
    gap: 4px;
    background-color: #E2384D;
    color: white;
    padding: 6px 12px;
    border-radius: 6px;
    border: none;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .upgradeButton:hover {
    background-color: #cc2b3d;
  }
  
  .visibilityControl {
    background-color: var(--bg-secondary);
    border-radius: 8px;
    padding: 16px;
    border: 1px solid var(--border-color);
  }
  
  .visibilityOptions {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .visibilityOption {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 8px;
    border-radius: 6px;
    transition: background-color 0.2s;
  }
  
  .visibilityOption:hover {
    background-color: var(--bg-primary);
  }
  
  .visibilityOption input[type="radio"] {
    width: 16px;
    height: 16px;
    accent-color: #E2384D;
  }
  
  .visibilityOption span {
    color: var(--text-primary);
    font-size: 14px;
  }
  
  /* Custom Visibility Selector Styles */
  .customVisibilitySelector {
    border-top: 1px solid var(--border-color);
    margin-top: 16px;
    padding-top: 16px;
  }
  
  .customVisibilityTitle {
    font-size: 14px;
    color: var(--text-secondary);
    margin-bottom: 12px;
  }
  
  .customVisibilityOption {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    border-radius: 6px;
    transition: background-color 0.2s;
  }
  
  .customVisibilityOption:hover {
    background-color: var(--bg-primary);
  }
  
  .customVisibilityOption.manager {
    background-color: rgba(226, 56, 77, 0.1);
  }
  
  .customVisibilityOption input[type="checkbox"] {
    width: 16px;
    height: 16px;
    accent-color: #E2384D;
  }
  
  .customVisibilityOption.manager input[type="checkbox"] {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  /* Form Actions Styles */
  .formActions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;
  }
  
  .cancelButton,
  .submitButton {
    padding: 12px 24px;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .cancelButton {
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    border: 1px solid var(--border-color);
  }
  
  .cancelButton:hover:not(:disabled) {
    background-color: var(--border-color);
  }
  
  .submitButton {
    background-color: #E2384D;
    color: white;
    border: none;
  }
  
  .submitButton:hover:not(:disabled) {
    background-color: #cc2b3d;
  }
  
  .cancelButton:disabled,
  .submitButton:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  /* Alert Modal Styles */
  .alertModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
    backdrop-filter: blur(4px);
  }
  
  .alertModal {
    background-color: var(--bg-primary);
    border-radius: 12px;
    padding: 24px;
    width: 90%;
    max-width: 400px;
    border: 1px solid var(--border-color);
  }
  
  .alertHeader {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;
  }
  
  .alertIcon {
    font-size: 24px;
  }
  
  .alertIcon.error {
    color: #E2384D;
  }
  
  .alertTitle {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    color: var(--text-primary);
  }
  
  .alertMessage {
    color: var(--text-secondary);
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 1.5;
  }
  
  .alertActions {
    display: flex;
    justify-content: flex-end;
  }
  
  .alertButton {
    padding: 8px 16px;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .alertButton.error {
    background-color: #E2384D;
    border: none;
    color: white;
  }
  
  /* Loading Overlay Styles */
  .loadingOverlay {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    z-index: 1200;
  }
  
  .spinner {
    width: 40px;
    height: 40px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #E2384D;
    animation: spin 1s linear infinite;
  }
  
  .loadingText {
    color: white;
    margin-top: 16px;
    font-size: 16px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .modalContent {
      width: 95%;
      padding: 16px;
    }
  
    .uploadArea {
      padding: 24px;
    }
  
    .uploadIcon {
      font-size: 24px;
    }
  
    .formActions {
      flex-direction: column;
    }
  
    .cancelButton,
    .submitButton {
      width: 100%;
    }
  
    .visibilityControl {
      padding: 12px;
    }
  
    .visibilityOption,
    .customVisibilityOption {
      padding: 6px;
    }
  
    .alertModal {
      width: 95%;
      margin: 16px;
    }
  }
  
  /* Dark Mode Support */
  :root[class="dark"] .modalContent,
  :root[class="dark"] .alertModal {
    background-color: var(--bg-primary);
    border-color: var(--border-color);
  }
  
  :root[class="dark"] .uploadArea {
    background-color: var(--bg-primary);
  }
  
  :root[class="dark"] .visibilityOption:hover,
  :root[class="dark"] .customVisibilityOption:hover {
    background-color: var(--bg-primary);
  }
  
  :root[class="dark"] .cancelButton {
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    border-color: var(--border-color);
  }
  
  :root[class="dark"] .submitButton,
  :root[class="dark"] .upgradeButton {
    background-color: #E2384D;
    color: white;
  }

  /* Upgrade Modal Styles */
.upgradeModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
  }
  
  .upgradeModal {
    background-color: var(--bg-primary);
    border-radius: 12px;
    padding: 24px;
    width: 90%;
    max-width: 400px;
    border: 1px solid var(--border-color);
    text-align: center;
  }
  
  .upgradeModalTitle {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--text-primary);
    margin-bottom: 16px;
  }
  
  .upgradeModalMessage {
    color: var(--text-secondary);
    margin-bottom: 24px;
    line-height: 1.5;
  }
  
  .upgradeModalActions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
  
  /* Elite Tag Styles */
  .eliteTag {
    background-color: #E2384D;
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
  }
  
  /* Visibility Info Styles */
  .visibilityInfo {
    background-color: var(--bg-secondary);
    padding: 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--border-color);
  }
  
  .visibilityInfo span:first-child {
    color: var(--text-secondary);
    font-size: 14px;
  }
  
  /* File Size Warning Styles */
  .fileSizeWarning {
    background-color: rgba(226, 56, 77, 0.1);
    color: #E2384D;
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 12px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .fileSizeWarning svg {
    font-size: 14px;
  }
  
  /* Enhanced Form Group Styles */
  .formGroup.error label {
    color: #E2384D;
  }
  
  .formGroup.error .textarea {
    border-color: #E2384D;
    background-color: rgba(226, 56, 77, 0.05);
  }
  
  .formGroup.error .helpText {
    color: #E2384D;
    font-size: 12px;
    margin-top: 4px;
  }
  
  /* Enhanced Button Styles */
  .submitButton:disabled {
    background-color: #f3f4f6;
    color: #9ca3af;
    cursor: not-allowed;
    border: 1px solid #e5e7eb;
  }
  
  .submitButton:disabled:hover {
    background-color: #f3f4f6;
    transform: none;
  }
  
  /* Drag and Drop Enhancement */
  .uploadArea.dragOver {
    border-color: #E2384D;
    background-color: rgba(226, 56, 77, 0.05);
  }
  
  .uploadArea.dragOver .uploadIcon {
    transform: scale(1.1);
  }
  
  /* Enhanced Mobile Styles */
  @media (max-width: 768px) {
    .upgradeModal {
      width: 95%;
      margin: 16px;
    }
  
    .upgradeModalActions {
      flex-direction: column;
    }
  
    .upgradeModalActions button {
      width: 100%;
    }
  
    .visibilityInfo {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }
  
    .eliteTag {
      align-self: flex-start;
    }
  }
  
  /* Animation Keyframes */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideIn {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  /* Apply Animations */
  .modalContent {
    animation: slideIn 0.3s ease-out;
  }
  
  .alertModal,
  .upgradeModal {
    animation: slideIn 0.3s ease-out;
  }
  
  .modalOverlay,
  .alertModalOverlay,
  .upgradeModalOverlay {
    animation: fadeIn 0.2s ease-out;
  }
  
  /* Enhanced Accessibility Styles */
  .visibilityOption input[type="radio"]:focus + span,
  .customVisibilityOption input[type="checkbox"]:focus + label {
    outline: 2px solid #E2384D;
    outline-offset: 2px;
  }
  
  .closeButton:focus,
  .submitButton:focus,
  .cancelButton:focus {
    outline: 2px solid #E2384D;
    outline-offset: 2px;
  }
  
  /* Better Focus Indicators for Dark Mode */
  :root[class="dark"] .visibilityOption input[type="radio"]:focus + span,
  :root[class="dark"] .customVisibilityOption input[type="checkbox"]:focus + label,
  :root[class="dark"] .closeButton:focus,
  :root[class="dark"] .submitButton:focus,
  :root[class="dark"] .cancelButton:focus {
    outline-color: #E2384D;
  }
  
  /* Print Styles */
  @media print {
    .modalOverlay {
      position: relative;
      background: none;
    }
  
    .modalContent {
      box-shadow: none;
      border: 1px solid #000;
    }
  
    .submitButton,
    .cancelButton,
    .closeButton {
      display: none;
    }
  }