:root {
  --bg-primary: #ffffff;
  --bg-secondary: #f3f4f6;
  --text-primary: #111827;
  --text-secondary: #6b7280;
  --border-color: #e5e7eb;
  --page-bg: #f5f7fb;
  --tag-primary: #f3f4f6;
}

:root[class="dark"] {
  --bg-primary: #1f2937;
  --bg-secondary: #111827;
  --text-primary: #ffffff;
  --text-secondary: #9ca3af;
  --border-color: #374151;
  --page-bg: #111827;
  --tag-primary: #1f2937;
}

.main-layout {
  display: flex;
  min-height: 100vh;
  padding-top: 3.5rem;
  background-color: var(--page-bg);
}

.ticket-main {
  flex: 1;
  margin-left: 240px;
  margin-right: 270px;
  min-width: 0;
  height: calc(100vh - 3.5rem);
  overflow-y: auto;
}

.ticket-content {
  padding: 2rem;
  max-width: 100%;
  margin: 0 auto;
}

/* Date Selector Styles */
.date-selector {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 2px;
  width: 100%;
  padding: 0;
}

.dates-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  gap: 4px;
  padding: 4px 0;
  width: calc(100% - 80px);
  flex: 1;
}

.dates-container::-webkit-scrollbar {
  display: none;
}

.date-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 16px;
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
  color: var(--text-primary);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 160px;
  white-space: nowrap;
  margin: 0;
}



.scroll-button {
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  flex-shrink: 0;
  width: 40px;
}

.scroll-button:hover {
  background-color: var(--bg-hover);
  color: #E2384D;
}

.date-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.date-button.cancelled .date-content {
  text-decoration: line-through;
  opacity: 0.7;
}

.status-badges {
  display: flex;
  gap: 4px;
  margin-top: 4px;
  font-size: 10px;
}

.cancelled-badge, .rescheduled-badge {
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
}

.cancelled-badge {
  background-color: rgba(239, 68, 68, 0.1);
  color: #ef4444;
}

.original-date {
  font-size: 10px;
  color: var(--text-secondary);
  margin-top: 4px;
}

.date-button.selected {
  background-color: #E2384D;
  border-color: #E2384D;
  color: white;
}

.date-button.selected .cancelled-badge,
.date-button.selected .rescheduled-badge,
.date-button.selected .original-date {
  color: white;
  background-color: rgba(255, 255, 255, 0.2);
}

.rescheduled-badge {
  background-color: rgba(245, 158, 11, 0.1);
  color: #f59e0b;
}

.passenger-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
}

.passenger-item {
  display: flex;
  align-items: center;
  gap: 6px;
}

.passenger-avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}


.date {
  font-size: 14px;
  color: var(--text-secondary);
  margin: 2px 0;
}

  .date-day {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 6px;
  }
  
  .date-venue {
    font-size: 13px;
    opacity: 0.8;
    text-align: center;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
 

/* Add Ticket Button */
.add-ticket-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  background-color: var(--bg-primary);
  border: 1px solid #E2384D;
  border-radius: 8px;
  color: #E2384D;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  gap: 8px;
}

.add-ticket-button:hover {
  background-color: var(--bg-hover);
}

/* Passenger and Tickets Styles */
.passenger-section {
  margin-bottom: 24px;
}

.passenger-name {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  gap: 8px;
}

.passenger-name img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.passenger-tickets {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-left: 40px;
}

/* Ticket Card Styles */
.ticket-card {
  background: var(--bg-primary);
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--border-color);
}

.ticket-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--border-color);
}

.airline-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.airline-logo {
  width: 36px;
  height: 36px;
  object-fit: contain;
}

.airline-name {
  display: block;
  font-weight: 600;
  color: var(--text-primary);
  font-size: 16px;
}

.ticket-number {
  display: block;
  font-size: 14px;
  color: var(--text-secondary);
  margin-top: 2px;
}

.pnr-number {
  font-size: 14px;
  color: var(--text-secondary);
  background: var(--bg-secondary);
  padding: 4px 8px;
  border-radius: 4px;
}

/* Journey Details Styles */
.journey-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  padding: 0 20px;
}

.location-time {
  text-align: center;
  flex: 1;
}

.time {
  font-size: 18px;
  font-weight: 600;
  color: var(--text-primary);
}

.location {
  font-size: 14px;
  color: var(--text-secondary);
  margin-top: 4px;
  font-weight: 500;
}

.journey-duration {
  flex: 2;
  text-align: center;
  position: relative;
}

.duration-line {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 20px;
  padding: 10px 0;
}

.dot {
  width: 8px;
  height: 8px;
  background: #E2384D;
  border-radius: 50%;
}

.line {
  flex: 1;
  height: 2px;
  background: #E2384D;
  margin: 0 4px;
  position: relative;
}

.line::before {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid #E2384D;
}

.duration-text {
  font-size: 14px;
  color: var(--text-secondary);
  margin-top: 8px;
}

/* Notes and Actions Styles */
.ticket-notes {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
  padding: 12px;
  background: var(--bg-secondary);
  border-radius: 8px;
  font-size: 14px;
  color: var(--text-secondary);
}

.ticket-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid var(--border-color);
}

.action-button {
  padding: 8px 16px;
  background: #E2384D;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
  font-weight: 500;
}

.action-button:hover {
  background: #cc2d43;
}

.action-icons {
  display: flex;
  gap: 12px;
}

.icon-button {
  padding: 8px;
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 4px;
}

.icon-button:hover {
  color: #E2384D;
  background: var(--bg-hover);
}

/* Empty State Styles */
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 0;
  text-align: center;
}

.empty-icon {
  font-size: 48px;
  color: #E2384D;
  margin-bottom: 16px;
}

.empty-text {
  color: var(--text-secondary);
  font-size: 16px;
}

.visibility-section {
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid var(--border-color);
}

.visibility-label {
  font-size: 12px;
  color: var(--text-secondary);
  margin-right: 8px;
}

.visibility-users {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.visibility-user {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  color: var(--text-primary);
  background-color: var(--tag-primary);
  border-radius: 16px;
  font-size: 12px;
}

.visibility-user-pic {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: cover;
}

.visibility-all {
  font-size: 12px;
  color: var(--text-secondary);
  font-style: italic;
}

.alert-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.alert-modal {
  background: var(--bg-primary);
  border-radius: 12px;
  padding: 24px;
  width: min(400px, 90%);
  border: 1px solid var(--border-color);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
}

.alert-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.alert-icon {
  color: #EF4444;
  font-size: 24px;
}

.alert-title {
  color: var(--text-primary);
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.alert-message {
  color: var(--text-secondary);
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 1.5;
}

.alert-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.alert-button {
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.alert-button.cancel {
  background: var(--bg-secondary);
  border: 1px solid var(--border-color);
  color: var(--text-primary);
}

.alert-button.delete {
  background: #EF4444;
  border: none;
  color: white;
}

.alert-button:hover {
  transform: translateY(-1px);
}

.alert-button.cancel:hover {
  background: var(--border-color);
}

.alert-button.delete:hover {
  background: #DC2626;
}

.alert-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  border-radius: 12px;
}

.loading-spinner {
  position: relative;
  width: 40px;
  height: 40px;
}

.loading-spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid #fff;
  border-top-color: #E2384D;
  animation: spinner 0.8s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .ticket-main {
    margin: 0;
    padding: 1rem;
    padding-bottom: 5rem;
  }

  .ticket-content {
    padding: 1rem;
  }

  .journey-details {
    padding: 0 10px;
  }

  .passenger-tickets {
    padding-left: 20px;
  }

  .ticket-card {
    padding: 12px;
  }

  .time {
    font-size: 16px;
  }

  .location {
    font-size: 12px;
  }

  .duration-text {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .date-button {
    min-width: 140px;
    padding: 8px 12px;
  }
  
  .original-date {
    font-size: 9px;
  }
}

/* Dark Mode Styles */
:root[class="dark"] .date-button {
  background-color: var(--bg-secondary);
}

:root[class="dark"] .date-button.selected {
  background-color: #E2384D;
}

:root[class="dark"] .ticket-card {
  background-color: var(--bg-secondary);
}

:root[class="dark"] .add-ticket-button {
  background-color: var(--bg-secondary);
}

