.signup-page {
  min-height: 100vh;
  background-color: #000;
  color: #fff;
  padding: 40px 20px;
  position: relative;
  justify-content: center;
  align-items: center;
}

.signup-page::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgb(17, 24, 39) 100%
  );
  z-index: 1;
}

.signup-container {
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.logo-section {
  text-align: center;
  margin-bottom: 40px;
}

.logo {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.step-indicator {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  position: relative;
}

.step-indicator::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #333;
  z-index: 1;
}

.step {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #333;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  position: relative;
  z-index: 2;
}

.step.active {
  background-color: #E2384D;
}

.step.completed {
  background-color: #28a745;
}

.form-container {
  background: rgba(255, 255, 255, 0.05);
  padding: 30px;
  border-radius: 15px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px #1f293789;
}

.profile-photo-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.profile-photo {
  width: 120px;
  height: 120px;
  border-radius: 60px;
  object-fit: cover;
  margin-bottom: 15px;
  border: 3px solid #E2384D;
}

.upload-button {
  background-color: #E2384D;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.3s ease;
}

.upload-button:hover {
  background-color: #ff4057;
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  display: block;
  margin-bottom: 8px;
  color: #fff;
}

.form-input {
  width: 100%;
  padding: 12px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
}

.form-input:focus {
  outline: none;
  border-color: #E2384D;
  background: rgba(255, 255, 255, 0.15);
}

.select-input {
  width: 100%;
  padding: 12px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.select-input option {
  background-color: #1a1a1a;
  color: #fff;
}

.password-input-container {
  position: relative;
}

.password-toggle {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
}

.radio-group {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.radio-button {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.band-section {
  margin-top: 20px;
}

.band-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.band-tag {
  background-color: rgba(226, 56, 77, 0.2);
  border: 1px solid #E2384D;
  padding: 8px 12px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.remove-tag {
  cursor: pointer;
  color: #E2384D;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  gap: 20px;
}

.back-button, .next-button {
  flex: 1;
  padding: 12px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.back-button {
  background-color: transparent;
  border: 2px solid #E2384D;
  color: #fff;
}

.next-button {
  background-color: #E2384D;
  border: none;
  color: #fff;
}

.back-button:hover {
  background-color: rgba(226, 56, 77, 0.1);
  transform: translateY(-2px);
}

.next-button:hover {
  background-color: #ff4057;
  transform: translateY(-2px);
}

.back-button:disabled, .next-button:disabled {
  background-color: #666;
  cursor: not-allowed;
  transform: none;
  border-color: #666;
}

.error-message {
  background-color: rgba(220, 53, 69, 0.2);
  color: #dc3545;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.login-link {
  text-align: center;
  margin-top: 20px;
  color: #ccc;
  position: relative;
  z-index: 2;
}

.login-link a {
  color: #E2384D;
  text-decoration: none;
  margin-left: 5px;
  transition: color 0.3s ease;
}

.login-link a:hover {
  color: #ff4057;
}

@media (max-width: 768px) {
  .signup-page {
    padding: 20px;
  }

  .form-container {
    padding: 20px;
  }

  .navigation-buttons {
    flex-direction: column;
  }

  .back-button, .next-button {
    width: 100%;
  }
}