.privacy-page {
    background-color: #f8f8f8;
}

.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    margin-top: -140px; /* Adjusted margin-top to bring the title up */
}

.title {
    font-size: 2rem;
    margin-bottom: 20px;
    margin-top: 0;
    
}

.content {
    margin-bottom: 40px;
}

.top-bar {
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.logo {
    height: 50px;
}

.sign-in-btn {
    background-color: #E2384D;
    color: #ffffff;
    border: none;
    padding: 8px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.footer {
    background-color: #E2384D;
    color: #ffffff;
    padding: 20px 0;
    text-align: center;
}

.footer ul {
    list-style-type: none;
    padding: 0;
}

.footer ul li {
    display: inline;
    margin-right: 20px;
}

.footer ul li:last-child {
    margin-right: 0;
}

.footer a {
    color: #ffffff;
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}
