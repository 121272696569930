/* messages.module.css */
:root {
  --chatbg-primary: #ffffff;
  --chatbg-secondary: #f3f4f6;
  --uitext-primary: #111827;
  --uitext-secondary: #6b7280;
  --chattext-primary: #ffffff;
  --chattext-secondary: #D3D3D3;
  --chatborder-color: #e9edef;
  --message-sent-bg: #E2384D;
  --message-received-bg: #ffffff;
  --chatpage-bg: #f5f7fb;
}

:root[class="dark"] {
  --chatbg-primary: #1f2937;
  --chatbg-secondary: #111827;
  --chattext-primary: #ffffff;
  --uitext-primary: #ffffff;
  --uitext-secondary: #9ca3af;
  --chattext-secondary: #D3D3D3;
  --chatborder-color: #374151;
  --message-sent-bg: #E2384D;
  --message-received-bg: #1f2937;
  --chatpage-bg: #111827;
}

.pageContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--chatpage-bg);
}

.contentLayout {
  flex: 1;
  display: flex;
  overflow: hidden;
  margin-top: 3.5rem; /* Account for TopBar height */
}

.messagesContainer {
  flex: 1;
  display: flex;
  background: var(--chatbg-primary);
  height: calc(100vh - 3.5rem); /* Adjust for TopBar */
  margin-left: 242px; /* Account for LeftMenu width */
}

/* Contacts/Chats List Panel */
.chatListPanel {
  width: 350px;
  min-width: 350px;
  background: var(--chatbg-primary);
  border-right: 1px solid var(--chatborder-color);
  border-left: 1px solid var(--chatborder-color);
  display: flex;
  flex-direction: column;
}

.chatListHeader {
  padding: 16px;
  border-bottom: 1px solid var(--chatborder-color);
}

.chatListHeader h2 {
  font-size: 20px;
  margin-bottom: 12px;
  color: var(--uitext-primary);
}

.searchContainer {
  position: relative;
  margin-bottom: 8px;
}

.searchIcon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--uitext-secondary);
}

.searchInput {
  width: 100%;
  padding: 8px 12px 8px 40px;
  border-radius: 8px;
  border: 1px solid var(--chatborder-color);
  background: var(--chatbg-secondary);
  color: var(--uitext-primary);
  font-size: 14px;
}

.searchInput::placeholder {
  color: var(--uitext-secondary);
}

/* Contacts Section */
.contactsSection {
  flex: 1;
  overflow-y: auto;
}

.sectionTitle {
  padding: 16px;
  font-size: 14px;
  color: var(--uitext-secondary);
  font-weight: 500;
}

.userCard {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.userCard:hover {
  background-color: var(--chatbg-secondary);
}

.userCard.active {
  background-color: var(--chatbg-secondary);
}

.userAvatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 12px;
  overflow: hidden;
  background: var(--chatbg-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--uitext-secondary);
}

.userAvatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.userInfo {
  flex: 1;
  overflow: hidden;
}

.userName {
  font-size: 16px;
  color: var(--uitext-primary);
  margin-bottom: 4px;
  font-weight: 500;
}

.lastMessage {
  font-size: 14px;
  color: var(--uitext-secondary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Chat Area */
.chatArea {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: var(--chatbg-secondary);
}

.chatHeader {
  padding: 16px;
  background: var(--chatbg-primary);
  border-bottom: 1px solid var(--chatborder-color);
  display: flex;
  align-items: center;
}

.chatUserInfo {
  display: flex;
  align-items: center;
  flex: 1;
}

.chatUserAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
}

.chatUserName {
  font-size: 16px;
  font-weight: 500;
  color: var(--uitext-primary);
}

/* Messages Area */
.messagesArea {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column-reverse;
}

.messageItem {
  display: flex;
  margin-bottom: 8px;
  max-width: 65%;
}

.messageItem.sent {
  margin-left: auto;
  flex-direction: row-reverse;
}

.messageContent {
  padding: 8px 12px;
  border-radius: 8px;
  background: var(--message-received-bg);
  position: relative;
  box-shadow: 0 1px 2px rgba(0,0,0,0.08);
}

.messageItem.sent .messageContent {
  background: var(--message-sent-bg);
}

.messageText {
  font-size: 14px;
  color: var(--chattext-primary);
  white-space: pre-wrap;
  word-wrap: break-word;
}

.messageTime {
  font-size: 11px;
  color: var(--chattext-secondary);
  margin-top: 4px;
  text-align: right;
}

/* Message Input Area */
.messageInputContainer {
  padding: 16px;
  background: var(--chatbg-primary);
  border-top: 1px solid var(--chatborder-color);
}


.messageInput:focus {
  outline: none;
}

.messageInput::placeholder {
  color: var(--uitext-secondary);
}

.sendButton {
  padding: 8px 12px;
  background: none;
  border: none;
  color: var(--chattext-secondary);
  cursor: pointer;
  transition: color 0.2s;
}

.sendButton:hover {
  color: #00a884;
}

.sendButton:disabled {
  color: var(--chattext-secondary);
  opacity: 0.5;
  cursor: not-allowed;
}

/* Loading States */
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--uitext-secondary);
}

/* Empty State */
.emptyChatState {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--chatbg-secondary);
  color: var(--uitext-secondary);
  font-size: 16px;
}

/* Back Button for Mobile */
.backButton {
  display: none;
  margin-right: 16px;
  background: none;
  border: none;
  color: var(--uitext-primary);
  cursor: pointer;
  padding: 8px;
}

/* Message status icons */
.messageStatus {
  display: inline-flex;
  margin-left: 4px;
  font-size: 12px;
  color: var(--chattext-secondary);
}

.messageStatus.sent {
  color: var(--chattext-secondary); /* Gray for sent */
}

.messageStatus.read {
  color: #0088cc; /* Blue for read messages */
}

/* Fixed width buttons in input area */
.messageInputWrapper {
  display: flex;
  align-items: center;
  background: var(--chatbg-primary);
  border-radius: 8px;
  padding: 8px;
  border: 1px solid var(--chatborder-color);
  gap: 8px; /* Add gap between elements */
}

.emojiButton,
.sendButton {
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: var(--uitext-secondary);
  cursor: pointer;
  padding: 0;
  transition: color 0.2s;
}

.messageInput {
  flex: 1;
  border: none;
  background: none;
  padding: 8px;
  max-height: 100px;
  min-height: 20px;
  resize: none;
  font-size: 15px;
  line-height: 1.4;
  color: var(--uitext-primary);
  overflow-y: auto; /* Add scrollbar if content exceeds max-height */
}

.emojiButton:hover,
.sendButton:hover:not(:disabled) {
  color: #00a884;
}

.sendButton:disabled {
  color: var(--chattext-secondary);
  opacity: 0.5;
  cursor: not-allowed;
}

/* Responsive Design */
@media (max-width: 768px) {
  .messagesContainer {
    margin-left: 0; /* Remove margin on mobile */
  }

  .chatListPanel {
    width: 100%;
    display: flex;
  }

  .chatArea {
    display: none;
  }

  .chatArea.active {
    display: flex;
    position: fixed;
    top: 3.5rem;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
  }

  .backButton {
    display: block;
  }

  .emojiButton {
    display: none; /* Hide emoji button on mobile */
  }

  .messageInputWrapper {
    padding: 4px 8px; /* Slightly less padding on mobile */
  }
}

/* Additional utility classes */
.hidden {
  display: none;
}

/* Emoji Picker Container */
.emojiPickerContainer {
  position: absolute;
  bottom: 100%;
  left: 0;
  z-index: 1000;
  background: var(--chatbg-primary);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}



/* Utility Classes */
.hidden {
  display: none;
}

.flexGrow {
  flex-grow: 1;
}

.textEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}