/* Theme variables - matching Membership.module.css */
:root {
    --bg-primary: #ffffff;
    --bg-secondary: #f3f4f6;
    --text-primary: #111827;
    --text-secondary: #6b7280;
    --border-color: #e5e7eb;
    --page-bg: #f5f7fb;
  }
  
  :root[class="dark"] {
    --bg-primary: #1f2937;
    --bg-secondary: #111827;
    --text-primary: #ffffff;
    --text-secondary: #9ca3af;
    --border-color: #374151;
    --page-bg: #111827;
  }
  
  .container {
    width: 100%;
    min-height: 100vh;
    background-color: var(--page-bg);
  }
  
  .main-layout {
    display: flex;
    min-height: calc(100vh - 3.5rem);
    margin-top: 3.5rem;
  }
  
  .downloads-content {
    flex: 1;
    margin-left: 240px;
    margin-right: 270px;
    padding: 2rem;
    background-color: var(--page-bg);
  }
  
  .downloads-header {
    margin-bottom: 3rem;
    text-align: center;
  }
  
  .downloads-header h1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--text-primary);
    margin-bottom: 1rem;
  }
  
  .header-description {
    color: var(--text-secondary);
    font-size: 1.1rem;
  }
  
  .downloads-grid {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .download-section {
    background: var(--bg-primary);
    border-radius: 1rem;
    padding: 2rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .section-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text-primary);
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  
  .section-icon {
    color: #E2384D;
  }
  
  .section-description {
    color: var(--text-secondary);
    margin-bottom: 2rem;
  }
  
  .platforms-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }
  
  .platform-card {
    background: var(--bg-secondary);
    border-radius: 0.75rem;
    overflow: hidden;
    transition: transform 0.2s ease;
  }
  
  .platform-card:hover {
    transform: translateY(-4px);
  }
  
  .platform-content {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .platform-icon {
    font-size: 2.5rem;
    color: #E2384D;
    margin-bottom: 1rem;
  }
  
  .platform-name {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--text-primary);
    margin-bottom: 0.5rem;
  }
  
  .platform-tag {
    font-size: 0.875rem;
    color: var(--text-secondary);
    margin-bottom: 1.5rem;
  }
  
  .store-button {
    display: block;
    transition: opacity 0.2s ease;
  }
  
  .store-button:hover {
    opacity: 0.8;
  }
  
  .store-image {
    height: 60px;
    width: auto;
  }
  
  .download-button {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    background: #E2384D;
    color: white;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    font-weight: 500;
    transition: background-color 0.2s ease;
    text-decoration: none;
  }
  
  .download-button:hover {
    background: #cb1d32;
  }
  
  .system-requirements {
    margin-top: 3rem;
    padding: 2rem;
    background: var(--bg-primary);
    border-radius: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .system-requirements h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text-primary);
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .requirements-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }
  
  .requirement-card {
    background: var(--bg-secondary);
    padding: 1.5rem;
    border-radius: 0.75rem;
  }
  
  .requirement-card h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--text-primary);
    margin-bottom: 1rem;
  }
  
  .requirement-card ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .requirement-card li {
    color: var(--text-secondary);
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--border-color);
  }
  
  .requirement-card li:last-child {
    border-bottom: none;
  }
  
  /* Bottom Navigation - matching Membership.module.css */
  .bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--bg-primary);
    padding: 0.75rem;
    display: none;
    justify-content: space-around;
    border-top: 1px solid var(--border-color);
    z-index: 50;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
    color: var(--text-secondary);
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    font-size: 0.75rem;
  }
  
  .nav-item.active {
    color: #E2384D;
  }
  
  /* Responsive Styles */
  @media (max-width: 1200px) {
    .downloads-content {
      margin-left: 200px;
      margin-right: 200px;
    }
  }
  
  @media (max-width: 768px) {
    .downloads-content {
      margin: 0;
      padding: 1rem;
      padding-bottom: 5rem;
    }
  
    .downloads-header h1 {
      font-size: 2rem;
    }
  
    .platforms-grid {
      grid-template-columns: 1fr;
    }
  
    .requirements-grid {
      grid-template-columns: 1fr;
    }
  
    .bottom-nav {
      display: flex;
    }
  }
  
  /* Animation */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .download-section {
    animation: fadeInUp 0.3s ease-out;
    animation-fill-mode: both;
  }
  
  .download-section:nth-child(1) { animation-delay: 0.1s; }
  .download-section:nth-child(2) { animation-delay: 0.2s; }
  
  /* Hover Effects */
  .platform-card {
    position: relative;
    overflow: hidden;
  }
  
  .platform-card::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at center, rgba(226, 56, 77, 0.1) 0%, transparent 70%);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .platform-card:hover::after {
    opacity: 1;
  }
  
  .platform-icon {
    transition: transform 0.3s ease;
  }
  
  .platform-card:hover .platform-icon {
    transform: scale(1.1);
  }
  
  /* Print Styles */
  @media print {
    .bottom-nav {
      display: none;
    }
  
    .downloads-content {
      margin: 0;
      padding: 0;
    }
  
    .platform-card {
      break-inside: avoid;
      box-shadow: none;
      border: 1px solid var(--border-color);
    }
  
    .download-button,
    .store-button {
      display: none;
    }
  }