.welcome-page {
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: #000;
}

.sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: background-color 0.3s ease;
}

.sticky-header.scrolled {
  background-color: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(5px);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  max-width: 1400px;
  margin: 0 auto;
}

.logo-section {
  display: flex;
  align-items: center;
  gap: 15px;
}

.logo {
  height: 40px;
}

.header-title {
  color: #E2384D;
  font-size: 24px;
  margin: 0;
}

.welcome-content {
  position: relative;
  height: 100vh;
  flex: none;
  padding: 20px;
  color: #fff;
  padding-top: 40px;
}

.welcome-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../assets/Images/welcomebackground.png');
  background-size: cover;
  background-position: center;
  opacity: 0.2;
  z-index: 0;
}

main {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 60px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.h2 {
  font-size: 42px;
  margin-bottom: 20px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  color: #ffffff;
  font-weight: 700;
}

.subtitle {
  font-size: 24px;
  margin-bottom: 40px;
  color: #fff;
  font-weight: 600;
}

.cta-section {
  margin-bottom: 50px;
}

.get-started-btn {
  background-color: #E2384D;
  color: #fff;
  border: none;
  padding: 15px 40px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 4px;
  transition: transform 0.2s ease;
}

.get-started-btn:hover {
  transform: scale(1.05);
}

.sign-in-btn {
  background-color: transparent;
  color: #fff;
  border: 2px solid #E2384D;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
}

.sign-in-btn:hover {
  background-color: #E2384D;
}

.features-section {
  background-color: #000;
  padding: 80px 0;
  overflow: hidden;
  position: relative;
}

.features-scroll {
  display: flex;
  gap: 40px;
  padding: 0 40px;
  max-width: 1400px;
  margin: 0 auto;
  flex-wrap: nowrap;
}

.feature {
  flex: 1;
  min-width: 300px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.feature-text {
  margin-bottom: 30px;
}

.feature h3 {
  font-size: 32px;
  margin-bottom: 20px;
  color: #E2384D;
}

.feature p {
  font-size: 18px;
  line-height: 1.6;
  color: #ccc;
}

.feature-image img {
  width: 100%;
  max-width: 250px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(226, 56, 77, 0.3);
}

.reasons-section {
  background-color: #111;
  padding: 80px 40px;
  position: relative;
}

.reasons-section h2 {
  text-align: center;
  color: #E2384D;
  font-size: 36px;
  margin-bottom: 50px;
  font-weight: 700;
}

.reasons-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.reason-card {
  background: linear-gradient(
    45deg, 
    rgba(0, 0, 0, 0.95) 0%,
    rgba(0, 0, 0, 0.95) 50%,
    rgba(0, 72, 197, 0.174) 100%
  );
  border: 1px solid rgba(226, 56, 77, 0.2);
  padding: 30px;
  border-radius: 12px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 
    0 4px 15px rgba(226, 56, 77, 0.15),
    inset 0 0 20px rgba(226, 56, 77, 0.05);
  position: relative;
  overflow: hidden;
}

.reason-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(
    90deg, 
    #E2384D 0%, 
    #ff6b81 50%, 
    #E2384D 100%
  );
}

.reason-card::after {
  content: '';
  position: absolute;
  top: 4px;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    135deg,
    rgba(226, 56, 77, 0.1) 0%,
    transparent 50%,
    rgba(226, 56, 77, 0.05) 100%
  );
  pointer-events: none;
}

.reason-card:hover {
  transform: translateY(-5px);
  box-shadow: 
    0 8px 25px rgba(226, 56, 77, 0.2),
    inset 0 0 30px rgba(226, 56, 77, 0.08);
}

.reason-card:hover::after {
  background: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.15) 0%,
    transparent 50%,
    rgba(174, 0, 20, 0.659) 100%
  );
}

.reason-emoji {
  font-size: 40px;
  display: block;
  margin-bottom: 15px;
}

.reason-card h3 {
  color: #E2384D;
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: 600;
}

.reason-card p {
  color: #fff;
  font-size: 16px;
  line-height: 1.5;
}

.app-download {
  margin-top: 40px;
  width: 100%;
}

.app-download p {
  margin-bottom: 20px;
  color: #ccc;
  font-size: 18px;
}

/* More specific selectors to ensure styles are applied */
.app-store-logos {
  display: flex;
  justify-content: center;
  gap: 30px;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

.app-store-logos img.playstorelogo,
.app-store-logos img.appstorelogo {
  width: auto;
  height: 80px !important; /* Increased size and added !important to check if there are conflicting styles */
  transition: transform 0.2s ease;
  object-fit: contain;
}

.app-store-logos img.playstorelogo:hover,
.app-store-logos img.appstorelogo:hover {
  transform: scale(1.05);
}

/* Mobile styles */


@media (max-width: 768px) {
  .app-store-logos {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  
  .app-store-logos img.playstorelogo,
  .app-store-logos img.appstorelogo {
    height: 65px !important;
    max-width: 200px;
  }
}

.mobile-cta {
  display: none;
}

footer {
  background-color: #E2384D;
  padding: 30px;
  text-align: center;
}



footer ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

footer li {
  margin: 0 10px;
}

footer a {
  color: #fff;
  text-decoration: none;
  transition: opacity 0.2s ease;
}

footer a:hover {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .header-content {
    padding: 15px 20px;
  }

  .logo {
    height: 30px;
  }

  .header-title {
    font-size: 20px;
  }

  h1 {
    font-size: 48px;
  }

  .h2 {
    font-size: 32px;
  }

  .subtitle {
    font-size: 20px;
  }

  .features-scroll {
    flex-wrap: nowrap;
    overflow-x: auto;
    margin: 0;
    padding: 0 20px;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 20px;
  }

  .feature {
    flex: 0 0 85%;
    scroll-snap-align: center;
    min-width: 280px;
  }

  .feature h3 {
    font-size: 24px;
  }

  .feature p {
    font-size: 16px;
  }

  .reasons-grid {
    grid-template-columns: 1fr;
  }

  .mobile-cta {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 15px 20px;
    text-align: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
  }
  
  .mobile-cta .get-started-btn {
    width: 100%;
    max-width: 300px;
  }

  

}

@media (max-width: 768px) {
  footer {
    padding-bottom: 80px; /* Add extra padding at the bottom for mobile to account for CTA */
  }

  footer ul {
    flex-direction: column;
    gap: 10px;
  }

  footer li {
    margin: 5px 0;
  }

}


@media (min-width: 769px) {
  .mobile-cta {
    display: none !important;
  }
}