/* Theme variables */
:root {
  --bg-primary: #ffffff;
  --bg-secondary: #f3f4f6;
  --text-primary: #111827;
  --text-secondary: #6b7280;
  --border-color: #e5e7eb;
  --page-bg: #f5f7fb;
}

:root[class="dark"] {
  --bg-primary: #1f2937;
  --bg-secondary: #111827;
  --text-primary: #ffffff;
  --text-secondary: #9ca3af;
  --border-color: #374151;
  --page-bg: #111827;
}

/* Base container styles */
.profile-container {
  width: 100%;
  min-height: 100vh;
  background-color: var(--page-bg);
  position: relative;
  z-index: 1;
}

.main-layout {
  display: flex;
  min-height: calc(100vh - 3.5rem);
  margin-top: 3.5rem;
}

/* Main content area */
.profile-main {
  flex: 1;
  margin-left: 240px;
  margin-right: 270px;
  min-width: 0;
  padding: 2rem;
  background-color: var(--page-bg);
  position: relative;
  z-index: 1;
}

/* Profile content container */
.profile-content {
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}

/* Cover photo section */
.cover-photo-container {
  width: 100%;
  height: 250px;
  position: relative;
  background-color: var(--bg-secondary);
  border-radius: 1rem 1rem 1rem 1rem;
  overflow: hidden;
  margin-bottom: 4rem;
}

.cover-photo {
  min-width: 100%;
  height: 100%;
  object-fit: cover;
}

.cover-overlay {
  position: absolute;
  inset: 0;
  background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
}

/* Profile card */
.profile-card {
  background: var(--bg-primary);
  border-radius: 1rem;
  overflow: hidden;
  margin-bottom: 2rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  margin-top: 0rem;
}

/* Profile header section */
.profile-header {
  display: flex;
  gap: 2rem;
  padding: 0 2rem 2rem;
  position: relative;
  margin-top: -8rem;
}

/* Left profile section */
.profile-left {
  flex: 0 0 auto;
  width: 250px;
  position: relative;
  margin-top: -3rem;
}

.profile-info {
  margin-top: 1rem;
  width: 100%;
}

.profile-photo {
  width: 10rem;
  height: 10rem;
  border-radius: 9999px;
  border: 4px solid var(--bg-primary);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  object-fit: cover;
}

/* Right section */
.profile-right {
  flex: 1;
  min-width: 0;
  padding-top: 4rem;
}

/* Profile info and details */
.name-section {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-bottom: 0.5rem;
  margin-top: -2rem;
}

.name-section h1 {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--text-primary);
  margin: 0;
}

.verified-badge {
  width: 1.25rem;
  height: 1.25rem;
}

.user-type {
  font-size: 1rem;
  color: var(--text-secondary);
  margin-bottom: 1rem;
}

.info-items {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
}

.info-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-secondary);
}

.info-item.on-tour {
  color: #E2384D;
}

.info-item.available {
  color: #10b981;
}

.bio {
  font-size: 1rem;
  color: var(--text-secondary);
  line-height: 1.5;
  margin-top: 0.5rem;
}

:root[class="dark"] .bio {
  color: var(--text-primary);
}


.dateItem {
  background: var(--bg-secondary);
  border-radius: 0.75rem;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.dateItem.onTour {
  opacity: 0.7;
  cursor: not-allowed;
}

.dateItem.pending {
  opacity: 0.7;
  cursor: not-allowed;
  background-color: rgba(255, 165, 0, 0.1);
}

.dateItem.selected {
  background: #E2384D;
  color: white;
}

.dateInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.dateInfo span {
  color: var(--text-primary);
}

.status {
  font-size: 0.875rem;
  padding: 0.25rem 0.75rem;
  border-radius: 0.25rem;
  color: var(--text-secondary);
  background: var(--bg-primary);
}

.venueInfo {
  margin-top: 0.5rem;
}

.venueInfo p {
  color: var(--text-secondary);
}

.venueName {
  font-weight: 500;
  margin: 0 0 0.25rem 0;
}

.venueLocation {
  color: var(--text-secondary);
  font-size: 0.875rem;
  margin: 0;
}

.bioSection {
  margin: 1rem 0;
}

.bio {
  color: var(--text-secondary);
  line-height: 1.5;
  margin: 0;
}

.addBioButton {
  background: none;
  border: none;
  color: #E2384D;
  padding: 0;
  font-size: 0.875rem;
  cursor: pointer;
  font-weight: 500;
}

.socialIcons {
  display: flex;
  gap: 1rem;
  align-items: center;
  color :#ffffff;
}



.socialIcon {
  width: 26px;  
  height: 26px;
  color: #E2384D
}

.customsocialIcon {
  width: 26px;  
  height: 26px;
  color: #E2384D;
  margin-top: -1.5rem;
}

.addSocialButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;  
  height: 30px;
  border-radius: 50%;
  background-color: var(--bg-secondary);
  border: none;
  color: #E2384D;
  cursor: pointer;
  transition: all 0.2s ease;
}

.addSocialButton:hover {
  background-color: var(--border-color);
}

.addSocialButton svg {
  width: 26px;  
  height: 26px;
}

/* Profile actions */
.profile-actions {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

.action-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
}

.action-button.edit {
  background-color: var(--text-primary);
  color: var(--bg-primary);
}

.action-button.contact {
  background-color: #E2384D;
  color: white;
}

.action-button.share {
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
}

/* Stats section */
.stats-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border-radius: 0.75rem;
  padding: 1rem;
  margin-top: 1rem;
}


.stat-value {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-primary);
  text-align: center 
}

.stat-label {
  color: var(--text-secondary);
  font-size: 0.875rem;
  text-align: center 
}

.add-user-button {
  display: flex;
  justify-content: center;
  align-items: center;
 margin-top: -1.5rem;
  background-color: var(--bg-secondary);
  border-radius: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.add-user-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}



.add-user-icon {
  width: 75px;
  height: 75px;
  object-fit: contain;
}

.add-user-icon.disabled {
  opacity: 0.5;
}

.tour-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  color: var(--text-primary);
}

.tour-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: var(--bg-secondary);
  border-radius: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.tour-item:hover:not(.disabled) {
  background: var(--border-color);
}


.tour-item h3 {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: var(--text-primary);
}

.tour-item p {
  font-size: 0.9rem;
  color: var(--text-secondary);
}

.dates-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0;
  color: var(--text-primary);
}

.tour-title {
  color: var(--text-primary);
  margin-bottom: 0.5rem;
 }

.date-item {
  background: var(--bg-secondary);
  color: var(--text-primary);
  border-radius: 0.75rem;
  padding: 1rem;
  cursor: pointer;
}

.date-item.on-tour {
  opacity: 0.7;
  cursor: not-allowed;
}

.date-item.selected {
  background: #E2384D;
  color: white;
}

.date-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.subtitle {
  color: var(--text-secondary);
  margin-bottom: 1rem;
}

/* Content section */
.content-section {
  background: var(--bg-primary);
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.content-tabs {
  display: flex;
  gap: 1rem;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 1.5rem;
}

.tab {
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  color: var(--text-secondary);
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
}

.tab.active {
  color: #E2384D;
}

.tab.active::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #E2384D;
}

/* Tour grid */
.tour-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.tour-card {
  background: var(--bg-secondary);
  border-radius: 0.75rem;
  overflow: hidden;
  transition: all 0.2s ease;
}

.tour-cover {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  object-fit: cover;
  flex-shrink: 0;
}

.tour-item-content {
  flex: 1;
}


.tour-info {
  padding: 1rem;
  color: var(--text-primary);
}

.tour-info h2 {
  font-size: 1rem;
  color: var(--text-primary);
  margin-bottom: 0.25rem;
  text-align: center;
  font-size: x-large;

}

.tour-info h3 {
  color: var(--text-primary);
  margin: 0 0 0.5rem 0;

}

.tour-info h4 {
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 0.25rem;

}

.act-name {
  color: var(--text-primary);
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.tour-dates,
.shows-count {
  color: var(--text-secondary);
  font-size: 0.875rem;
}

/* Date cards */
.dates-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 2rem;
}

.date-filters {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.filter-btn {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background: var(--bg-secondary);
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  transition: all 0.2s ease;
}

.filter-btn.active {
  background: #E2384D;
  color: white;
}



.date-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.date {
  color: var(--text-primary);
  font-weight: 500;
}

.status {
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background: var(--bg-primary);
  color: var(--text-secondary);
}

.venue-info h4 {
  color: var(--text-primary);
  margin: 0 0 0.25rem 0;
}

.venue-info p {
  color: var(--text-secondary);
  font-size: 0.875rem;
  margin: 0;
}

/* Loading states */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: var(--bg-primary);
}

.loading-spinner {
  border: 3px solid var(--border-color);
  border-top: 3px solid var(--text-primary);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Bottom Navigation */
.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--bg-primary);
  padding: 0.75rem;
  display: none;
  justify-content: space-around;
  border-top: 1px solid var(--border-color);
  z-index: 50;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  color: var(--text-secondary);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  font-size: 0.75rem;
}

.nav-item.active {
  color: #E2384D;
}

.nav-icon {
  font-size: 1.25rem;
}

.tour-item {
  position: relative;
}

.tour-item.disabled {
  opacity: 0.7;
  cursor: not-allowed;
}



.tour-status {
  font-size: 0.875rem;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  background-color: var(--bg-primary);
  color: var(--text-secondary);
}

.tour-item.disabled .tour-status {
  background-color: rgba(226, 56, 77, 0.1);
  color: #E2384D;
}

.date-item.pending {
  opacity: 0.7;
  cursor: not-allowed;
  background-color: rgba(255, 165, 0, 0.1); /* Light orange background for pending */
}

.date-item.pending .status {
  color: #FFA500; /* Orange color for pending status */
}

.date-item.on-tour .status {
  color: #E2384D; /* Red color for on tour status */
}

.date-item:not(.on-tour):not(.pending) .status {
  color: #10b981; /* Green color for available status */
}

.date-item.pending {
  opacity: 0.7;
  cursor: not-allowed;
  background-color: rgba(255, 165, 0, 0.1);
}

.date-item.pending .status {
  color: #FFA500;
}


 
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  position: relative;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  border-radius: 1rem;
  padding: 2rem;
  width: 90%;
  max-width: 500px;
  margin: auto;
  max-height: 90vh;
  overflow-y: auto;
  z-index: 10000;
  transform: translate(0, 0);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.modal-content h2 {
  padding-right: 2rem; /* Make space for close button */
}

.modal-close {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  background: none;
  border: none;
  color: var(--text-secondary);
  cursor: pointer;
  font-size: 1.25rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;
  width: 2rem;
  height: 2rem;
  z-index: 10001;
}

.modal-close:hover {
  background-color: var(--bg-secondary);
}

body.modal-open {
  overflow: hidden;
}

/* Modal animations */
.modal-overlay {
  animation: fadeIn 0.2s ease-out;
}

.modal-content {
  animation: slideIn 0.3s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { 
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


/* Upload Options */
.upload-options {
  text-align: center;
}

.upload-options h2 {
  margin-bottom: 2rem;
  color: var(--text-primary);
}

.upload-buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.upload-buttons button {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 1.5rem;
  border: 2px solid var(--border-color);
  border-radius: 0.75rem;
  background: var(--bg-secondary);
  color: var(--text-primary);
  cursor: pointer;
  transition: all 0.2s ease;
}

.upload-buttons button:hover {
  border-color: #E2384D;
  color: #E2384D;
}

.upload-buttons button svg {
  font-size: 1.5rem;
}

/* Upload Form */
.upload-form {
  color: var(--text-primary);
}

.upload-form h2 {
  margin-bottom: 1.5rem;
  text-align: center;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--text-primary);
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  background: var(--bg-secondary);
  color: var(--text-primary);
}

.form-group textarea {
  min-height: 100px;
  resize: vertical;
}

.form-group small {
  display: block;
  margin-top: 0.25rem;
  color: var(--text-secondary);
  font-size: 0.875rem;
}

.form-actions {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 2rem;
}

.form-actions button {
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  font-weight: 500;
}

.form-actions button[type="button"] {
  background: var(--bg-secondary);
  color: var(--text-secondary);
}

.form-actions button[type="submit"] {
  background: #E2384D;
  color: white;
}

/* Showcase Section */
.showcase-section {
  padding: 1rem 0;
}

.upload-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background: var(--bg-secondary);
  border: 2px dashed var(--border-color);
  border-radius: 0.75rem;
  color: var(--text-secondary);
  cursor: pointer;
  margin-bottom: 2rem;
  width: 100%;
  justify-content: center;
  transition: all 0.2s ease;
}

.upload-button:hover {
  border-color: #E2384D;
  color: #E2384D;
}

/* Posts Grid */
.posts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}


.media-icon {
  position: absolute;
  font-size: 2rem;
  color: var(--text-secondary);
}





.search-section {
  margin: 1.5rem 0;
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
}



/* Tags Tabs */
.tags-tabs {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  overflow-x: auto;
  padding-bottom: 0.5rem;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
}

/* Add scrollbar styling for desktop */
.tags-tabs::-webkit-scrollbar {
  height: 4px;
}

.tags-tabs::-webkit-scrollbar-track {
  background: var(--bg-secondary);
  border-radius: 2px;
}

.tags-tabs::-webkit-scrollbar-thumb {
  background: var(--border-color);
  border-radius: 2px;
}

.tag-tab {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;
  background: var(--bg-secondary);
  color: var(--text-secondary);
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.2s ease;
  
}

.tag-tab.active {
  background: #E2384D;
  color: white;
}

/* Existing Tags in Modal */
.existing-tags {
  display: flex;
  overflow-x: auto;
  gap: 0.5rem;
  padding: 0.5rem 0;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  margin: 0.5rem -0.5rem;
}

.existing-tags::-webkit-scrollbar {
  height: 4px;
}

.existing-tags::-webkit-scrollbar-track {
  background: var(--bg-secondary);
  border-radius: 2px;
}

.existing-tags::-webkit-scrollbar-thumb {
  background: var(--border-color);
  border-radius: 2px;
}


.tag-button {
  padding: 0.25rem 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  background: var(--bg-secondary);
  color: var(--text-secondary);
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.tag-button.selected {
  background: #E2384D;
  color: white;
  border-color: #E2384D;
}



.loading-spinner-container {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
}



.no-more-posts {
  text-align: center;
  padding: 2rem 0;
  color: var(--text-secondary);
  font-size: 0.875rem;
}

.no-results {
  text-align: center;
  padding: 3rem 0;
  color: var(--text-secondary);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Update post-fallback styles */
.post-fallback {
  width: 100%;
  height: 200px;
  background: var(--bg-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  overflow: hidden;
}

.fallback-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-secondary);
  text-align: center;
  padding: 1rem;
}

.fallback-content .media-icon {
  font-size: 2rem;
  opacity: 0.5;
}

/* Date Filters */
.date-filters {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
  overflow-x: auto;
  padding-bottom: 0.5rem;
  -webkit-overflow-scrolling: touch;
}

.filter-btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;
  background: var(--bg-secondary);
  color: var(--text-secondary);
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
  font-weight: 500;
}

.filter-btn.active {
  background: #E2384D;
  color: white;
}

/* Date Picker */
.date-picker {
  width: 100%;
  max-width: 200px;
  padding: 0.5rem;
  border: 2px solid var(--border-color);
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
  background: var(--bg-secondary);
  color: var(--text-primary);
}

/* Date Cards */
.date-card {
  background: var(--bg-secondary);
  border-radius: 0.75rem;
  padding: 1.25rem;
  margin-bottom: 1rem;
  transition: all 0.2s ease;
}

.date-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.date {
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--text-primary);
}

.status {
  font-size: 0.875rem;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-weight: 500;
}

.status.on-tour {
  background: rgba(226, 56, 77, 0.1);
  color: #E2384D;
}

.status.available {
  background: rgba(16, 185, 129, 0.1);
  color: #10b981;
}






.venue-info {
  margin-top: 0.5rem;
}

.venue-name {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--text-primary);
  margin-bottom: 0.25rem;
}

.venue-location {
  font-size: 0.875rem;
  color: var(--text-secondary);
}

.rescheduled-info {
  font-size: 0.875rem;
  color: #E2384D;
  font-style: italic;
  margin-top: 0.5rem;
}

/* Load More Button */
.load-more {
  width: 100%;
  padding: 0.75rem;
  background: var(--bg-secondary);
  border: none;
  border-radius: 0.5rem;
  color: var(--text-primary);
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 1rem;
}

.load-more:hover {
  background: var(--border-color);
}

.stats-modal {
  max-width: 800px !important;
  max-height: 90vh !important;
}

/* Tours List Styles */
.tours-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  padding: 1rem 0;
}

.tour-card {
  background: var(--bg-secondary);
  border-radius: 0.75rem;
  overflow: hidden;
  transition: transform 0.2s ease;
}

.tour-card:hover {
  transform: translateY(-2px);
}

.tour-cover-photo {
  width: 100%;
  height: 160px;
  object-fit: cover;
}

.tour-card-content {
  padding: 1rem;
}

.tour-card-content h3 {
  margin: 0 0 0.5rem 0;
  font-size: 1.125rem;
  color: var(--text-primary);
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.load-more-button:hover {
  background: var(--border-color);
}

/* Stats card interaction */
.stat-card {
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.2s ease;
  padding-bottom: 0.25rem;
  border-radius: 0.75rem;
}

.stat-card:hover {
  transform: translateY(-2px);
  background-color: var(--border-color);
  border-radius: 0.75rem;

}

/* Empty menus for non-logged in users */
.empty-left-menu {
  width: 210px;
  height: calc(100vh - 3.5rem);
  background: transparent;
}

.empty-right-menu {
  width: 280px;
  height: calc(100vh - 3.5rem);
  background: transparent;
}

/* Login prompt styles */
.login-prompt-container {
  width: 100%;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-prompt {
  background: var(--bg-secondary);
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-out;
}

.lock-icon {
  font-size: 2.5rem;
  color: var(--text-secondary);
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}

.login-prompt h3 {
  font-size: 1.25rem;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
}

.login-prompt p {
  color: var(--text-secondary);
  margin-bottom: 1.5rem;
}

.login-actions {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.login-actions button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
}

.login-actions .login-button {
  background: var(--bg-primary);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
}

.login-actions .signup-button {
  background: #E2384D;
  color: white;
}

/* Share profile button */
.share-profile-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: #E2384D;
  margin-top: 0.5rem;
}

/* Posts preview grid */
.posts-preview {
  margin-bottom: 2rem;
}

.posts-preview .posts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  margin-bottom: 1rem;
  opacity: 0.7;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.endorsedBrandsSection {
  margin-top: 1rem;
  width: 100%;
}

.endorsedBrandsTitle {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--text-secondary);
  margin-bottom: 0.75rem;
}

.endorsedBrands {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}

.brandBadge {
  display: flex;
  align-items: center;
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: 2rem;
  padding: 0.25rem;
  gap: 0.5rem;
  transition: all 0.2s ease;
  cursor: pointer;
}

.brandBadgeLogo {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  object-fit: cover;
  background-color: var(--bg-primary);
}

.brandBadgeName {
  margin-right: 0.5rem;
  color: var(--text-primary);
  font-size: 0.875rem;
}

.brandBadge:hover {
  background-color: var(--border-color);
  transform: translateY(-1px);
}

/* Responsive styles */
@media (max-width: 768px) {
  .endorsedBrands {
    justify-content: center;
    padding: 0 1rem;
  }

  .brandBadge {
    padding: 0.2rem;
  }

  .brandBadgeLogo {
    width: 24px;
    height: 24px;
  }

  .brandBadgeName {
    font-size: 0.75rem;
  }
}

/* Dark mode specific styles */
:root[class="dark"] .brandBadge {
  background-color: var(--bg-primary);
}


/* Mobile styles */
@media (max-width: 768px) {
  .empty-left-menu,
  .empty-right-menu {
    display: none;
  }
  
  .login-prompt {
    padding: 1.5rem;
    margin: 0 1rem;
  }
  
  .login-actions {
    flex-direction: column;
  }
  
  .login-actions button {
    width: 100%;
  }
}
  
/* Empty placeholders for menus */
.empty-left-menu {
  width: 210px;
  height: calc(100vh - 3.5rem);
  background: transparent;
}

.empty-right-menu {
  width: 280px;
  height: calc(100vh - 3.5rem);
  background: transparent;
}

/* Login prompt styles */
.login-prompt-container {
  width: 100%;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-prompt {
  background: var(--bg-secondary);
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-out;
}

.lock-icon {
  font-size: 2.5rem;
  color: var(--text-secondary);
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}

.login-prompt h3 {
  font-size: 1.25rem;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
}

.login-prompt p {
  color: var(--text-secondary);
  margin-bottom: 1.5rem;
}

.login-actions {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.login-actions button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
}

.login-actions .login-button {
  background: var(--bg-primary);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
}

.login-actions .signup-button {
  background: #E2384D;
  color: white;
}

/* Auth buttons for TopBar */
.auth-buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.auth-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-weight: 500;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
}

.login-button {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
}

.login-button:hover {
  background-color: var(--border-color);
}

.signup-button {
  background-color: #E2384D;
  color: white;
}

.signup-button:hover {
  background-color: #cb1d32;
}

.auth-icon {
  font-size: 1rem;
}

/* Stats modal login prompt */
.stats-modal {
  max-width: 500px;
  text-align: center;
}

.stats-modal h2 {
  margin-bottom: 1rem;
  color: var(--text-primary);
}

.stats-modal p {
  color: var(--text-secondary);
  margin-bottom: 1.5rem;
}

/* Add Associated Act Button */
.addAssociatedActButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  color: var(--text-primary);
  cursor: pointer;
  margin-top: 1rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.addAssociatedActButton:hover {
  background: var(--border-color);
}

/* Associated Acts Section */
.associatedActsSection {
  margin-top: 1.5rem;
  width: 100%;
}

.sectionTitle {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--text-secondary);
  margin-bottom: 1rem;
}

.associatedActsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

.actCard {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: var(--bg-secondary);
  border-radius: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.actCard:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.actPhoto {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
}

.actInfo {
  flex: 1;
  min-width: 0;
}

.actNameContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.25rem;
}

.actNameContainer h4 {
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.actUsername {
  font-size: 0.875rem;
  color: var(--text-secondary);
  margin: 0 0 0.25rem 0;
}

.actSubtype,
.actGenre {
  font-size: 0.875rem;
  color: var(--text-primary);
  margin: 0;
}

.addMoreActsButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;
  background: var(--bg-secondary);
  border: 2px dashed var(--border-color);
  border-radius: 0.75rem;
  color: var(--text-secondary);
  cursor: pointer;
  transition: all 0.2s ease;
  height: 100%;
  min-height: 80px;
}

.addMoreActsButton:hover {
  border-color: #E2384D;
  color: #E2384D;
}

/* Dark Mode Support */
:root[class="dark"] .actCard {
  background: var(--bg-primary);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .associatedActsGrid {
    grid-template-columns: 1fr;
  }

  .actCard,
  .addMoreActsButton {
    margin: 0 1rem;
  }
}

/* Posts preview grid for public view */
.posts-preview {
  margin-bottom: 2rem;
}

.posts-preview .posts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  margin-bottom: 1rem;
  opacity: 0.7;
}

/* Limited content overlay */
.limited-content-overlay {
  position: relative;
}

.limited-content-overlay::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 150px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), var(--bg-primary));
  pointer-events: none;
}

:root[class="dark"] .limited-content-overlay::after {
  background: linear-gradient(to bottom, rgba(31, 41, 55, 0), var(--bg-primary));
}

/* Animation for content containers */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .empty-left-menu,
  .empty-right-menu {
    display: none;
  }
  
  .login-prompt {
    padding: 1.5rem;
    margin: 0 1rem;
  }
  
  .login-actions {
    flex-direction: column;
  }
  
  .login-actions button {
    width: 100%;
  }
}

/* Responsive Styles for Showcase */
@media (max-width: 768px) {
  .modal-content {
    width: 95%;
    padding: 1.5rem;
    margin: 1rem;
  }

  .upload-buttons {
    grid-template-columns: 1fr;
  }

  .posts-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 0 1rem;
  }

  .upload-button {
    margin: 1rem;
    width: calc(100% - 2rem);
  }


  .form-actions {
    flex-direction: column;
  }

  .form-actions button {
    width: 100%;
    text-align: center;
  }

 
  
  .tags-tabs {
    margin-bottom: 1rem;
  }
  
  .tag-tab {
    padding: 0.4rem 0.8rem;
    font-size: 0.875rem;
  }

}

/* Dark Mode Specific Styles */


:root[class="dark"] .tag {
  background: var(--bg-secondary);
}

:root[class="dark"] .upload-button:hover {
  background: rgba(226, 56, 77, 0.1);
}

:root[class="dark"] .modal-content {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

/* Responsive Styles */
/* Mobile styles */
@media (max-width: 768px) {
  .profile-main {
    margin: 0;
    padding: 1rem;
    padding-bottom: 5rem;
  }

  .profile-content {
    padding: 0;
    width: 100%;
  }

  .profile-card {
    margin: 0;
    border-radius: 0;
  }

  .cover-photo-container {
    border-radius: 0;
    margin-bottom: 0;
    height: 200px;
  }

  .profile-header {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: -3rem;
    padding: 1rem;
    gap: 1rem;
  }

  .profile-left {
    width: 100%;
    margin-top: -3rem;
  }

  .profile-photo {
    width: 120px;
    height: 120px;
    margin: 0 auto;
  }

  .profile-info {
    width: 100%;
    padding: 0 1rem;
  }

  .profile-right {
    width: 100%;
    padding-top: 1rem;
  }

  .info-items {
    justify-content: center;
    flex-wrap: wrap;
  }

  .profile-actions {
    flex-direction: column;
    gap: 0.75rem;
  }

  .stats-grid {
    margin-top: 0.5rem;
    grid-template-columns: repeat(2, 1fr);
  }

  .tour-grid {
    grid-template-columns: 1fr;
  }

  .content-tabs {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    gap: 0;
  }

  .tab {
    padding: 0.75rem 1rem;
    white-space: nowrap;
  }

  .bottom-nav {
    display: flex;
  }

  .date-filters {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 0.5rem;
  }

  .filter-btn {
    white-space: nowrap;
  }

  .stats-modal {
    width: 95%;
    margin: 1rem;
    padding: 1rem;
  }

  .tours-list {
    grid-template-columns: 1fr;
  }

 

  .user-photo {
    width: 50px;
    height: 50px;
  }

  .tour-card-content h3 {
    font-size: 1rem;
  }


/* Shows List Styles */
.shows-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
}

.show-card {
  background: var(--bg-secondary);
  border-radius: 0.75rem;
  padding: 1rem;
}

.show-date {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
}

.show-details h3 {
  margin: 0 0 0.5rem 0;
  font-size: 1rem;
  color: var(--text-primary);
}



.venue-address {
  color: var(--text-secondary);
  font-size: 0.875rem;
}

.rescheduled-note {
  color: #E2384D;
  font-style: italic;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

/* Users List Styles */
.users-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
}

.user-card {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  background: var(--bg-secondary);
  border-radius: 0.75rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.user-card:hover {
  background: var(--border-color);
}

.user-photo {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  object-fit: cover;
}

.user-info {
  flex: 1;
}

.user-name-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.25rem;
}

.user-name-container h3 {
  margin: 0;
  font-size: 1rem;
}

.verified-badge {
  width: 16px;
  height: 16px;
}

.username {
  color: var(--text-secondary);
  font-size: 0.875rem;
  margin: 0 0 0.25rem 0;
}

.user-type,
.user-subtype {
  color: var(--text-secondary);
  font-size: 0.875rem;
  margin: 0;
}

/* Load More Button */
.load-more-button {
  width: 100%;
  padding: 0.75rem;
  background: var(--bg-secondary);
  border: none;
  border-radius: 0.5rem;
  color: var(--text-primary)

}

}

/* Tablet styles */
@media (min-width: 769px) and (max-width: 1024px) {
  .profile-main {
    margin-left: 240px;
    margin-right: 270px;
    padding: 1.5rem;
  }

  .profile-header {
    flex-direction: row;
    gap: 1.5rem;
  }

  .profile-left {
    width: 200px;
  }

  .profile-photo {
    width: 140px;
    height: 140px;
  }

  .profile-info {
    padding: 0;
  }

  .profile-right {
    padding-top: 3rem;
  }

  .stats-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .tour-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Large tablet to small desktop */
@media (min-width: 1025px) and (max-width: 1280px) {
  .profile-main {
    margin-left: 240px;
    margin-right: 270px;
  }

  .profile-left {
    width: 220px;
  }
}

/* Larger screens */
@media (min-width: 1281px) {
  .profile-main {
    margin-left: 240px;
    margin-right: 270px;
  }

  .profile-left {
    width: 250px;
  }
}


.not-found-container {
  flex: 1;
  margin-left: 240px;  /* Width of LeftMenu */
  margin-right: 270px; /* Width of RightMenu */
  min-width: 0;
  padding: 2rem;
  background-color: var(--page-bg);
  position: relative;
  z-index: 1;
  min-height: calc(100vh - 3.5rem);
  display: flex;
  justify-content: center;
  align-items: center;
}

.not-found-content {
  text-align: center;
  max-width: 400px;
  animation: fadeIn 0.5s ease-out;
  background: var(--bg-primary);
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}



.not-found-artwork {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0 auto 2rem;
}

.not-found-icon {
  font-size: 80px;
  color: #E2384D;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.not-found-circle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  background: linear-gradient(45deg, rgba(226, 56, 77, 0.1), rgba(226, 56, 77, 0.2));
  animation: pulse 2s infinite;
}

.not-found-content h1 {
  color: var(--text-primary);
  font-size: 2rem;
  margin-bottom: 1rem;
}

.not-found-content p {
  color: var(--text-secondary);
  margin-bottom: 2rem;
}

.return-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background: #E2384D;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.return-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(226, 56, 77, 0.2);
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.8;
  }
  100% {
    transform: scale(0.95);
    opacity: 0.5;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .not-found-container {
    margin: 0;
    padding: 1rem;
    padding-bottom: 5rem; /* Space for bottom nav */
  }
  
  .not-found-artwork {
    width: 150px;
    height: 150px;
  }
  
  .not-found-content {
    width: 90%;
    padding: 1.5rem;
  }

  .not-found-icon {
    font-size: 60px;
  }
  
  .not-found-content h1 {
    font-size: 1.5rem;
  }

}
  
